import React, {useContext, useEffect, useState} from "react";
import {useSetState} from "react-use";
import {useForm} from "react-hook-form";
import {Link} from "react-router-dom";
import * as Constant from "../../other/Constant";
import SlidingPanel from "../_sliding_panel";
import DataPrivacy from "../../other/DataPrivacy";
import CGSV from "../../other/CGSV";
import Legal from "../../other/Legal";
import Api from "../../helper/api";
import {NotificationSettings, NotificationTypes} from "../../other/Constant";

const Setting = () => {
    const [showLegal, setShowLegal] = useState(false)
    const [showDataPrivacy, setShowDataPrivacy] = useState(false)
    const [showCGV, setShowCGV] = useState(false)
    const [showCGU, setShowCGU] = useState(false)
    const api = new Api();
    const [notificationSettings, setNotificationSettings] = useState([])

    const updateNotification = (data) => {
        const setting = notificationSettings?.find(obj => { return obj.type == data.type })

        if (setting) {
            if ('push' in data) {
                setting.push = data.push
            } else if ('email' in data) {
                setting.email = data.email
            }
            setNotificationSettings(notificationSettings?.map(obj => {
                if (obj.id === setting.id) {
                    return setting;
                }
                return obj;
            }))
            api.patchUserNotificationSetting(setting.id, data).then(response => {
            })
        }
    }

    useEffect(() => {
        api.getUserNotificationSetting().then(response => {
            console.log(response, 'RESULT NOTIF SETTINGS')
            setNotificationSettings(response)
        })
    }, [])

    return(
        <div id={"setting_wrapper"} className={"profile-wrapper"}>
            <h1>Paramètres</h1>

            <h2>Préférences de notifications</h2>
            <p>Sélectionnez ce que vous souhaitez recevoir</p>

            <table className={"notification_block"}>
                <tr>
                    <th>Mission</th>
                    <th>Push</th>
                    <th>Email</th>
                </tr>
                <tr>
                    <td>Recevoir les alertes de mission</td>
                    <td>
                        <div className={"form_row_wrapper checkbox_wrapper"}>
                            <input type={"checkbox"} checked={notificationSettings?.find(obj => { return obj.type == NotificationSettings.TYPE_MISSION && obj.push })} onChange={(e) => {
                                updateNotification({type: NotificationSettings.TYPE_MISSION, push: e.target.checked})
                            }} />
                        </div>
                    </td>
                    <td>
                        <div className={"form_row_wrapper checkbox_wrapper"}>
                            <input type={"checkbox"} checked={notificationSettings?.find(obj => { return obj.type == NotificationSettings.TYPE_MISSION && obj.email })} onChange={(e) => {
                                updateNotification({type: NotificationSettings.TYPE_MISSION, email: e.target.checked})
                            }} />
                        </div>
                    </td>
                </tr>
            </table>

            <table className={"notification_block"}>
                <tr>
                    <th>Match</th>
                    <th>Push</th>
                    <th>Email</th>
                </tr>
                <tr>
                    <td>Recevoir les alertes de la communauté</td>
                    <td>
                        <div className={"form_row_wrapper checkbox_wrapper"}>
                            <input type={"checkbox"} checked={notificationSettings?.find(obj => { return obj.type == NotificationSettings.TYPE_QUESTION && obj.push })} onChange={(e) => {
                                updateNotification({type: NotificationSettings.TYPE_QUESTION, push: e.target.checked})
                            }} />
                        </div>
                    </td>
                    <td>
                        <div className={"form_row_wrapper checkbox_wrapper"}>
                            <input type={"checkbox"} checked={notificationSettings?.find(obj => { return obj.type == NotificationSettings.TYPE_QUESTION && obj.email })} onChange={(e) => {
                                updateNotification({type: NotificationSettings.TYPE_QUESTION, email: e.target.checked})
                            }} />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>Recevoir les alertes d'aide</td>
                    <td>
                        <div className={"form_row_wrapper checkbox_wrapper"}>
                            <input type={"checkbox"} checked={notificationSettings?.find(obj => { return obj.type == NotificationSettings.TYPE_HELP && obj.push })} onChange={(e) => {
                                updateNotification({type: NotificationSettings.TYPE_HELP, push: e.target.checked})
                            }} />
                        </div>
                    </td>
                    <td>
                        <div className={"form_row_wrapper checkbox_wrapper"}>
                            <input type={"checkbox"} checked={notificationSettings?.find(obj => { return obj.type == NotificationSettings.TYPE_HELP && obj.email })} onChange={(e) => {
                                updateNotification({type: NotificationSettings.TYPE_HELP, email: e.target.checked})
                            }} />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>Recevoir les alertes d'échange</td>
                    <td>
                        <div className={"form_row_wrapper checkbox_wrapper"}>
                            <input type={"checkbox"} checked={notificationSettings?.find(obj => { return obj.type == NotificationSettings.TYPE_SWITCH_DUTY && obj.push })} onChange={(e) => {
                                updateNotification({type: NotificationSettings.TYPE_SWITCH_DUTY, push: e.target.checked})
                            }} />
                        </div>
                    </td>
                    <td>
                        <div className={"form_row_wrapper checkbox_wrapper"}>
                            <input type={"checkbox"} checked={notificationSettings?.find(obj => { return obj.type == NotificationSettings.TYPE_SWITCH_DUTY && obj.email })} onChange={(e) => {
                                updateNotification({type: NotificationSettings.TYPE_SWITCH_DUTY, email: e.target.checked})
                            }} />
                        </div>
                    </td>
                </tr>
            </table>

            <table className={"notification_block"}>
                <tr>
                    <th>Messagerie</th>
                    <th>Push</th>
                    <th>Email</th>
                </tr>
                <tr>
                    <td>Réception de nouveaux messages</td>
                    <td>
                        <div className={"form_row_wrapper checkbox_wrapper"}>
                            <input type={"checkbox"} checked={notificationSettings?.find(obj => { return obj.type == NotificationSettings.TYPE_MESSAGE_NEW && obj.push })} onChange={(e) => {
                                updateNotification({type: NotificationSettings.TYPE_MESSAGE_NEW, push: e.target.checked})
                            }} />
                        </div>
                    </td>
                    <td>
                        <div className={"form_row_wrapper checkbox_wrapper"}>
                            <input type={"checkbox"} checked={notificationSettings?.find(obj => { return obj.type == NotificationSettings.TYPE_MESSAGE_NEW && obj.email })} onChange={(e) => {
                                updateNotification({type: NotificationSettings.TYPE_MESSAGE_NEW, email: e.target.checked})
                            }} />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>Demande de visioconférence</td>
                    <td>
                        <div className={"form_row_wrapper checkbox_wrapper"}>
                            <input type={"checkbox"} checked={notificationSettings?.find(obj => { return obj.type == NotificationSettings.TYPE_VIDEO_NEW && obj.push })} onChange={(e) => {
                                updateNotification({type: NotificationSettings.TYPE_VIDEO_NEW, push: e.target.checked})
                            }} />
                        </div>
                    </td>
                    <td>
                        <div className={"form_row_wrapper checkbox_wrapper"}>
                            <input type={"checkbox"} checked={notificationSettings?.find(obj => { return obj.type == NotificationSettings.TYPE_VIDEO_NEW && obj.email })} onChange={(e) => {
                                updateNotification({type: NotificationSettings.TYPE_VIDEO_NEW, email: e.target.checked})
                            }} />
                        </div>
                    </td>
                </tr>
            </table>

            <h2>Sécurité</h2>

            <div className={"setting_inline_form"}>
                <h4>Adresse email</h4>
                <Link to={"/profile/email"}>Modifier</Link>
            </div>
            <div className={"setting_inline_form"}>
                <h4>Mot de passe</h4>
                <Link to={"/profile/password"}>Modifier</Link>
            </div>
            <div className={"setting_inline_form"}>
                <h4>Numéro de téléphone</h4>
                <Link to={"/profile/phone"}>Modifier</Link>
            </div>
            {/*<div className={"setting_inline_form"}>*/}
            {/*    <h4>Suppression de compte</h4>*/}
            {/*</div>*/}

            <h2>Légal</h2>

            <ul className={"setting_legal_link_wrapper"}>
                <li><Link to={"#0"} onClick={() => setShowLegal(true)}>Mentions légales</Link></li>
                <li><Link to={"#0"} onClick={() => setShowDataPrivacy(true)}>Politique de confidentialité</Link></li>
                {/*<li><Link to={"#0"} onClick={() => setShowCGU(true)}>CGU</Link></li>*/}
                <li><Link to={"#0"} onClick={() => setShowCGV(true)}>CGS / CGV</Link></li>
            </ul>

            {/*<div className={"notification_block"}>*/}
            {/*    <div className={"notification_header"}>*/}
            {/*        <h3>Mission</h3>*/}
            {/*        <div className={"notification_header_options"}>*/}
            {/*            <p>Push</p>*/}
            {/*            <p>Email</p>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className={"notification_item"}>*/}
            {/*        <p></p>*/}
            {/*        <div className={"notification_item_options"}>*/}
            {/*            <div className={"form_row_wrapper checkbox_wrapper"}>*/}
            {/*                <input type={"checkbox"}/>*/}
            {/*            </div>*/}
            {/*            <div className={"form_row_wrapper checkbox_wrapper"}>*/}
            {/*                <input type={"checkbox"}/>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <SlidingPanel show={showLegal} handleClose={() => setShowLegal(false)} forceSliding={true}>
                <Legal handleClose={() => setShowLegal(false)} />
            </SlidingPanel>
            <SlidingPanel show={showDataPrivacy} handleClose={() => setShowDataPrivacy(false)} forceSliding={true}>
                <DataPrivacy handleClose={() => setShowDataPrivacy(false)} />
            </SlidingPanel>
            <SlidingPanel show={showCGV} handleClose={() => setShowCGV(false)} forceSliding={true}>
                <CGSV handleClose={() => setShowCGV(false)} />
            </SlidingPanel>
        </div>
    )
}

export default Setting