import React from "react";

const CGSV = (props) => {

    return(
        <div id={"community_cgu_wrapper"}>
            <h1>Conditions générales de services et d'utilisation</h1>
            <p></p>
            <h2>Préambule</h2>
            <p>
                Les présentes conditions, qui ont une valeur contractuelle, ont pour objet de définir les conditions et modalités d'utilisation des services et des fonctionnalités accessibles depuis le site web ou mobile www.esterlaw.io et les applications EsterLaw IOS et Android.Elles ont également pour objet de définir les droits et obligations des parties. Elles peuvent être complétées par des conditions d'utilisations particulières à certains services.
                <br/>
                <br/>
                L'utilisation de ces Services c'est-à-dire de l'ensemble des produits, logiciels, Services et sites Web de la société EsterLaw SAS, est régie par les termes d'un contrat légal conclu entre EsterLaw SAS et vous selon les présentes conditions.
                <br/>
                <br/>
                La plateforme EsterLaw, est une plateforme de mise en relation par Matching entre des professionnels qui proposent des missions ponctuelles et des professionnels offrant leurs compétences.
                <br/>
                <br/>
                EsterLaw permet également aux avocats de communiquer entre eux au ein d'une communauté privée qui leur est réservée  et d'être mis en relation dans le cadre d'entraide confraternelle.
                <br/>
                <br/>
                L'accès à la plateforme et aux services implique l'acceptation et le respect des présentes conditions, que l'utilisateur reconnaît avoir lues, comprises et acceptées.
                <br/>
                <br/>
                Nous vous invitons également à prendre connaissance de nos informations légales et de notre politique de confidentialité relative aux traitements des données à caractère personnel.
            </p>
            <h2>1. Définitions :</h2>
            <p>
                Les mots et expressions ci-après commençant ou non par une lettre majuscule, au singulier ou au pluriel,  sont employés dans les présentes avec la signification suivante :
                <br/>
                <br/>
                « Nous » et/ou  « esterLaw » : désigne EsterLaw SAS, au capital de 5000 Euros, dont le siège social est situé 78 avenue des Champs Elysées 75008 Paris enregistrée au Registre du Commerce et des Sociétés de Paris sous le numéro 984 397 554 représenté par son représentant légal.
                <br/>
                « Vous » : désigne les Clients, Utilisateurs et Membres.
                <br/>
                « Site » : désigne le site web ou mobile www.esterlaw.io et nos applications EsterLaw IOS et Android.
                <br/>
                « Plateforme » : désigne l'espace en ligne accessible depuis notre site web et/ ou nos applications IOS et Android.
                <br/>
                « Services » : désigne l'accès à l'ensemble des produits, logiciels, services et sites de la société EsterLaw SAS.
                <br/>
                « Avocat » personne physique inscrite au tableau de l'Ordre des Avocats de l'un des 164 barreaux français, de la métropole et d'Outre-mer et qui a souscrit au Service.
                <br/>
                « Client » : désigne la personne physique ou morale qui s'inscrit et crée un compte afin d'avoir accès aux Services, au travers d'un abonnement gratuit ou payant.
                <br/>
                « Utilisateur » : désigne la personne physique qui s'inscrit et dispose d'un compte utilisateur et qui renseigne son profil, soit en qualité de cliente pour les travailleurs indépendants, soit en qualité d'associé ou de salarié d'une société ou d'une structure d'exercice disposant de la personnalité morale déjà cliente.
                <br/>
                « Membre » : désigne l'utilisateur avocat membre de la communauté #esterLawyers
                <br/>
                « Compte » : désigne l'interface du site ou de l'application qui permet à chaque client et/ ou utilisateur d'avoir accès aux Services de l'application et où il renseigne les informations le concernant.
                <br/>
                « Profil » désigne les espaces au sein de la plateforme correspondant soit à un profil d'utilisateurs, proposant des missions (profils propositions missions) ou leurs compétences (profils Talents/ propositions de compétences), les utilisateurs peuvent avoir les deux profils. Les utilisateurs y renseignent des informations professionnelles et personnelles.
                <br/>
                « Talents» / « Candidat » : désigne tout utilisateur qui répond à une offre de mission en suite d'un Matching entre les critères de l'offre et les critères de son profil professionnel.
                <br/>
                « Mission » : désigne toute offre de Services créée et proposée par un utilisateur. Pour les avocats il s'agit de toute prestation intellectuelle telles que consultations juridiques, conseils, analyses, rédaction de contrats, d'actes juridiques.
                <br/>
                « Aide confraternelle » : désigne tout service demandé et/ ou rendu entre avocat à titre confraternel et gratuit.
                <br/>
                « Formule d'Abonnement » : désigne les conditions spécifiques d'accès pour un Client aux Services en contrepartie du paiement d'un tarif pour une période définie.
                <br/>
                « Contenu » : désigne toute donnée, information, image, fichier, son, texte, programme, logiciel, code, ou élément de toute nature qui serait exploité, diffusé, stocké, transmis, émis, collecté, traité ou mis à disposition directement ou indirectement au moyen des Service
                <br/>
                « Matching » : désigne dans le contexte de la plateforme professionnelle  EsterLaw un processus d'appariement intelligent entre l'offre et la demande. Il s'agit d'un système automatisé qui analyse et compare les profils, les compétences, les disponibilités,  les besoins et les critères spécifiques des différents utilisateurs pour proposer des correspondances pertinentes et optimales.
                <br/>
                « Match » : désigne dans le contexte de la plateforme professionnelle  EsterLaw  une concordance optimale entre une offre de mission et une offre de service, il s'agit du résultat du processus de matching.
                <br/>
                « Mise en relation » : une mise en relation désigne, dans le contexte de la plateforme professionnelle EsterLaw, le processus par lequel deux utilisateurs ayant manifesté un intérêt mutuel suite à un match découvrent leurs identités respectives et peuvent communiquer  via la messagerie de la plateforme.
            </p>
            <h2>2. Accès aux Services - Inscription - Acceptation des conditions de service et de vente</h2>
            <p>
                L'accès aux Services est dédié et réservé aux professionnels résidant sur le territoire français hexagonal et d'outre-mer.
                <br/>
                <br/>
                En vous inscrivant, vous vous engagez et attestez fournir des informations correctes,  exactes et actuelles que vous tiendrez à jour.
                <br/>
                <br/>
                L'accès au service s'effectue en s'inscrivant sur la plateforme via la création d'un compte.
                <br/>
                <br/>
                L'inscription nécessite obligatoirement la fourniture de votre identité, adresse e-mail et numéro de mobile afin de réception d'un code de vérification.
                <br/>
                <br/>
                En vous inscrivant et en accédant aux services vous certifiez que vous êtes âgé de plus de 18 ans, que vous agissez et vous engagez dans le cadre d'une activité professionnelle, et reconnaissez donc que les dispositions protectrices applicables aux contrats conclus avec les consommateurs ainsi que les dispositions des articles 1369-5 et 1369-4 du code civil ne vous sont pas applicables.
                <br/>
                <br/>
                Lorsque vous contractez pour le compte d'une structure quelle que soit sa forme vous certifiez que vous avez le pouvoir d'engager cette structure et de contracter avec nous.
                <br/>
                <br/>
                L'accès aux services en qualité d'avocat est réservé à toute personne physique habilitée à exercer la profession d'avocat en France, dans les conditions de la loi n° 71-1130 du 31 décembre 1971 modifiée ainsi que du décret n°91-1197 du 27 novembre 1991 et soumise aux règlements de la profession, ayant prêté serment d'avocat et régulièrement inscrite à un Barreau français et dont l'inscription n'est ni suspendue ni omise.
                <br/>
                <br/>
                L'inscription d'un avocat nécessite une vérification des informations fournies par carte professionnelle ou attestation de l'ordre des avocats par EsterLaw. Le compte de l'utilisateur et /ou de membre de l'avocat n'est actif qu'après cette vérification et validation.
                <br/>
                <br/>
                Nous rappelons que les usurpations d'identité ou de titre, tel celui d'avocat, constituent des délits prévus et réprimés respectivement par les articles 226-4-1 et 433-17 du Code Pénal.
                <br/>
                <br/>
                Si EsterLaw découvre que certaines des informations que vous avez fournies, sont inexactes, incomplètes ou obsolètes, ou si nous considérons à notre entière discrétion et sans avoir à fournir d'explications ou de justification, que les utilisateurs et/ ou clients ou les actions menées au sein de la plateforme  ne sont pas en adéquation avec nos Services et / ou nos valeurs éthiques,  nous pouvons suspendre ou résilier à tout moment,  tous les droits d'accès, de réception, d'utilisation des  Services sans préavis ni indemnisation  et ce à  quelque titre que ce soit. Les abonnements payants en cours seront le cas échéant remboursés au prorata temporis.
                <br/>
                <br/>
                Vous devez accepter les conditions avant de pouvoir utiliser les Service par un processus d'acceptation par clic, lors de votre inscription ou lors de la souscription d'un abonnement.
                <br/>
                <br/>
                EsterLaw innove en permanence, nos services et la plateforme sont évolutifs, vous reconnaissez et acceptez que la forme et la nature des services fournis sont susceptibles d'être modifiées sans préavis.
                <br/>
                <br/>
                EsterLaw peut modifier ces Conditions à tout moment en publiant une version révisée des Conditions accessible en ligne et/ou en vous adressant des informations relatives à la modification des Conditions à l'adresse électronique que vous avez renseignée. Toute modification des Conditions applicables à un Client sera soumise à son acceptation.
            </p>
            <h2>3. Fonctionnement de la plateforme et des Services</h2>
            <h3>3.1 Description des prestations</h3>
            <p>
                EsterLaw SAS a développé la plateforme EsterLaw de mise en relation par Matching entre des professionnels qui proposent des missions et des professionnels qui proposent leurs compétences.
                <br/>
                <br/>
                EsterLaw permet également aux avocats utilisateurs d'EsterLaw de devenir membres de la communauté #esterLawyers afin de faciliter l'entraide  et la communication entre confrères.
                <br/>
                <br/>
                EsterLaw SAS n'exerce qu'un rôle d'intermédiaire technique entre les utilisateurs professionnels.
                <br/>
                <br/>
                Le service proposé par EsterLaw est uniquement de mettre le site et la technologie développée au service de ses utilisateurs.
                <br/>
                <br/>
                EsterLaw SAS ne fournit aucune prestation de nature juridique.
                <br/>
                <br/>
                EsterLaw n'intervient pas dans les discussions entre les utilisateurs. Les honoraires des avocats sont librement fixés par ces derniers ainsi que les tarifs des prestataires de Services.
                <br/>
                <br/>
                Il appartient aux avocats de vérifier l'absence de conflits d'intérêts et de conclure des conventions de collaboration inter cabinets ou des conventions de mission et d'honoraires.
                <br/>
                <br/>
                De manière générale, il appartient aux utilisateurs de contractualiser leurs relations.
                <br/>
                <br/>
                Ces contrats sont négociés et conclus hors intervention d'EsterLaw SAS sous la seule, pleine et entière responsabilité des utilisateurs.
                <br/>
                <br/>
                Les missions sont réalisées hors intervention d'EsterLaw et sous la seule et exclusive responsabilité des utilisateurs.
                <br/>
                <br/>
                La facturation et le règlement des missions s'effectuent directement par les utilisateurs hors la plateforme EsterLaw et hors le concours d'EsterLaw.
                <br/>
                <br/>
            </p>
            <h3>3.2 Services communs à tous les utilisateurs</h3>
            <p>
                Sous réserve du respect des présentes conditions générales et de la formule d'abonnement qui sera choisie EsterLaw met en relation les professionnels tels qu' avocats, directions juridiques et plus largement toutes structures professionnelles, commerciales ou  associatives  cherchant à confier des missions juridiques,  judiciaires ou de sous-traitance à des avocats dans le respect de la loi du 31 décembre 1971,  dans sa version en vigueur portant réforme de certaines professions judiciaires et juridiques et principalement des articles 56 et 57.
                <br/>
                <br/>
                Les directions juridiques, entreprises et associations sont exclusivement mises en relation avec des avocats.
                <br/>
                <br/>
                Par exception, pour certaines missions spécifiques, non réservées aux avocats, telles que celles d'audits, de Data Protection Officer externe  et de médiation, les entreprises pourront, à leur demande, être mises en relation avec des professionnels non avocats.
                <br/>
                <br/>
                Les avocats peuvent choisir de diriger leurs offres de mission selon leur objet vers des confrères avocats, des professeurs de droit ou divers experts.
                <br/>
                <br/>
                Le professionnel qui a besoin de renfort renseigne sur la plateforme les critères de son offre de mission correspondant à une prestation spécifique.
                <br/>
                <br/>
                L'offre de mission n'est pas publiée sur le site et n'est pas visible par tous les utilisateurs.
                <br/>
                <br/>
                Immédiatement après validation de l'offre de mission, par l'offrant, EsterLaw utilise son algorithme de Matching pour identifier et informer automatiquement les utilisateurs d'EsterLaw dont les profils, renseignés et enrichis le cas échéant par des données professionnelles publiques essentielles, correspondent le mieux à l'offre.
                <br/>
                <br/>
                Afin d'améliorer encore la pertinence des mises en relation, des données professionnelles publiques supplémentaires sont susceptibles d'être collectées avec votre consentement préalable. Pour plus d'informations concernant la collecte et l'utilisation de ces données, nous vous invitons à consulter notre Politique de confidentialité.
                <br/>
                <br/>
                Seules les caractéristiques de l'offre sont communiquées.
                <br/>
                <br/>
                Les Matching sont notifiés de manière automatique dans la limite de cinq matchs afin de permettre à chaque Talent/candidat de se positionner, si les matchs ne donnent pas lieu à manifestation d'intérêts par les deux parties, l'algorithme propose de nouveaux Matchs dans la limite de cinq Matchs simultanés en attente de réponse de l'offrant.
                <br/>
                <br/>
                EsterLaw SAS n'effectue aucune vérification, des compétences et autres informations professionnelles renseignées, il vous appartient d'effectuer les vérifications et/ou de demander à vos interlocuteurs les justificatifs et/ou informations complémentaires que vous considérez nécessaires.
                <br/>
                <br/>
                L'utilisateur qui reçoit la notification d'un Matching entre les critères de son profil professionnel et une offre s'engage à en prendre connaissance dans les plus brefs délais et à indiquer s'il est intéressé.
                <br/>
                <br/>
                L'utilisateur qui propose une mission est informé lorsqu'un Talent qui propose ses compétences a manifesté son intérêt pour l'offre, il peut alors découvrir les informations renseignées par les professionnels qui proposent leurs compétences dans leur profil. Ces renseignements ne contiennent pas les noms, prénoms, coordonnées ni photo afin de préserver confidentialité et égalité.
                <br/>
                <br/>
                Si l'utilisateur qui a publié l'offre de mission est intéressé par un ou plusieurs des profils professionnels des Talents intéressés par son offre, EsterLaw communique les identités et met en relation les utilisateurs concernés au moyen d'une messagerie instantanée et d'une solution de visioconférence intégrées à l'application.
                <br/>
                <br/>
                Les messages échangés  entre les utilisateurs à partir des services de messagerie instantanée  sont des correspondances privées et ne sont pas accessibles par EsterLaw. Le contenu des  messages relève de la pleine et entière responsabilité des utilisateurs.
                <br/>
                <br/>
                La solution de Visioconférence  est pear to pear.
                <br/>
                <br/>
            </p>
            <h3>3.3 Services réservés aux avocats</h3>
            <p>
                EsterLaw propose aux avocats l'accès à la communauté #esterLawyers composée d'avocats utilisateurs d'EsterLaw. Cet espace facilite l'entraide confraternelle et gratuite, (telles que démarches simples, renvois à une audience, échanges de permanences, modelés d'actes…).
                <br/>
                <br/>
                La participation à la communauté, accessible à tout avocat utilisateur d'EsterLaw,  nécessite l'acceptation et l'adhésion préalable à la charte proposée lors de la procédure  d'inscription des avocats.
            </p>

            <h2>4. Abonnements – Accès aux Services</h2>
            <p>
                L'inscription est gratuite.
                <br/>
                <br/>
                EsterLaw ne perçoit aucune rémunération ni commission du fait des missions proposées sur la plateforme.
                <br/>
                <br/>
                EsterLaw peut proposer des Services gratuits, des abonnements gratuits, et des abonnements d'essai à sa seule discrétion.
                <br/>
                <br/>
                Si l'accès aux Services vous est fourni gratuitement, dans le cadre de programmes, de services gratuits ou à des fins d'essai, cet accès est également régi par les présentes Conditions.
                <br/>
                <br/>
                À tout moment avant ou pendant la période gratuite, des services gratuits ou d'essai, EsterLaw se réserve le droit, à sa seule discrétion, de résilier l'accès sans préavis et sans aucune responsabilité envers vous, pour quelque raison que ce soit.
                <br/>
                <br/>
                Après expiration de la période d'accès gratuit, des services gratuits ou de l'offre d'essai, vous ne pourrez continuer à utiliser les services qu'en souscrivant un abonnement payant.
                <br/>
                <br/>
                Vous souscrivez une formule d'abonnement et vous vous acquitterez du prix conformément aux conditions particulières déterminées par la formule d'abonnement que vous aurez choisie.
                <br/>
                <br/>
                L'abonnement est non remboursable et est renouvelé automatiquement à l'issue de sa durée. Vous pouvez interrompre l'abonnement à tout moment depuis votre compte, dans ce cas l'abonnement se termine à l'issue de la période en cours puis n'est plus renouvelé.
                <br/>
                <br/>
                Le compte n'est pas supprimé de facto lors de l'interruption de l'abonnement, vous pouvez à tout moment décider de reprendre un abonnement et vous continurez à recevoir le cas échéant, sauf opposition de votre part, et, selon les paramétrages de vos préférences de communication, des notifications sur les activités d'EsterLaw.
            </p>

            <h2>5. Comptes – Sécurité</h2>
            <p>
                En tant qu'utilisateur, vous êtes responsable de tous les renseignements que vous fournissez et de toutes les activités que vous réalisez.
                <br/>
                <br/>
                L'accès au compte est protégé par un identifiant et un mot de passe que vous choisissez lors de votre inscription sur EsterLaw et que vous pouvez changer à tout moment. Votre mot de passe est chiffré et n'est pas accessible aux collaborateurs d'EsterLaw. Pour modifier votre mot de passe ou en cas d'oubli vous pourrez demander la réinitialisation de votre mot de passe à tout moment en suivant le processus proposé lors de la connexion ou dans votre compte utilisateur.
                <br/>
                <br/>
                Vous vous engagez à conserver vos mots de passe de manière sécurisée et confidentielle. Les identifiants et mots de passe sont strictement personnels. Il vous incombe de prendre des mesures raisonnables pour maintenir la sécurité et conserver le contrôle de votre compte.
                <br/>
                <br/>
                Nous vous demandons de fournir un numéro de téléphone lors de votre inscription afin de vous envoyer un code de sécurité. La responsabilité d'EsterLaw ne peut être engagée pour toute perte ou préjudice que vous pourriez subir en raison d'une compromission de vos informations d'identification de connexion à votre compte.
                <br/>
                <br/>
                Il vous appartient de donner suite aux avis ou alertes que nous pourrions être amenés à vous envoyer à votre adresse électronique ou sur votre numéro de téléphone.
                <br/>
                <br/>
                Si vous pensez que votre compte ou l'une de vos informations de sécurité ont été  compromis, veuillez contacter l'Assistance clientèle. Vous êtes tenu pour responsable de toutes pertes et dommages qu'EsterLaw pourrait subir du fait d'un accès illégitime à votre compte.
            </p>

            <h2>6. Comportement de l'utilisateur</h2>
            <p>
                Vous devez accéder et utiliser les Services de manière appropriée et responsable dans le respect des lois et règlements.
                <br/>
                <br/>
                Notamment vous vous engagez à respecter les interdictions suivantes :
                <br/>
                <ul>
                    <li>Renseigner et/ou communiquer des informations fausses ou mensongères ;</li>
                    <li>Induire ou de tenter d'induire en erreur d'autres utilisateurs en usurpant l'identité ou une dénomination sociale, ou une fonction ;</li>
                    <li>Porter atteinte à l'image ou à la réputation d'EsterLaw ou des utilisateurs ;</li>
                    <li>Tenir des propos contraires à l'ordre public, aux bonnes mœurs. Notamment sont interdits tout propos médisants, méprisants, irrespectueux, injurieux, outrageants, diffamatoires, violents, discriminatoires ou comprenant une provocation à la haine, à la violence ou à la discrimination en raison de leur origine, de leur religion, de leur sexe ou de leur orientation sexuelle, portant atteinte à la dignité et la réputation ;</li>
                    <li>Divulguer des informations échangées dans le cadre de la plateforme ;</li>
                    <li>Porter atteinte à la vie privées des tiers ou au secret des correspondances ;</li>
                    <li>Utiliser les Services de manière contraire aux règles déontologiques de la profession d'avocat ;</li>
                    <li>Perturber, ralentir, bloquer ou altérer le flux normal des données échangées dans le cadre d'EsterLaw ;</li>
                    <li>Transmettre ou tenter de transmettre virus, logiciel espion, code ou tout autre élément portant atteinte au bon fonctionnement des systèmes, à la confidentialité, l'intégrité et la disponibilité des données.</li>
                    <li>Accéder ou tenter d'accéder frauduleusement dans les systèmes d'information et de communication d'EsterLaw ;</li>
                </ul>
                <br/>
                Par ailleurs, vous reconnaissez et acceptez que vous demeurez seul responsable des traitements que vous réalisez sur la plateforme, vous vous engagez à respecter les dispositions relatives aux règles professionnelles et à la protection des données et assumez seul responsable de toute collecte ou communication de données inappropriée et/ ou contraire aux règles relatives à la confidentialité et/ou au secret professionnel.
                <br/>
                <br/>
                Vous reconnaissez que vous assumez seul toutes les conséquences juridiques et judiciaires directes ou indirectes de tout comportement ou traitement inapproprié ou illégal.
                <br/>
                <br/>
                Vous pouvez nous signaler tout contenu inapproprié ou illégal à l'aide du formulaire que vous trouverez dans votre compte utilisateur.
            </p>

            <h2>7. Propriété intellectuelle d'EsterLaw</h2>
            <p>
                L'intégralité du contenu du site et de la plateforme Esterlaw accessible depuis notre site web et/ ou nos applications IOS et Android, tant en ce qui concerne la structure générale que les contenus, textes, algorithmes, éléments graphiques, photographiques et vidéos, sons, logos, icones, documents téléchargeables, sont la propriété exclusive de EsterLaw SAS ou utilisés par EsterLaw SAS avec l'accord des titulaires de droits. Aucune représentation, reproduction ni utilisation de tout ou partie du site, de la plateforme ou de leurs contenus, par quelques procédés que ce soient, sans l'autorisation préalable et écrite de EsterLaw SAS n'est autorisée. Le non-respect de cette interdiction constituerait une contrefaçon sanctionnée par les articles L335-2 et suivants du Code de la Propriété intellectuelle.
                <br/>
                <br/>
                Les bases de données figurant sur la plateforme sont protégées par les dispositions de la loi du 11 juillet 1998 portant transposition dans le Code de la propriété intellectuelle (CPI) de la directive européenne du 11 mars 1996 relative à la protection juridique des bases de données. Sont notamment interdites l'extraction et la réutilisation, quantitativement ou qualitativement substantielles, du contenu des bases de données contenues dans la plateforme. Tout contrevenant s'expose aux sanctions visées aux articles L 343-1 et suivants du Code de la propriété intellectuelle.
                <br/>
                <br/>
                Nous concédons aux utilisateurs, à titre exclusif, personnel et non transférable, une utilisation selon les conditions et durées d'accès définies à l'article 4.
            </p>

            <h2>8. Obligations Responsabilité d'EsterLaw</h2>
            <p>
                EsterLaw SAS est soumise à une obligation de moyens. Elle s'engage à fournir les Services décrits dans les présentes conditions.
                <br/>
                <br/>
                EsterLaw SAS s'engage dans la mesure du possible à effectuer les opérations de maintenance durant les heures habituelles d'inactivité, mais en tout état de cause sa responsabilité d'EsterLaw ne peut pas être engagée en cas d'interruption temporaire d'accès à la plateforme et aux Services en raison opérations de maintenance, à des mises à jour et améliorations techniques, à des évolutions de contenu ou de présentation, à un cas de force majeur ou à la survenance d'un évènement hors du contrôle d'EsterLaw.
                <br/>
                <br/>
                EsterLaw n'a aucune obligation dans le cadre des prestations de service de mise en relation, de sauvegarde des données qui transitent le cas échéant par la plateforme, il vous appartient de prendre toutes mesures nécessaires afin de sauvegarde.
                <br/>
                <br/>
                EsterLaw n'assume aucune obligation ni responsabilité de quelque nature que ce soit en ce qui concerne :
                <br/>
                <ul>
                    <li>Le non-respect des utilisateurs des interdictions mentionnées à l'article 6 et des autres dispositions des présente ;</li>
                    <li>Les contenus des échanges et des publications qui transitent ou qui sont publiées sur dans la plateforme ou sur le site relèvent de la seule responsabilité des avocats et professionnels concernés ;</li>
                    <li>Les failles de sécurité des équipements d'un utilisateur ou de compromission de ses mots de passes ;</li>
                    <li>Les conséquences de rupture d'accès à Internet et/ou aux réseaux de communication ;</li>
                </ul>
                Version au 22 octobre 2024
            </p>

            <br/>
            <br/>
            <div className={"wta_wrapper"}>
                <a className={"cta full blue"} onClick={props.handleClose}>Fermer</a>
            </div>
        </div>
    )
}

export default CGSV