import Api from "../helper/api";
import {
    STEP_AGREEMENT,
    STEP_DONE,
    STEP_RECIPIENT_ACCEPTED,
    STEP_SENDER_ACCEPTED,
    STEP_SENDER_REFUSED
} from "../other/Constant";
/**
 * Sitchs duty loaders
 * @param context
 * @returns {Promise<{userApplications: Promise<*>, userHelps: Promise<[]|*>, helps: Promise<[]|*>, urgentHelps: Promise<[]|*>}>}
 */
export const getSwitchs = async (context) => {

    const api = new Api();
    let switchsPromise = api.getUserSwitchDuties(true,false, null);
    let urgentSwitchsPromise = api.getUserSwitchDuties(true,false, true);
    let userSwitchsPromise = api.getUserSwitchDuties(null,false, null, context.state.user.id);
    let userApplicationsPromise = api.getSwitchLinkings(null, [STEP_RECIPIENT_ACCEPTED, STEP_AGREEMENT, STEP_SENDER_ACCEPTED, STEP_SENDER_REFUSED]);
    let userDonesPromise = api.getUserSwitchDuties(true,true, null, context.state.user.id);
    let applicationDonesPromise = api.getSwitchLinkings(null, [STEP_DONE]);

    return {
        switchs : switchsPromise,
        urgentSwitchs : urgentSwitchsPromise,
        userSwitchs : userSwitchsPromise,
        userApplications : userApplicationsPromise,
        userDones: userDonesPromise,
        applicationDones: applicationDonesPromise
    };
}

/**
 * Return data for current switch
 * @param params - contain switchId
 */
export const getSwitch = async ({params}) => {

    const api = new Api()
    const switchPromise = api.getSwitch(params.switchId)

    return {
        switchPromise : switchPromise
    }
}

/**
 * Return Switch linking List for a specific switch duty
 * @param params - contain switchId
 */
export const getSwitchMatchs = async ({params}) => {

    const api = new Api()
    const matchsPromise = api.getSwitchLinkings(params.switchId, null, true)

    return {
        type : "echange",
        matchs : matchsPromise
    }
}