import React, {useState} from "react";
import Api from "../../helper/api";

const ConfirmSwitchRequest = ({switchId, handleClose}) => {

    const api = new Api();
    const [confirm, setConfirm] = useState(false)

    const handleConfirm = () => {
        api.createSwitchLinking(
            switchId,
        ).then((data) => setConfirm(true))
    }

    return (
        <div id={"confirmhelp_wrapper"} className={"popup_confirm"}>
            <div className={"bloc_title_info"}>
                {!confirm && <h2>Proposer un échange</h2>}
                {!confirm && <p>Je suis intéressé et souhaite discuter</p>}
                {confirm && <h2>Proposition d'échange envoyée !</h2>}
            </div>

            <div id={"confirmhelp_cta_wrapper"} className={"cta_wrapper"}>
                {!confirm && <button className={"cta full blue reverse"} onClick={handleConfirm}>Oui</button>}
                <button className={"cta full blue"} onClick={() => handleClose(confirm)}>{confirm ? 'Fermer' : 'Annuler'}</button>
            </div>
        </div>
    )
}

export default ConfirmSwitchRequest