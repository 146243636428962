import React from "react";
import ReactModal from 'react-modal';
import {isMobile} from "react-device-detect";


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        minWidth: '600px',
        transform: 'translate(-50%, -50%)',
        borderRadius: '10px'
    },
};

const SlidingPanel = ({show, handleClose, children, forceSliding}) => {
    return (
        <>
            {!isMobile && !forceSliding && <ReactModal
                isOpen={show ? true : false}
                onRequestClose={handleClose}
                style={customStyles}
                parentSelector={() => document.querySelector('.page_wrapper')}
            >
                <a href={"#0"} id={"sliding_panel_close"} onClick={handleClose}></a>
                <div id={"sliding_panel_body"}>
                    {children}
                </div>
            </ReactModal>}
            {(isMobile || forceSliding) && <div id={"sliding_panel"} className={ show?'show':'' }>
                <a href={"#0"} id={"sliding_panel_close"} onClick={handleClose}></a>
                <div id={"sliding_panel_body"}>
                    {children}
                </div>
            </div>}
        </>
    )
}

SlidingPanel.defaultProps = {
    show : false,
    handleClose : () => {console.log("handleClose props is missing")}
}

export default SlidingPanel