import Api from "../helper/api";
import {redirect, useLocation} from "react-router-dom";
import {PROFIL_TYPE_RECRUTEUR} from "../other/Constant";

/**
 *
 * @returns {Promise<{missions: ([]|*), unreadMatch: number, user: axios.AxiosResponse<*>}>}
 */
export const getUserStepStatus = async (context) => {

    let isLoggedIn = true;
    let showOptionChoiceScreen = false;
    let showVerifiedPhoneScreen = false;
    let notifications = []

    //get user from context provider
    // console.log("LOAD USER 22222")
    const user = await Api.getUser().catch(() => {
        isLoggedIn = false;
    });
    // const user = context.state.user
    if (user !== undefined) {
        //check if user set is preference using mission and skill flag, only for profileType 1 and only if phone has been verified
        showOptionChoiceScreen = user.verified && (typeof user.mission === 'undefined' || user.mission === null) && (typeof user.skill === 'undefined' || user.skill === null) && user.profileType === 1;
        //check if user validate phone number
        showVerifiedPhoneScreen = !user.verified;

        // notifications = await Api.getUserNotifications()
        // if (notifications?.filter((notification) => notification.read === false).length) {
            // setNewNotifications(true)
        // }
        // if (location.pathname.substring(1).indexOf("notification") === -1) {
            // setNotifications(data)
        // }
    } else {
        isLoggedIn = false
    }

    return {
        isLoggedIn : isLoggedIn,
        showOptionChoiceScreen : showOptionChoiceScreen,
        showVerifiedPhoneScreen : showVerifiedPhoneScreen,
        user: user,
        notifications: notifications
    };

}
