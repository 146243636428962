import Api from "../helper/api";
import {createContext, useContext, useEffect, useState} from "react";
import { Client } from "twilio-chat";
import moment from "moment";

const TwilioContext = createContext();

export const TwilioProvider = ({ isLoggedIn, children }) => {

    const api = new Api()

    const [chatClient, setChatClient] = useState(null);
    const [conversations, setConversations] = useState([]);
    const [convFetched, setConvFetched] = useState(false)

    //on component mount
    useEffect(() => {
        if (isLoggedIn) {
            initTwilio()
        }
    }, [isLoggedIn]);

    const initTwilio = async () => {

        //get token from ester api
        const token = await api.getTwilioToken()

        //init twilio with token
        const client = await new Client(token.token);

        client.on("stateChanged", (state) => {
            if (state === 'initialized') {
                setChatClient(client)
                fetchConversations(client)
            }
        });

        client.on("tokenAboutToExpire", async () => {
            const token = await api.getTwilioToken();
            client.updateToken(token.token);
        });

        client.on("tokenExpired", async () => {
            const token = await api.getTwilioToken();
            client.updateToken(token.token);
        });

        client.on("channelAdded", async (conversation) => {
            console.log(conversation, "NEW CONV FROM BACK")
            // fetchConversations(client, conversation)
            if (convFetched){
                if (!conversations.find(channel => { return channel.sid === conversation.sid })) {
                    conversation.messages = await fetchMessages(conversation, [], 0);
                    setConversations([...conversations, conversation]);
                }
            }

            // if (store.getState() && store.getState().conversations && store.getState().conversations.length) {
            //     console.log(conversation, 'NEW CONV !')
            //     const conv = TwilioService.getInstance().parseConversation(conversation)
            //     console.log(conv, 'NEW PARSED CONV !')
            //     console.log(store.getState().conversations.length, 'CURRENT NB CONV')
            //     store.dispatch({ type: 'ADD_CONVERSATION', conversation: conv })
            //
            //     conversation.getMessages().then(messagePaginator => {
            //         if (messagePaginator.items.length) {
            //             const message = this.processConversation(messagePaginator)
            //             if (message) {
            //                 conv.lastMsg = TwilioService.getInstance().parseMessage(message)
            //                 if (conv.lastReadMessageIndex < message.index) {
            //                     conv.unreadCount = message.index - conv.lastReadMessageIndex
            //                     store.dispatch({ type: 'UNREAD_MESSAGES', unreadMessages: true })
            //                 }
            //                 store.dispatch({ type: 'UPDATE_CONVERSATION', conversation: conv })
            //             }
            //         }
            //     })
            // }
        });

        client.on("messageAdded", (message) => {
            // fetchConversations(client)
            // console.log(conversations, "NEW MESSAGE FROM BACK")
            if (conversations.length) {
                // console.log(message.channel.sid, "NEW MESSAGE ADDED")
                const conversation = conversations.find(obj => { return obj.sid === message.channel.sid })
                if (conversation) {
                    let c = conversation
                    // console.log(message, "NEW MESSAGE ADDED")
                    // console.log(c, "CURRENT CONV")
                    c.lastMsg = message
                    // if (message.user._id !== user.uid && (store.getState().currentConversation === null || store.getState().currentConversation?.sid !== c.sid)) {
                    //     c.unreadCount++
                    //     store.dispatch({ type: 'UNREAD_MESSAGES', unreadMessages: true })
                    // }
                    // setConversations(conversations.map(obj => {
                    //     if (obj.sid === c.sid) {
                    //         return c;
                    //     }
                    //     return obj;
                    // }))
                }
            }
        });
    }

    // const logout = async () => {
    //     if (chatClient) {
    //         await chatClient.shutdown()
    //         setChatClient(null)
    //     }
    //     TwilioService.twilioToken = null;
    // }

    const fetchConversations = async (client, conversation = null) => {
        if (client) {
            try {
                //fetch conversations
        //         if (conversation) {
        //             // console.log(conversation.sid, "FOUND CONV")
        //             if (!conversations.find(channel => { return channel.sid === conversation.sid })) {
        //                 // const paginator = await conversation.getMessages();
        //                 conversation.messages = await fetchMessages(conversation, [], 0);
        //                 // console.log(conversation.messages.length, "MESSAGES")
        //                 // console.log(conversation.messages.hasNextPage, '6666')
        //                 setConversations([...conversations, ...[conversation]]);
        //             }
        //         } else {
                    client.getSubscribedChannels().then(async (paginator) => {
                        if (paginator && paginator.items.length > 0) {
                            // console.log(paginator.items.length, 'NB CHANNELS')
                            const conversationsWithMessages = await Promise.all(
                                paginator.items.map(async (channel) => {
                                    channel.messages = await fetchMessages(channel, [], 0);
                                    // const messagePaginator = await channel.getMessages();
                            //         console.log(channel.sid, "channel")
                            //         // console.log(messagePaginator, "messagePaginator")
                            //         // channel.messages = messagePaginator
                                    return channel
                                })
                            );
                            console.log(conversationsWithMessages, "NEW CONVERSATIONS FOUND !")
                            setConversations(conversationsWithMessages)
                            setConvFetched(true)
                        }
                    })
                // }
                //fetch messages
                // const conversationsWithMessages = await Promise.all(
                //     subscribedChannels.items.map(async (channel) => {
                //         channel.messages = await channel.getMessages();
                //         return channel
                //     })
                // );
                // setConversations(conversationsWithMessages);
            } catch (error) {
                console.error('Error fetching conversations:', error);
            }
        }
    }

    const sendMessage = async (channelId, message) => {
        try {
            const channel = await chatClient.getChannelBySid(channelId);
            await channel.sendMessage(message);
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    const formatMessage = (message) => {
        const hours = message.state.timestamp.getHours();
        const minutes = message.state.timestamp.getMinutes();

        const day = message.state.timestamp.getDate();
        const month = message.state.timestamp.getMonth() + 1;
        const year = message.state.timestamp.getFullYear();

        return({
            _id: message.sid,
            message: message.state.body,
            // sentTime: message.dateCreated,
            sender : message.author,
            position : "single",
            direction : chatClient?.user?.identity === message.author ? "outgoing" : "incoming",
            senttime : message.dateCreated ? moment(message.dateCreated).locale('fr').format('H:m') : '',
            sentdate : `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`,
            name : message.author.charAt(0)+message.author.charAt(1)
        })
    }

    const fetchMessages = async (conversation, messages, lastMessageIndex) => {
        let paginator = null;
        if (lastMessageIndex > 0) {
            paginator = await conversation.getMessages(30, lastMessageIndex, "forward")
        } else {
            paginator = await conversation.getMessages(30, 0, "forward")
        }
        if (paginator.items && paginator.items.length) {
            // const formatedMessages = paginator.items.map((message) => formatMessage(message))
            // console.log(paginator.items)
            // messages = [...messages, ...formatedMessages]
            messages = [...messages, ...paginator.items]

            // console.log(paginator, '6666')
            if (paginator.hasNextPage) {
                // const nextPaginator = await paginator.nextPage()
                // console.log(nextPaginator.items, '555')
                // console.log(paginator.items[paginator.items.length - 1]?.index, '4444')

                // return await fetchMessages(conversation, messages, paginator.items[paginator.items.length - 1]?.index)
                messages = await fetchMessages(conversation, messages, paginator.items[paginator.items.length - 1]?.index)
                // console.log(messages, 'nextMessages')
                // return nextMessages
            }
        }
        return messages
    };

    // const processConversation = (paginator) => {
    //     if (paginator.hasNextPage) {
    //         const nextPaginator = paginator.nextPage()
    //
    //         return processConversation(nextPaginator)
    //     }
    //     const message = paginator.items.pop()
    //     if (message) {
    //         return message
    //     }
    //     return null
    // }

    return(
        <TwilioContext.Provider value={{chatClient, conversations, sendMessage}}>
            {children}
        </TwilioContext.Provider>
    )

}
export const useChat = () => useContext(TwilioContext);
