import React, {useEffect, useState, Suspense} from "react";
import {Await, useLoaderData, useNavigate, useOutletContext} from "react-router-dom";

import FilterButtonBar from "../../other/FilterButtonBar";
import MissionBlock from "./_MissionBlock";

import SlidingPanel from "../_sliding_panel";
import AddMission from "../../slidingContent/addMission";
import {GenericEmptyItem} from "../../other/GenericBlock";
import {sortMissionByStatus, sortMissionCandidatByStatus} from "../../helper/missionHelper";
import {useSetState} from "react-use";
import {PROFIL_TYPE_RECRUTEUR} from "../../other/Constant";

import SwipeableTabs from 'react-swipeable-tabs';

const Missions = () => {

    //api and context
    const {user} = useOutletContext();

    if (user.profileType === PROFIL_TYPE_RECRUTEUR) {
        return(
            <div id={"missions_wrapper"}>
                <MissionsCurrent user={user} />
            </div>
        )
    } else {
        return (
            <div id={"missions_wrapper"}>
                <MissionsCandidat user={user} />
            </div>
        )
    }
}

const MissionsCurrent = ({user}) => {
    const {missions, linkings} = useLoaderData()
    //const [filterValue, setFilterValue] = useState("mission"); //filter
    const [filterValue, setFilterValue] = useState(0); //filter
    const [linkingsState, setLinkingsState] = useSetState() //match listings
    const [missionsState, setMissionsState] = useSetState() //mission listings
    const [showSlidingPanel, setShowSlidingPanel] = useState(false); //sliding panel
    const [currentMission, setCurrentMission] = useState(null)
    const [showSlidingPanelConfirmCreate, setShowSlidingPanelConfirmCreate] = useState(null)
    const navigate = useNavigate()

    /**
     * close panel and add new mission to listing
     * @param mission
     */
    const handleClosePanel = (mission = null, id = null) => {
        if(mission !== null){
            if (mission.published) {
                setMissionsState({
                    published : [...missionsState.published, mission]
                })
            } else {
                setMissionsState({
                    drafts : id !== null ? missionsState.drafts.map(obj => { return obj.id === id ? mission : obj }) : [...missionsState.drafts, mission]
                })
            }
            setShowSlidingPanelConfirmCreate(mission.published)
        }
        setShowSlidingPanel(false)
    }

    /**
     * Filter mission by state
     */
    useEffect(() => {
        missions?.then(missions => setMissionsState({
            published: missions.filter(obj => (obj.published === true && obj.closed === false)),
            dones: missions.filter(obj => (obj.published === true && obj.closed === true)),
            drafts : missions.filter(obj => (obj.published === false))
        }))
        linkings?.then(linkings => setLinkingsState(sortMissionByStatus(linkings)))
    }, [missions, linkings]);

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        if (queryParameters.get("create") !== null) {
            setCurrentMission(null)
            setShowSlidingPanel(true)
        }

    }, []);

    return(
        <>
            <div className={"content_header"}>
                <FilterButtonBar filters={[
                    {value: "missions", label: `Toutes`, count: missionsState.published?.length},
                    {value: "pending", label: `Mises en relation`, count: linkingsState.discussions?.length},
                    {value: "agreement", label: `Accords`, count: linkingsState.agreements?.length},
                    {value: "dones", label: `Terminées`, count: missionsState.dones?.length},
                    {value: "draft", label: `Brouillons`, count: missionsState.drafts?.length},
                ]} setFilterValue={setFilterValue}/>

                <div className={"text-center"}>
                    <button className={"cta full blue"} onClick={() => {
                        if (!user?.subscription) {
                            navigate('/abonnements')
                        } else {
                            setCurrentMission(null)
                            setShowSlidingPanel(true)
                        }
                    }}>
                        Publier une mission
                    </button>
                </div>
            </div>

            {filterValue === 0 &&
                <div id={"mission_current"} className={"filter_container"}>
                    <div className={"mission_block_wrapper"}>
                        <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                            <Await resolve={Promise.all([missions, linkings])}>
                                {missionsState.published?.length > 0 && missionsState.published?.map((mission, key) => {
                                    let maxLinkingStep = -1
                                    if(mission.linkings){
                                        mission.linkings.forEach((l) => {
                                            if (l.step > 0 && l.step > maxLinkingStep) {
                                                maxLinkingStep = l.step
                                            }
                                        })
                                    }
                                    // console.log(maxLinkingStep, 'maxLinkingStep')
                                    return (<MissionBlock {...mission} key={`mission_${mission.id}`} step={maxLinkingStep} />)})
                                }
                                {missionsState.published?.length === 0 && <GenericEmptyItem title={"Aucune mission pour le moment"}/>}
                            </Await>
                        </Suspense>
                    </div>
                </div>
            }

            {filterValue === 1 &&
                <div id={"mission_draft"} className={"filter_container"}>
                    <div className={"mission_block_wrapper"}>
                        <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                            <Await resolve={linkings}>
                                {linkingsState.discussions?.length > 0 && linkingsState.discussions?.map((item, key) => <MissionBlock {...item.mission} key={`mission_${item.id}`} step={3} />)}
                                {linkingsState.discussions?.length === 0 && <GenericEmptyItem title={"Aucune mise en relation pour le moment"}/>}
                            </Await>
                        </Suspense>
                    </div>
                </div>
            }

            {filterValue === 2 &&
                <div id={"mission_ended"} className={"filter_container"}>
                    <div className={"mission_block_wrapper"}>
                        <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                            <Await resolve={linkings}>
                                {linkingsState.agreements?.length > 0 && linkingsState.agreements?.map((item, key) => <MissionBlock {...item.mission} key={`mission_${item.id}`} step={5} />)}
                                {linkingsState.agreements?.length === 0 && <GenericEmptyItem title={"Aucun accord pour le moment"}/>}
                            </Await>
                        </Suspense>
                    </div>
                </div>
            }

            {filterValue === 3 &&
                <div id={"mission_ended"} className={"filter_container"}>
                    <div className={"mission_block_wrapper"}>
                        <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                            <Await resolve={missions}>
                                {missionsState.dones?.length > 0 && missionsState.dones?.map((item, key) => <MissionBlock {...item} key={`mission_${item.id}`} />)}
                                {missionsState.dones?.length === 0 && <GenericEmptyItem title={"Aucune mission terminée pour le moment"}/>}
                            </Await>
                        </Suspense>
                    </div>
                </div>
            }

            {filterValue === 4 &&
                <div id={"mission_draft"} className={"filter_container"}>
                    <div className={"mission_block_wrapper"}>
                        <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                            <Await resolve={missions}>
                                {missionsState.drafts?.length > 0 && missionsState.drafts?.filter(obj => (obj.published === false)).map((mission, key) => <MissionBlock {...mission} step={-1} key={`mission_${mission.id}`} onClick={() => {
                                    setCurrentMission(mission)
                                    setShowSlidingPanel(true)
                                }} />)}
                                {missionsState.drafts?.length === 0 && <GenericEmptyItem title={"Aucun brouillon pour le moment"}/>}
                            </Await>
                        </Suspense>
                    </div>
                </div>
            }

            <SlidingPanel show={showSlidingPanel} handleClose={() => setShowSlidingPanel(false)} forceSliding={true}>
                <AddMission user={user} handleClose={handleClosePanel} mission={currentMission}/>
            </SlidingPanel>

            <SlidingPanel show={showSlidingPanelConfirmCreate !== null} handleClose={() => {
                setShowSlidingPanelConfirmCreate(null)
                window.location.href = "/missions"
            }}>
                <div>
                    <div className={"bloc_title_info"}>
                        <h2>Super !</h2>
                        <p>{showSlidingPanelConfirmCreate ? "Votre mission est publiée" : "Votre mission est enregistrée dans vos brouillons"}</p>
                    </div>
                    <div className={"text-center mb2 mt3"}>
                        <button className={"cta full blue"} onClick={() => {
                            setShowSlidingPanelConfirmCreate(null)
                            window.location.href = "/missions"
                        }}>Fermer</button>
                    </div>
                </div>
            </SlidingPanel>
        </>
    )
}

const MissionsCandidat = ({user}) => {

    const {linkings} = useLoaderData()
    const [filterValue, setFilterValue] = useState(0); //filter
    const [missionsState, setMissionsState] = useSetState() //mission listings

    /**
     * Filter mission by state
     */
    useEffect(() => {
        linkings?.then(linkings => setMissionsState(sortMissionCandidatByStatus(linkings)))
    }, [linkings]);

    return (
        <>
            <FilterButtonBar filters={[
                { value : "interest", label : `Intérêts`, count : missionsState.interest?.length},
                { value : "discuss", label :`Mises en relation`, count : missionsState.discuss?.length},
                { value : "agreement", label : `Accords`, count : missionsState.agreement?.length},
                { value : "dones", label : `Terminées`, count : missionsState.dones?.length},
                { value : "declined", label : `Sans suite`, count : missionsState.declined?.length},
            ]} setFilterValue={setFilterValue} />

            {filterValue === 0 &&
                <div id={"mission_interest"} className={"filter_container"}>
                    <div className={"bloc_title_info"}>
                        <p>
                            Retrouvez ici les missions qui vous intéressent.
                            <br/>Puis retrouvez-les dans l'onglet "Mises en relation" après intérêt des deux parties.
                        </p>
                    </div>
                    <div className={"mission_block_wrapper"}>
                        <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                            <Await resolve={linkings}>
                                {missionsState.interest?.length > 0 && missionsState.interest.map( (match, key) => <MissionBlock {...match.mission} key={`mission_${match.mission.id}`} step={1} link={`/matchs/mission/${match.id}`}/>)}
                                {missionsState.interest?.length === 0 && <GenericEmptyItem title={"Retrouvez ici les missions qui vous intéresse. Puis retrouver-les dans l’onglet \"Mise en relation\" après des deux parties"}/>}
                            </Await>
                        </Suspense>
                    </div>
                </div>
            }

            {filterValue === 1 &&
                <div id={"mission_discuss"} className={"filter_container"}>
                    <div className={"mission_block_wrapper"}>
                        <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                            <Await resolve={linkings}>
                                {missionsState.discuss?.length > 0 && missionsState.discuss.map( (match, key) => <MissionBlock {...match.mission} key={`mission_${match.mission.id}`} step={3} link={`/matchs/mission/${match.id}`}/>)}
                                {missionsState.discuss?.length === 0 && <GenericEmptyItem title={"Retrouvez ici les mises en relation"}/>}
                            </Await>
                        </Suspense>
                    </div>
                </div>
            }

            {filterValue === 2 &&
                <div id={"mission_agrement"} className={"filter_container"}>
                    <div className={"mission_block_wrapper"}>
                        <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                            <Await resolve={linkings}>
                                {missionsState.agreement?.length > 0 && missionsState.agreement.map( (match, key) => <MissionBlock {...match.mission} key={`mission_${match.mission.id}`} step={5} link={`/matchs/mission/${match.id}`}/>)}
                                {missionsState.agreement?.length === 0 && <GenericEmptyItem title={"Retrouvez ici les missions en accord"}/>}
                            </Await>
                        </Suspense>
                    </div>
                </div>
            }

            {filterValue === 3 &&
                <div id={"mission_closed"} className={"filter_container"}>
                    <div className={"mission_block_wrapper"}>
                        <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                            <Await resolve={linkings}>
                                {missionsState.dones?.length > 0 && missionsState.dones.map( (match, key) => <MissionBlock {...match.mission} key={`mission_${match.mission.id}`} step={6} link={`/matchs/mission/${match.id}`}/>)}
                                {missionsState.dones?.length === 0 && <GenericEmptyItem title={"Retrouvez ici les missions terminées"}/>}
                            </Await>
                        </Suspense>
                    </div>
                </div>
            }
            {filterValue === 4 &&
                <div id={"mission_declined"} className={"filter_container"}>
                    <div className={"mission_block_wrapper"}>
                        <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                            <Await resolve={linkings}>
                                {missionsState.declined?.length > 0 && missionsState.declined.map( (match, key) => <MissionBlock {...match.mission} key={`mission_${match.mission.id}`} step={4} link={`/matchs/mission/${match.id}`}/>)}
                                {missionsState.declined?.length === 0 && <GenericEmptyItem title={"Retrouvez ici les missions sans suite"}/>}
                            </Await>
                        </Suspense>
                    </div>
                </div>
            }
        </>
    )
}

export default Missions