import React, {useEffect, useState} from "react";
import {useSetState} from "react-use";
import Api from "../helper/api";
import {Link, useOutletContext, useRevalidator} from "react-router-dom";
import {useForm} from "react-hook-form";
import AddressAutoComplete from "../form/addressAutoComplete";
import MultiSelect from "../form/multiSelect";
import FormSkill from "../form/formSkill";
import ProfilDataBlock from "../other/ProfilDataBlock";
import FormSpecialization from "../form/formSpecialization";
import {deleteItemFromArrayById, getIdFromApiObject} from "../helper/formUtils";
import {useForceUpdate} from "../helper/hook";
import FormActivity from "../form/formActivity";
import DatePicker from "react-datepicker";
import CurrencyInput from 'react-currency-input-field';
import moment from "moment";

const AddMission = (props) => {

    //handle form submit
    const {register,formState: { errors }, handleSubmit, reset, setValue, watch, unregister} = useForm();
    const {user} = useOutletContext();

    const [formData, setFormData] = useSetState({
        missionTypesList : [],
        missionCollaborationTypesList : [],
        experienceYearList : [],
        barList : [],
        languageList : [],
        durationList : [],
        experienceYear : [],
        countryList : [],
    })

    //handle multiselect change
    const [countries, setCountries] = useState([])

    //date picker
    const [datePicker, setDatePicker] = useState("");

    //mission Data state
    const [missionSkill, setMissionSkill] = useState([])
    const [missionSpecialization, setMissionSpecialization] = useState([])
    const [missionActivity, setMissionActivity] = useState([])

    //Mission Skill List
    const addMissionSkill = (skill) => {
        setMissionSkill([...missionSkill, skill])
    }
    const removeMissionSkill = (skillId) => {
        missionSkill.splice(skillId, 1)
        setMissionSkill(missionSkill)
    }
    const editMissionSkill = (skill, key) => {
        const editSkills = missionSkill.map((obj, k) => {
            if (k === key) {
                return skill;
            }
            return obj;
        });
        setMissionSkill(editSkills)
    }

    //Mission Specialization List
    const addMissionSpecialization = (specialization) => {
        setMissionSpecialization([...missionSpecialization, specialization])
    }
    const removeMissionSpecialization = (specializationId) => {
        missionSpecialization.splice(specializationId, 1)
        setMissionSpecialization(missionSpecialization)
    }
    const editMissionSpecialization = (specialization, key) => {
        const editSpecializations = missionSpecialization.map((obj, k) => {
            if (k === key) {
                return specialization;
            }
            return obj;
        });
        setMissionSpecialization(editSpecializations)
    }

    //Mission Activity List
    const addMissionActivity = (activity) => {
        setMissionActivity([...missionActivity, activity])
    }
    const removeMissionActivity = (activityId) => {
        missionActivity.splice(activityId, 1)
        setMissionActivity(missionActivity)
    }
    const editMissionActivity = (activity, key) => {
        const editActivities = missionActivity.map((obj, k) => {
            if (k === key) {
                return activity;
            }
            return obj;
        });
        setMissionActivity(editActivities)
    }

    //fetch data on mount
    useEffect(() => {
        const fetchData = async () => {
            const api = new Api();

            reset()
            setValue("title", "")
            setValue("description", "")
            setValue("type", "")
            setValue("collaborationType", "")
            setValue("estimatedDuration", "")
            setValue("estimatedBudget", "")
            setValue("experience", "")
            setValue("language", "")
            setValue("bar", "")
            setDatePicker("")
            setCountries([])
            setMissionSkill([])
            setMissionSpecialization([])
            setMissionActivity([])

            const MissionTypesList = await api.getMissionTypesList()
            const MissionCollaborationTypesList = await api.getMissionCollaborationTypesList()
            const MissionExperienceYearList = await api.getExperienceYearList()
            const BarList = await api.getBarList()
            const LanguageList = await api.getLanguageList()
            const durationList = await api.getDurationList()
            const experienceYear = await api.getExperienceYearList()
            const countryList = await api.getCountryList()

            setFormData({
                missionTypesList : MissionTypesList,
                missionCollaborationTypesList : MissionCollaborationTypesList,
                experienceYearList : MissionExperienceYearList,
                barList : BarList,
                languageList : LanguageList,
                durationList : durationList,
                experienceYear : experienceYear,
                countryList : countryList
            })

            if (props.mission) {
                console.log(props.mission, 'CURRENT MISSION')
                reset(props.mission)
                setValue("type", props.mission.type?.id)
                setValue("collaborationType", props.mission.collaborationType?.id)
                setValue("estimatedDuration", props.mission.estimatedDuration?.id)
                if (props.mission.estimatedBudget) {
                    setValue("estimatedBudget", props.mission.estimatedBudget + " €")
                }
                setValue("experience", props.mission.experience?.id)
                setValue("language", props.mission.language?.id)
                setValue("bar", props.mission.bar?.id)

                if (props.mission.limitDate) {
                    setDatePicker(moment(props.mission.limitDate).toDate())
                }
                if (props.mission.countries) {
                    setCountries(props.mission.countries)
                }
                if (props.mission.skills) {
                    setMissionSkill(props.mission.skills)
                }
                if (props.mission.specializations) {
                    setMissionSpecialization(props.mission.specializations)
                }
                if (props.mission.industries) {
                    setMissionActivity(props.mission.industries)
                }
            }
        }
        fetchData()
    }, [props.mission, reset])

    const handleCancel = (mission = null) => {
        reset()
        setCountries([])
        setDatePicker('')
        props.handleClose(mission, props.mission?.id)
    }

    //Handle click event on create draft cta
    const handleSubmitDraft = () => {
        setValue("published", "0")
        handleSubmit(handleFormSubmit)()
    }

    //Handle click event on create draft cta
    const handleSubmitPublished = () => {
        setValue("published", "1")
        handleSubmit(handleFormSubmit)()
    }

    //HandleFormSubmit
    const handleFormSubmit = async (data) => {
        const api = new Api();

        //create params list
        let params = {
            published : data.published === "1",
            title : data.title,
            description : data.description,
            estimatedBudget : data.estimatedBudget,
            collaborationType: data.collaborationType ? data.collaborationType : null,
            type: data.type ? data.type : null,
            estimatedDuration: data.estimatedDuration ? data.estimatedDuration : null,
            bar : data.bar ? data.bar : null,
            company: false,
            experience: data.experience ? data.experience : null,
            language: data.language ? data.language : null,
            latitude: data.location?.latitude,
            longitude: data.location?.longitude,
            location: data.location?.address,
            urgent: data.urgent
            /*skills: [],
            specializations: [],
            industries: []*/
        }
        if (countries) {
            let c = []
            for (var i = 0; i < countries.length; i++) {
                c.push('api/countries/' + countries[i].id)
            }
            params.countries = c
        }

        console.log(params.estimatedBudget, 'COUNTRIES')
        if (params.estimatedBudget) {
            // params.estimatedBudget = params.estimatedBudget.replace(/[^0-9.-]+/g, "")
        }
        params.estimatedBudget = parseFloat(params.estimatedBudget)

        // if (data.id) {
        //     params.id = data.id
        // }
        if (data.limitDate) {
            params.limitDate = data.limitDate
        }
        if (data.from) {
            params.from = data.from
        }
        if (data.to) {
            params.to = data.to
        }

        let skills = []
        let industries = []
        let specializations = []

        //add skills list
        missionSkill.forEach((item, key) => {
            skills.push(
                {
                    description: item.description,
                    area : item.area.id,
                    experience: item.experience.id,
                    countries : item.countries,
                    subareas : item.subareas,
                    order: key
                }
            )
        })

        //add specialization
        missionSpecialization.forEach((item, key) => {
            specializations.push(
                {
                    specialization: item.specialization.id,
                    description: item.description,
                    experience: item.experience.id,
                    order: key,
                    countries : []
                }
            )
        })

        //add industry
        missionActivity.forEach((item, key) => {
            industries.push(
                {
                    industry: item.industry.id,
                    experience: item.experience.id,
                    countries : item.countries,
                }
            )
        })

        if (props.mission && props.mission.id) {
            console.log(params, 'PATCH MISSION')
            console.log(skills, 'PATCH skills')
            console.log(specializations, 'PATCH specializations')
            api.patchMission(props.mission.id, params, industries, skills, specializations).then((mission) => {
                console.log("UPDATE OK")
                handleCancel(mission)
            })
                .catch( error => {
                    //TODO display error
                    console.log(error, "UPDATE ERROR")
                })
        } else {
            console.log(params, 'CREATE MISSION')
            api.createMission(params, industries, skills, specializations).then((mission) => handleCancel(mission))
                .catch( error => {
                    //TODO display error
                })
        }
    }

    useEffect(() => {

        const colType = parseInt(watch("collaborationType"))

        if(colType === 1 || colType === 3){
            // setValue("location", props.mission.location)
        }else{
            unregister("location")
        }
    }, [watch("collaborationType")])

    return (
        <div id={"addmission_wrapper"}>
            <div className={"bloc_title_info"}>
                <h2>{props.mission?.id ? "Continuer votre création de mission" : "Vous êtes sur le point de publier une mission"}</h2>
                <p>En quelques secondes, publiez votre mission. Décrivez votre besoin en détails pour trouver votre l'étoile avec les expertises les plus compatibles.</p>
            </div>

            <div id={"addmission_form"} className={"profil_block"}>
                <form onSubmit={() => {}}>
                    <input type={"hidden"} name={"published"} value={"0"} {...register("published")}/>
                    <div className={"side-left"}>
                    <div className={"form_row_wrapper type3"}>
                        <label>Type</label>
                        <select name={"type"} id={"type"} defaultValue={""} {...register("type", {
                            required: "Veuillez renseigner cette information"
                        })}>
                            <option value="" disabled={true}>Type</option>
                            {formData.missionTypesList.map((c, index) => {
                                return (<option value={c.id} key={index}>{c.name}</option>)
                            })}
                        </select>
                        <div className="error">{errors.type && errors.type.message}</div>
                    </div>
                    <div className={"form_row_wrapper type3"}>
                        <label>Titre</label>
                        <input type={"text"} name={"title"} id={"title"} placeholder={"Type here"} {...register('title', {
                            required: "Veuillez renseigner cette information"
                        })}/>
                        <div className={"error"}>{errors.title && errors.title.message}</div>
                    </div>
                    <div className={"form_row_wrapper type3"}>
                        <label>Description</label>
                        <textarea {...register('description', {
                            required: "Veuillez renseigner cette information"
                        })}></textarea>
                        <div className={"error"}>{errors.description && errors.description.message}</div>
                    </div>
                    <div className={"form_row_wrapper type3"}>
                        <label>Budget prévisionnel</label>
                        <CurrencyInput
                            id={"estimatedBudget"}
                            name={"estimatedBudget"}
                            placeholder={"Budget prévisionnel"}
                            allowDecimals={false}
                            // defaultValue={estimatedBudget}
                            onValueChange={(value, name, values) => {
                                setValue("estimatedBudget", value + " €")
                            }}
                            suffix={" €"}
                            intlConfig={{ locale: 'fr-FR', currency: 'EUR' }}
                            allowNegativeValue={false}
                            {...register("estimatedBudget")}
                        />
                        {/*<div className={"error"}>{errors.estimatedBudget && errors.estimatedBudget.message}</div>*/}
                    </div>
                    <div className={"form_row_wrapper type3"}>
                        <label>Durée estimée</label>
                        <select name={"estimatedDuration"} id={"estimatedDuration"} defaultValue={""} {...register("estimatedDuration", {
                            // required: "Veuillez renseigner cette information"
                        })}>
                            <option value="" disabled={true}>Durée estimée</option>
                            {formData.durationList.map((c, index) => {
                                return (<option value={c.id} key={index}>{c.name}</option>)
                            })}
                        </select>
                        <div className="error">{errors.estimatedDuration && errors.estimatedDuration.message}</div>
                    </div>
                    <div className={"form_row_wrapper type3"}>
                        <label>Mode de collaboration</label>
                        <select name={"collaborationType"} id={"collaborationType"} defaultValue={""} {...register("collaborationType", {
                            // required: "Veuillez renseigner cette information"
                        })}>
                            <option value="" disabled={true}>Mode de collaboration</option>
                            {formData.missionCollaborationTypesList.map((c, index) => {
                                return (<option value={c.id} key={index}>{c.name}</option>)
                            })}
                        </select>
                        <div className="error">{errors.collaborationType && errors.collaborationType.message}</div>
                    </div>
                    {watch("type") !== "3" && watch("type") !== "2" && watch("collaborationType") !== "" && (watch("collaborationType") === "1" || watch("collaborationType") === "3") &&
                        <div className={"form_row_wrapper type3"}>
                            <label>Lieu</label>
                            <AddressAutoComplete name={"location"} id={"location"} register={register} setValue={(value) => setValue("location", value)}/>
                        </div>
                    }
                    {user.job !== 1 && (watch("type") === "1" || watch("type") === "2") &&
                        <div className={"form_row_wrapper type3"}>
                            <label>Barreau</label>
                            <select name={"bar"} id={"bar"} defaultValue={""} {...register("bar", {
                                // required: "Veuillez renseigner cette information"
                            })}>
                                <option value="" disabled={true}>Barreau</option>
                                {formData.barList.map((c, index) => {
                                    return (<option value={c.id} key={index}>{c.name}</option>)
                                })}
                            </select>
                            <div className="error">{errors.bar && errors.bar.message}</div>
                        </div>
                    }
                    <div className={"form_row_wrapper type3"}>

                        <label>Date limite de réponse</label>
                        <input type={"hidden"} {...register("limitDate")} name={"limitDate"} id={"limitDate"}/>

                        <DatePicker
                            showYearDropdown
                            yearDropdownItemNumber={30}
                            placeholderText="Date limite de réponse"
                            dateFormat="dd/MM/yyyy"
                            minDate={new Date()}
                            selected={datePicker}
                            onChange={(date) => {
                                setDatePicker(date)
                                setValue("limitDate", date.toISOString())
                            }}
                        />
                    </div>
                    <div className={"form_row_wrapper type3"}>
                        <label>Expérience</label>
                        <select name={"experience"} id={"experience"} defaultValue={""} {...register("experience", {
                        })}>
                            <option value="" disabled={true}>Expérience</option>
                            {formData.experienceYear.map((c, index) => {
                                return (<option value={c.id} key={index}>{c.name}</option>)
                            })}
                        </select>
                        <div className="error">{errors.experience && errors.experience.message}</div>
                    </div>
                    <div className={"form_row_wrapper type3"}>
                        <label>Langue</label>
                        <select name={"language"} id={"language"} defaultValue={""} {...register("language", {
                        })}>
                            <option value="" disabled={true}>Langue</option>
                            {formData.languageList.map((c, index) => {
                                return (<option value={c.id} key={index}>{c.name}</option>)
                            })}
                        </select>
                        <div className="error">{errors.language && errors.language.message}</div>
                    </div>
                    <div className={"form_row_wrapper type3 multiselect"}>
                        <label>Expertise international</label>
                        <MultiSelect name={"expertise"} id={"expertise"} placeholder="Sélectionner" options={formData.countryList.map(item => ({label: item.name, value: item.id}))} isMulti={true} closeMenuOnSelect={false} defaultValues={countries}
                                     getValues={setCountries}/>
                    </div>

                    <div className={"form_row_title"}>
                        <h1>Compétences</h1>
                        <p>Quelles sont les compétences idéales recherchées pour cette mission ?</p>
                    </div>

                    <SkillsBlock user={props.user} items={missionSkill} addItem={addMissionSkill} removeItem={removeMissionSkill} editItem={editMissionSkill}/>

                    <div className={"form_row_title"}>
                        <h1>Spécialisations</h1>
                        <p>Une spécialisation est-elle la bienvenue ?</p>
                    </div>

                    <SpecializationBlock items={missionSpecialization} addItem={addMissionSpecialization} removeItem={removeMissionSpecialization} editItem={editMissionSpecialization}/>

                    <div className={"form_row_title"}>
                        <h1>Secteurs d'activité</h1>
                        <p>Et un secteur en particulier ?</p>
                    </div>

                    <ActivityBlock user={props.user} items={missionActivity} addItem={addMissionActivity} removeItem={removeMissionActivity} editItem={editMissionActivity}/>

                    {/*<div className={"publication_type_wrapper form_row_title"}>
                        
                        <h4>Publier cette mission en tant que :</h4>

                        <div className={"form_row_wrapper type1"}>
                            <div className={"checkbox_wrapper checkbox_small_cta"}>
                                <input type={"radio"} name={"type"} id={"type_independant"} value={"independant"}/>
                                <label htmlFor={"type_independant"}>Indépendant</label>
                            </div>
                        </div>
                        <div className={"form_row_wrapper type1"}>
                            <div className={"checkbox_wrapper checkbox_small_cta"}>
                                <input type={"radio"} name={"type"} id={"type_cabinet"} value={"cabinet"}/>
                                <label htmlFor={"type_cabinet"}>Cabinet</label>
                            </div>
                        </div>

                    </div>*/}
                    <div className={"form_row_wrapper checkbox_wrapper checkbox"}>
                        <input type={"checkbox"} name={"urgent"} id={"urgent"} {...register("urgent")}/>
                        <label htmlFor={"urgent"}>Mission urgente</label>
                    </div>
                    </div>
                    <div className={"side-right"}>
                        <div className={"side-right-fixed"}>
                            {/*<div className={"mission-menu"}>*/}
                            {/*    <Link to={"#addmission_form"} className={""}>Informations</Link>*/}
                            {/*    <Link to={"#addmission_form"} className={""}>Compétences</Link>*/}
                            {/*    <Link to={"#addmission_form"} className={""}>Spécializa</Link>*/}
                            {/*</div>*/}
                            <div className={"cta_wrapper"}>
                                <a className={"cta full blue"} onClick={handleSubmitPublished}>Publier</a>
                                <a className={"cta full blue reverse"} onClick={handleSubmitDraft}>Enregistrer en brouillon</a>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
)
}

const SkillsBlock = ({user, items, addItem, removeItem, editItem}) => {

    //show / hide add form
    const [showAddForm, setShowAddForm] = useState(false)

    //hook to force component refresh on delete
    const handleForceUpdate = useForceUpdate()

    //if in edit mode set skill object to edit
    const [editItemId, setEditItemId] = useState(null)
    const [Item, setItem] = useState(null)

    //handle cancel form
    const handleCancel = () => {
        setEditItemId(null)
        setItem(null)
        setShowAddForm(false)
    }

    //handle edit action
    const handleEdit = (item, itemId) => {
        setEditItemId(itemId)
        setItem(item)
        setShowAddForm(true)
    }

    //handle remove action
    const handleRemove = (itemId) => {
        removeItem(itemId)
        handleForceUpdate()
    }

    //save skill
    const handleFormSubmit = async (data) => {
        if (editItemId === null) {
            //new item
            addItem(data)
        } else {
            //edit item
            editItem(data, editItemId)
        }
        handleCancel()
    }

    return(
        <div id={"skills_details"} className={"profil_block"}>

            <div className={"skills_wrapper"}>

                {items && items.map((item, i) => {
                    return(<ProfilDataBlock key={i} title={item.area.name} experience={item.experience.name} international={item.countries} description={item.description} subareas={item.subareas} editCallBack={() => handleEdit(item, i)} deleteCallBack={() => handleRemove(i)}/>)
                })}

                {!showAddForm &&
                    <div className={"text-right"}>
                        <button className={"cta full blue reverse"} onClick={()=> setShowAddForm(true)}>+ Ajouter une compétence</button>
                    </div>
                }
            </div>

            {showAddForm &&
                <div id={"addskill_wrapper"}>

                    <div id={"addskill_form"} className={"profil_block"}>
                        <FormSkill onFormSubmit={handleFormSubmit} onFormCancel={handleCancel} skill={Item}/>
                    </div>

                </div>
            }

        </div>
    )
}

const SpecializationBlock = ({items, addItem, removeItem, editItem}) => {

    //show / hide add form
    const [showAddForm, setShowAddForm] = useState(false)

    //hook to force component refresh on delete
    const handleForceUpdate = useForceUpdate()

    //if in edit mode set skill object to edit
    const [editItemId, setEditItemId] = useState(null)
    const [Item, setItem] = useState(null)

    //handle cancel form
    const handleCancel = () => {
        setEditItemId(null)
        setItem(null)
        setShowAddForm(false)
    }

    //handle edit action
    const handleEdit = (item, itemId) => {
        setEditItemId(itemId)
        setItem(item)
        setShowAddForm(true)
    }

    //handle remove action
    const handleRemove = (itemId) => {
        removeItem(itemId)
        handleForceUpdate()
    }

    //save skill
    const handleFormSubmit = async (data) => {

        if(editItemId === null){
            //new item
            addItem(data)
        }else{
            //edit item
            editItem(data,editItemId)
        }

        handleCancel()
    }

    return(
        <div id={"specialisation_details"} className={"profil_block"}>

            <div className={"skills_wrapper"}>

                {items && items.map((item, i) => {
                    return(<ProfilDataBlock title={item.specialization.name} experience={item.experience.name} description={item.description} editCallBack={() => handleEdit(item, i)} deleteCallBack={() => handleRemove(i)}/>)
                })}

                {!showAddForm &&
                    <div className={"text-right"}>
                        <button className={"cta full blue reverse"} onClick={() => setShowAddForm(true)}>+ Ajouter une spécialisation</button>
                    </div>
                }

            </div>

            {showAddForm &&
                <div id={"addspecialization_wrapper"}>
                    <div id={"addspecialization_form"} className={"profil_block"}>
                        <FormSpecialization onFormSubmit={handleFormSubmit} onFormCancel={handleCancel} specialization={Item}/>
                    </div>
                </div>
            }

        </div>
    );
}

const ActivityBlock = ({user, items, addItem, removeItem, editItem}) => {

    //show / hide add form
    const [showAddForm, setShowAddForm] = useState(false)

    //hook to force component refresh on delete
    const handleForceUpdate = useForceUpdate()

    //if in edit mode set skill object to edit
    const [editItemId, setEditItemId] = useState(null)
    const [Item, setItem] = useState(null)

    //handle cancel form
    const handleCancel = () => {
        setEditItemId(null)
        setItem(null)
        setShowAddForm(false)
    }

    //handle edit action
    const handleEdit = (item, itemId) => {
        setEditItemId(itemId)
        setItem(item)
        setShowAddForm(true)
    }

    //handle remove action
    const handleRemove = (itemId) => {
        removeItem(itemId)
        handleForceUpdate()
    }

    //save skill
    const handleFormSubmit = async (data) => {

        if(editItemId === null){
            //new item
            addItem(data)
        }else{
            //edit item
            editItem(data,editItemId)
        }

        handleCancel()
    }

    return(
        <div id={"activity_details"} className={"profil_block"}>

            <div className={"skills_wrapper"}>

                {items && items.map((item, i) => {
                    return(<ProfilDataBlock title={item.industry.name} experience={item.experience.name} description={item.description} editCallBack={() => handleEdit(item, i)} deleteCallBack={() => handleRemove(i)}/>)
                })}

                {!showAddForm &&
                    <div className={"text-right"}>
                        <button className={"cta full blue reverse"} onClick={() => setShowAddForm(true)}>+ Ajouter un secteur</button>
                    </div>
                }

            </div>

            {showAddForm &&
                <div id={"addactivity_wrapper"}>
                    <div id={"addactivity_form"} className={"profil_block"}>
                        <FormActivity onFormSubmit={handleFormSubmit} onFormCancel={handleCancel} activity={Item}/>
                    </div>

                </div>
            }

        </div>
    );
}

export default AddMission