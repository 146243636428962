import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {emailPattern} from "../helper/formUtils";
import Api from "../helper/api";
import {CtaReturn} from "../other/Cta";
import PasswordInput from "../form/passwordInput";
import {isAndroid, isIOS, isMobile} from "react-device-detect";

const ResetPassword = () => {

    const {register,formState: { errors }, handleSubmit} = useForm();
    const [serverError, setServerError] = useState("");
    const api = new Api();
    let { token } = useParams()
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [link, setLink] = useState("/connexion")

    const handleResetPassword = (data) => {

        setServerError("");

        api.userResetPassword(token, data.password).then(res => {
            setShowConfirmation(true)
        }).catch(error => {
            setServerError(error.message)
            console.log(error)
        })
    }
    useEffect(() => {
        // if (isMobile) {
        //     if (isAndroid) {
        //         // setLink(":esterlaw")
        //     } else if (isIOS) {
        //         // setLink(":esterlaw")
        //     }
        // }
        console.log('AAAAAA')
    }, []);


    return(
        <div className={"connection_wrapper"}>
            <div className={"connection_box"}>
                {!showConfirmation && <>
                    <h1>Récupération de mot de passe</h1>
                    <form id={"connection_form"} onSubmit={handleSubmit(handleResetPassword)}>
                        <div className={"form_row_wrapper type3"}>
                            <label htmlFor={"password"}>Nouveau mot de passe</label>
                            <PasswordInput name={"password"} id={"password"} placeholder={"Nouveau mot de passe"} register={register}/>
                            <div className={"error"}>{errors.password && errors.password.message}</div>
                        </div>

                        <div className={"form_row_wrapper type3"}>
                            <label htmlFor={"confirmationPassword"}>Confirmation de mot de passe</label>
                            <PasswordInput name={"confirmationPassword"} id={"confirmationPassword"} placeholder={"Confirmation de mot de passe"} register={register}/>
                            <div className={"error"}>{errors.confirmationPassword && errors.confirmationPassword.message}</div>
                        </div>

                        <div className={"error text-center"}>{serverError}</div>

                        <div className={"form_row_wrapper type1"}>
                            <input type={"submit"} value={"Valider"}/>
                        </div>
                    </form>
                </>}
                {showConfirmation && <>
                    <h1>Mot de passe modifié !</h1>
                    <p>Vous pouvez vous connecter avec votre nouveau mot de passe dès à présent</p>
                    <div className={"form_row_wrapper type1"}>
                        <Link to={link} className={"cta blue full"}>Se connecter</Link>
                    </div>
                </>}
            </div>
        </div>
    )
}

export default ResetPassword