import {
    STEP_AGREEMENT,
    STEP_DONE,
    STEP_PENDING,
    STEP_RECIPIENT_ACCEPTED, STEP_RECIPIENT_REFUSED,
    STEP_SENDER_ACCEPTED,
    STEP_SENDER_REFUSED
} from "../other/Constant";

/**
 *
 * @param missions
 * @returns {{draft: *[], closed: *[], published: *[]}}
 */
export const sortMissionByStatus = (missions) => {

    let missionDiscussions = []
    let missionAgreements = []
    let missionDones = []

    const sortedData = missions.sort((a, b) => {
        return b.step - a.step
    })

    sortedData.map(linking => {
        if (linking.mission.closed === false) {
            if (linking.step === 6 && missionDones.filter(obj => obj.mission.id === linking.mission.id).length === 0) {
                missionDones.push(linking)
            } else if (linking.step === 5 && missionDones.filter(obj => obj.mission.id === linking.mission.id).length === 0 && missionAgreements.filter(obj => obj.mission.id === linking.mission.id).length === 0) {
                missionAgreements.push(linking)
            } else if (linking.step === 3 && missionDones.filter(obj => obj.mission.id === linking.mission.id).length === 0 && missionAgreements.filter(obj => obj.mission.id === linking.mission.id).length === 0 && missionDiscussions.filter(obj => obj.mission.id === linking.mission.id).length === 0) {
                missionDiscussions.push(linking)
            }
        }
    })
    return {
        discussions : missionDiscussions,
        agreements : missionAgreements,
        dones : missionDones,
        all: [...missionDiscussions, ...missionAgreements, ...missionDones],
        linkings: sortedData
    }
}

export const sortMissionCandidatByStatus = (linkings) => {

    let missions_interest = [];
    let missions_discuss = [];
    let missions_agreement = [];
    let missions_dones = [];
    let missions_declined = [];

    linkings.forEach(l => {

        // console.log("match details", l)

        if (l.mission?.closed === true) {
            missions_dones.push(l)
        } else {
            switch (l.step){
                case STEP_RECIPIENT_ACCEPTED :missions_interest.push(l); break;
                case STEP_RECIPIENT_REFUSED : missions_declined.push(l); break;
                case STEP_SENDER_ACCEPTED : missions_discuss.push(l); break;
                case STEP_SENDER_REFUSED : missions_declined.push(l); break;
                case STEP_AGREEMENT : missions_agreement.push(l); break;
                case STEP_DONE : missions_dones.push(l); break;
            }
        }
    })

    return {
        interest : missions_interest,
        dones : missions_dones,
        discuss : missions_discuss,
        agreement : missions_agreement,
        declined : missions_declined
    }
}


export const filterDuplicateHelpInLinkings = (myHelps, helpLinkings = false) => {
    let allHelps = []

    if (myHelps !== undefined) {
        myHelps?.map(help => {
            if (help.linkings?.length) {
                const sortedHelpLinkings = help.linkings?.filter(obj => obj.step === 1 || obj.step === 3 || obj.step === 5).sort((a, b) => {
                    return b.step - a.step
                })
                sortedHelpLinkings.map(linking => {
                    if (allHelps.filter(obj => obj.help.id === help.id).length === 0) {
                        allHelps.push({help: help, linking: linking})
                    }
                })
            } else {
                allHelps.push({help: help, linking: {step: 0}})
            }
        })
    }
    const sortedHelpLinkings = helpLinkings && helpLinkings.length > 0 ? helpLinkings?.filter(obj => obj.step === 1 || obj.step === 3 || obj.step === 5).sort((a, b) => {
        return b.step - a.step
    }) : []
    sortedHelpLinkings?.map(linking => {
        if (allHelps.filter(obj => obj.help.id === linking.help.id).length === 0) {
            allHelps.push({ help: linking.help, linking: linking})
        }
    })
    return allHelps.sort((a, b) => {
        return b.linking.step - a.linking.step
    })
}

export const filterDuplicateSwitchDutyInLinkings = (mySwitchDuties, switchDutyLinkings = false) => {
    let allHelps = []

    if (mySwitchDuties !== undefined) {
        mySwitchDuties?.map(switchDuty => {
            if (switchDuty.linkings?.length) {
                const sortedHelpLinkings = switchDuty.linkings?.filter(obj => obj.step === 1 || obj.step === 3 || obj.step === 5).sort((a, b) => {
                    return b.step - a.step
                })
                sortedHelpLinkings.map(linking => {
                    if (allHelps.filter(obj => obj.switchDuty.id === switchDuty.id).length === 0) {
                        allHelps.push({switchDuty: switchDuty, linking: linking})
                    }
                })
            } else {
                allHelps.push({switchDuty: switchDuty, linking: {step: 0}})
            }
        })
    }
    const sortedHelpLinkings = switchDutyLinkings && switchDutyLinkings.length > 0 ? switchDutyLinkings?.filter(obj => obj.step === 1 || obj.step === 3 || obj.step === 5).sort((a, b) => {
        return b.step - a.step
    }) : []
    sortedHelpLinkings?.map(linking => {
        if (allHelps.filter(obj => obj.switchDuty.id === linking.switchDuty.id).length === 0) {
            allHelps.push({ switchDuty: linking.switchDuty, linking: linking})
        }
    })
    return allHelps.sort((a, b) => {
        return b.linking.step - a.linking.step
    })
}