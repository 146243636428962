import React, {useEffect, useState, Suspense} from "react";
import {Await, useLoaderData, useNavigate, useOutletContext} from "react-router-dom";

import SlidingPanel from "../_sliding_panel";
import {GenericEmptyItem} from "../../other/GenericBlock";
import FilterButtonBar from "../../other/FilterButtonBar";
import {useSetState} from "react-use";
import {sortMatchsByType} from "../../helper/missionHelper";
import MissionBlock from "../mission/_MissionBlock";
import {
    PROFIL_TYPE_RECRUTEUR,
    STEP_AGREEMENT, STEP_DONE,
    STEP_PENDING,
    STEP_RECIPIENT_ACCEPTED, STEP_RECIPIENT_REFUSED,
    STEP_SENDER_ACCEPTED, STEP_SENDER_REFUSED
} from "../../other/Constant";
import ItemLabel from "../../other/ItemLabel";
import HelpBlock from "../help/_HelpBlock";
import SwitchBlock from "../switch/_SwitchBlock";
// import { format } from "date-fns";

const MatchBlock = (props) => {

    const navigate = useNavigate()

    return (
        <div className={`mission_block_item`} onClick={() => navigate(props.link)}>
            {props.type.code && <ItemLabel code="TE" type={props.code} />}
            <div className={"content"}>
                <div className={"title"}>{props.title}</div>
                <div className={"description"}>
                    <span className={"reference"}>{props.reference}</span>
                    {props.step > 0 && <span className={"state"}>En attente</span>}
                </div>
            </div>
        </div>
    )
}

const SwitchMatchBlock = (props) => {

    const navigate = useNavigate();
    const [state, setState] = useSetState({
        completion : "",
        status : "..."
    })

    useEffect(() => {

        let maxLinkingStep = -1
        if(props.switchDuty.linkings){
            props.switchDuty.linkings.forEach((l) => {
                if (l.step > 0 && l.step > maxLinkingStep) {
                    maxLinkingStep = l.step
                }
            })
        }
        switch(maxLinkingStep){
            case STEP_RECIPIENT_ACCEPTED :
                setState({completion : "25%", status : "Matching en attente"})
                break;
            case STEP_RECIPIENT_REFUSED :
                setState({completion : "0%", status : "Pas intéressé"})
                break;
            case STEP_SENDER_ACCEPTED :
                setState({completion : "50%", status : "Mise en relation"})
                break;
            case STEP_SENDER_REFUSED :
                setState({completion : "25%", status : "Recherche mission terminée"})
                break;
            case STEP_AGREEMENT :
                setState({completion : "75%", status : "Accord"})
                break;
            case STEP_DONE :
                setState({completion : "100%", status : "Terminée"})
                break;
            default :
                setState({completion : "", status : ""})
        }
    },[])


    return (
        <div className={`mission_block_item`} onClick={() => navigate(props.link)}>
            {props.switchDuty.type.code && <ItemLabel code={props.switchDuty.type.code} type={props.switchDuty.type.name}/>}
            <div className={"content"}>
                <div className={"title"}>{props.title}</div>
                <div className={"detail d-mobile-none"}>{props.switchDuty.description}</div>
                {props.message && <div className={"description"}>
                    {props.message}
                    {/*<span className={"reference"}>{props.reference}</span>*/}
                    {/*{state.status !== "" && <span className={"state"}>{state.status}</span>}*/}
                </div>}
                {/*<div className={"progression"}>*/}
                {/*    {state.completion}*/}
                {/*</div>*/}
            </div>
        </div>
    )
}

const Matchs = () => {

    const [filterValue, setFilterValue] = useState(0);
    const {missions, helps, switchs} = useLoaderData()

    const [matchsState, setMatchsState] = useSetState()

    const {user} = useOutletContext()

    useEffect(() => {
        Promise.all([missions, helps, switchs]).then((values) => {
            const [m, h, s] = values

            console.log(h, "TEST")

            setMatchsState({
                missions : m?.length,
                helps : h?.length,
                switchs : s?.length
            })

        })

    },[missions, helps, switchs])

    return(
        <div id={"matchs_wrapper"}>

            <div className={"matchs_block_wrapper blue"}>

                <div className={"bloc_title_info"}>
                    <h2>Consultez vos matchs</h2>
                    <p>Et manifestez votre intérêt pour une mission, apportez de l'aide ou échangez des permanences.</p>
                </div>

                <FilterButtonBar filters={[
                    { value : "mission", label : `Missions`, count : matchsState.missions},
                    { value : "help", label :`Aides`, count : matchsState.helps},
                    { value : "switch", label : `Échanges de permanence`, count : matchsState.switchs},
                ]} setFilterValue={setFilterValue}/>

            </div>
            {filterValue === 0 &&
                <div className={"mission_block_wrapper"}>
                    <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                        <Await resolve={missions}>
                            {(missions) => {
                                if (missions.length === 0) {
                                    return(<GenericEmptyItem title={"Aucun match pour le moment"}/>)
                                }
                                const sortedMissions = missions.sort((a, b) => {
                                    return a.step - b.step
                                })
                                if (user.profileType === PROFIL_TYPE_RECRUTEUR) {
                                    return(sortedMissions.map((match, key) => <MissionBlock key={"match_mission_item_"+key}  {...match.mission} step={match.step} link={`/missions/${match.mission.id}/matchs`}/>))
                                } else {
                                    return(sortedMissions.map((match, key) => <MissionBlock key={"match_mission_item_"+key} {...match.mission} step={match.step} link={`/matchs/mission/${match.id}`}/>))
                                }
                            }}
                        </Await>
                    </Suspense>
                </div>
            }

            {filterValue === 1 &&
                <div className={"mission_block_wrapper"}>
                    <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                        <Await resolve={helps}>
                            {(helps) => {
                                // let helps = values[0]
                                // if (values.length > 1) {
                                //     helps = [...values[0], ...values[1]]
                                // }
                                if (helps.length === 0) {
                                    return(<GenericEmptyItem title={"Consultez les aides ici"}/>)
                                } else {
                                    const sortedHelps = helps.sort((a, b) => {
                                        return a.step - b.step
                                    })

                                    return (sortedHelps.map((match, key) => {
                                        return <HelpBlock {...match.help} link={`/matchs/aide/${match.id}`} step={match.step} linkings={[match]} owner={match.help?.user?.id === user.id} />
                                        // return <HelpBlock {...match.help} link={match.help?.user?.id === user.id ? `/aides/${match.help.id}` : `/matchs/aide/${match.id}`} step={match.step} linkings={[match]} owner={match.help?.user?.id === user.id} />
                                    }))
                                    // return (helps.map((match, key) => <MatchBlock title={match.help.title} {...match.help} link={`/matchs/aide/${match.id}`}/>))
                                }
                            }}
                        </Await>
                    </Suspense>
                </div>
            }

            {filterValue === 2 &&
                <div className={"mission_block_wrapper"}>
                    <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                        <Await resolve={switchs}>
                            {(switchs) => {
                                // let switchs = values[0]
                                // if (values.length > 1) {
                                //     switchs = [...values[0], ...values[1]]
                                // }
                                if (switchs.length === 0) {
                                    return(<GenericEmptyItem title={"Consultez les échanges ici"}/>)
                                } else {
                                    const sortedSwitchs = switchs.sort((a, b) => {
                                        return a.step - b.step
                                    })

                                    return(sortedSwitchs.map((match, key) => {
                                        return <SwitchBlock {...match.switchDuty} link={`/matchs/echange/${match.id}`} step={match.step} linkings={[match]} owner={match.switchDuty?.user?.id === user.id} />
                                        // return <SwitchBlock {...match.switchDuty} link={match.switchDuty?.user?.id === user.id ? `/echanges/${match.switchDuty.id}` : `/matchs/echange/${match.id}`} step={match.step} linkings={[match]} owner={match.switchDuty?.user?.id === user.id} />
                                    }))
                                    // return(switchs.map((match, key) => <SwitchMatchBlock title={match.switchDuty.from ? 'Échange' : 'Échange'} {...match} link={`/matchs/echange/${match.id}`}/>))
                                    // return(switchs.map((match, key) => <MatchBlock title={match.switchDuty.from ? format(new Date(match.switchDuty.from), 'dd/MM/yyyy') : 'Échange'} {...match.switchDuty} link={`/matchs/echange/${match.id}`}/>))
                                }
                            }}
                        </Await>
                    </Suspense>
                </div>
            }
        </div>
    )
}

export default Matchs