import {
    STEP_AGREEMENT, STEP_DONE,
    STEP_PENDING,
    STEP_RECIPIENT_ACCEPTED,
    STEP_RECIPIENT_REFUSED,
    STEP_SENDER_ACCEPTED,
    STEP_SENDER_REFUSED
} from "../other/Constant";
import moment from "moment";

export const GetHelpDate = (date) => {

    const options = { weekday:"long", year:"numeric", month:"long", day:"numeric"}
    const d = new Date(date)


    return d.toLocaleDateString('fr-FR', options)
}

export const GetHelpTime = (date) => {

    const h = moment(date).format('HH')
    const m = moment(date).format('MM')

    if (h > 0) {
        return `${h}h${m}`
    }
    return null;
}

export const GetFullDateTime = (date) => {

    const d = GetHelpDate(date)
    const t = GetHelpTime(date)

    if (t) {
        return `${d} - ${t}`
    }
    return d;
}

export const GetIntervalTime = (from, to) => {

    const f = GetHelpTime(from)
    const t = GetHelpTime(to)
    if (f && t) {
        return `${f} jusqu'à ${t}`
    }
    return f;
}

export const getIcon = (id) => {
    if (id % 4 === 0) {
        return 4
    } else if (id % 3 === 0) {
        return 3
    } else if (id % 2 === 0) {
        return 2
    }
    return 1
}

export const getLinkingStep = (linking) => {
    switch(linking.step) {
        case STEP_PENDING :
            return 'Nouveau match'
        case STEP_RECIPIENT_ACCEPTED :
            return 'Matching en attente'
        case STEP_RECIPIENT_REFUSED :
            return 'Sans suite'
        case STEP_SENDER_ACCEPTED :
            return 'Mise en relation'
        case STEP_SENDER_REFUSED :
            return 'Sans suite'
        case STEP_AGREEMENT :
            return 'Accord'
        case STEP_DONE :
            return 'Terminée'
        default :
    }
}

export const getLinkingTime = (linking) => {
    switch(linking.step) {
        case STEP_PENDING :
            return moment(linking.createdOn).format('DD/MM/YYYY HH:mm')
        case STEP_RECIPIENT_ACCEPTED :
            return moment(linking.userAcceptedOn).format('DD/MM/YYYY HH:mm')
        case STEP_RECIPIENT_REFUSED :
            return moment(linking.userDeclinedOn).format('DD/MM/YYYY HH:mm')
        case STEP_SENDER_ACCEPTED :
            return moment(linking.ownerAcceptedOn).format('DD/MM/YYYY HH:mm')
        case STEP_SENDER_REFUSED :
            return moment(linking.ownerDeclinedOn).format('DD/MM/YYYY HH:mm')
        case STEP_AGREEMENT :
            return moment(linking.agreedOn).format('DD/MM/YYYY HH:mm')
        case STEP_DONE :
            return moment(linking.closedOn).format('DD/MM/YYYY HH:mm')
        default :
    }
}

export const truncateString = (str, max = 70) => {
    return str.length > max ? str.substring(0, max) + "..." : str;
}

// export const GetFullDate = (date) => {
//
//     const options = { weekday:"long", year:"numeric", month:"long", day:"numeric"}
//     const d = new Date(date)
//
//     return d.toLocaleDateString('fr-FR', options)
// }
//
// export const GetShortDate = (date) => {
//
//     const options = { weekday:"long", year:"numeric", month:"long", day:"numeric"}
//     const d = new Date(date)
//
//     return d.toLocaleDateString('fr-FR', options)
// }
