import React, {Suspense, useContext, useEffect, useState} from "react";
import {Await, Link, useLoaderData, useNavigate, useOutletContext} from "react-router-dom";

import {useForm} from "react-hook-form";

import {GenericEmptyItem} from "../../other/GenericBlock";
import FilterButtonBar from "../../other/FilterButtonBar";

import Api from "../../helper/api";
import {CtaReturn} from "../../other/Cta";

import {Elements, PaymentElement} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import SubscriptionCheckout from "./SubscriptionCheckout";
import {AuthContext} from "../../userAuth/AuthContext";
import ShowMoreText from "react-show-more-text";
import {useSetState} from "react-use";

/**
 * Block to display subscription details and selec it
 * @param subscription
 * @param yearly
 * @param setSelectedSubscription
 * @param selectable
 * @returns {Element}
 * @constructor
 */
const SubscriptionBlock = ({subscription, yearly, setSelectedSubscription, selectable, subscriptionInfo}) => {

    const {id,name,description,details,monthlyAmount,yearlyAmount} = subscription

    const selectSubscription = () => {

        if(!selectable)
            return;

        Array.prototype.forEach.call(document.getElementsByClassName("subscription_block_item"), (i) => i.classList.remove("active"))
        document.getElementById(`subscription_block_item${id}`).classList.add("active")
        document.getElementById(`sub${id}`).checked = true

        setSelectedSubscription({...subscription, yearly : yearly})
    }

    // if (subscriptionInfo && subscriptionInfo.subscription.id === id) {
    //     console.log('test')
        // setSelectedSubscription()
    // }

    return(
        <div className={`subscription_block_item ${selectable ? 'selectable' : ''}`} id={`subscription_block_item${id}`} onClick={selectSubscription}>
            {selectable &&
                <div className={"input"}>
                    <input type={"radio"} name={"subscription"} id={`sub${id}`}/>
                    <label htmlFor={`sub${id}`}/>
                </div>
            }
            <div className={"content"}>
                <div className={"title"}>{name}</div>
                <div className={"description"}>{description}</div>

                <div className={"details"}>{details}</div>

                {monthlyAmount && yearlyAmount &&
                    <div className={"price"}>
                        {!yearly && <><span>{monthlyAmount} €</span> HT/mois</>}
                        {yearly && <><span>{yearlyAmount} €</span> HT/an</>}
                    </div>
                }
            </div>
        </div>
    )
}
SubscriptionBlock.defaultProps = {
    setSelectedSubscription : () => {},
    selectable : true
}

/**
 * Subscriptions Listing for user to choose to
 * @param setSubscriptionChoice
 * @returns {Element}
 * @constructor
 */
const SubscriptionsOptions = ({user, setSubscriptionChoice}) => {
    // const {subscriptionsListing} = useLoaderData()
    const [subscriptionsList, setSubscriptionList] = useState([])
    const [filterValue, setFilterValue] = useState(0); //filter
    const [subscriptionInfo, setSubscriptionInfo] = useState(null)
    const api = new Api()
    const navigate = useNavigate()

    //set selected subscription
    const [selectedSubscription, setSelectedSubscription] = useState(null)

    //add free subscription to listing
    useEffect(() => {
        api.getSubscriptionsListing().then((subscriptionsListing) => {
            if (user?.job === 0) {
                const freeSubscription = {
                    id : 0,
                    name : "Aide confraternelle seulement",
                    description : "Outil communautaire gratuit",
                }
                setSubscriptionList([...subscriptionsListing, freeSubscription])
                setSelectedSubscription(freeSubscription)
                console.log(freeSubscription)
            } else {
                setSubscriptionList(subscriptionsListing)
            }
            api.getSubscriptionInfo().then(data => {
                setSubscriptionInfo(data)
                console.log(data.subscription)
                setSelectedSubscription(data.subscription)
            }).catch(error => {
                // return navigate("/abonnements")
            })
        })

    }, []);

    const select = (subscription) => {
        if (subscription.id > 0) {
            setSubscriptionChoice(selectedSubscription)
        } else {
            window.location.href = '/dashboard'
        }
    }

    return(
        <>
            {user.subscription && <div className={"cta_wrapper"}>
                <CtaReturn/>
            </div>}
            <h1 className={"d-block d-mobile-none text-center mb3"}>Quel abonnement souhaitez-vous choisir ?</h1>
            <div className={"subscription_wrapper"}>
                <FilterButtonBar filters={[
                    {value: "monthly", label: `Mensuel`},
                    {value: "yearly", label: `Annuel`, count : "-15%"}
                ]} setFilterValue={setFilterValue} alignCenter={true} fitItems={true} />

                <div id={"subscriptions_wrapper"}>
                    {/*<Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>} >*/}
                    {/*    <Await resolve={subscriptionsListing}>*/}
                            {subscriptionsList.map((s) => {
                                let selectable = true
                                if (user.job !== 0 && s.name === "Essentiel") {
                                    selectable = false
                                }
                                return (<SubscriptionBlock subscription={s} selectable={selectable} yearly={filterValue === 1} setSelectedSubscription={setSelectedSubscription} subscriptionInfo={subscriptionInfo} />)
                            })}
                        {/*</Await>*/}
                    {/*</Suspense>*/}
                </div>
                {selectedSubscription && <div className={"cta_wrapper"}>
                    <button className={"cta blue full"} onClick={() => select(selectedSubscription)}>{subscriptionInfo ? "Modifier" : "Démarrer"}</button>
                </div>}
            </div>
        </>
    )
}

/**
 * Payment form for subscription
 * @param subscription
 * @returns {Element}
 * @constructor
 */
const SubscriptionPayment = ({subscription, setSecret, setAmount, onBack}) => {

    const api = new Api()
    const {register,formState: { errors }, handleSubmit} = useForm();
    const codeForm = useForm();
    const [countryList, setCountryList] = useState([])
    const [couponError, setCouponError] = useState("")
    const [paymentError, setPaymentError] = useState("")
    const [subscriptionInfo, setSubscriptionInfo] = useState(null)
    const [code, setCode] = useState(null)
    const [price, setPrice] = useState(subscription.yearly ? subscription.yearlyAmount : subscription.monthlyAmount)
    const {updateUserInGlobalContext} = useContext(AuthContext)
    const [formDataState, setFormDataState] = useSetState({
        country : null,
        address: "",
        address2: "",
        city: "",
        postalCode: ""
    })

    useEffect(() => {
        api.getCountryList().then(setCountryList)
    },[])

    const handleSubscriptionPayment = (data) => {
        setCouponError("")
        setPaymentError("")

        const paymentData = {
            subscription : subscription.id,
            yearly : subscription.yearly,
            country: data.country,
            city: data.city,
            postal_code: data.postal_code,
            line1 : data.address,
            line2 : data.address2,
            code: code
        }
        if (code) {
            // console.log(paymentData, 'BEFORE TO PAY')
            api.postSubscriptionFree(paymentData).then(async (data) => {
                const user = await Api.getUser();
                updateUserInGlobalContext(user)
                window.location.href = "/abonnement/confirmation"
            }).catch(error => {
                console.log(error, 'EsterLaw payment error')
                setPaymentError("Une erreur est survenue lors de l'initialisation du paiement.")
            })
        } else {
            // console.log(paymentData, 'BEFORE TO PAY')
            api.postSubscription(paymentData).then((data) => {
                setAmount(subscription.yearly ? subscription.yearlyAmount : subscription.monthlyAmount)
                setSecret(data.clientSecret)
            }).catch(error => {
                console.log(error, 'EsterLaw payment error')
                setPaymentError("Une erreur est survenue lors de l'initialisation du paiement.")
            })
        }
    }

    const handleSubscriptionCode = (data) => {
        setCouponError("")
        setPaymentError("")

        api.checkCode(data).then((response) => {
            setCode(data.code)
            setSubscriptionInfo(response)
            let currentPrice = 0
            const initialAmount = subscription.yearly ? subscription.yearlyAmount : subscription.monthlyAmount
            if (data.code) {
                currentPrice = initialAmount - (initialAmount / 100 * response.discount)
            } else {
                currentPrice = initialAmount
            }
            if (currentPrice < 0) {
                currentPrice = 0
            }
            setAmount(currentPrice)
            setPrice(currentPrice)
        })
        .catch((error) => {
            setCouponError("Code incorrect")
        });

    }

    return (
        <div id={"subscriptions_wrapper"}>
            <div className={"cta_wrapper"}>
                <button className={"cta return"} onClick={onBack}/>
            </div>

            <div className={"subscription_wrapper"}>
                <h1 className={"d-block d-mobile-none text-center mb3"}>Votre abonnement</h1>
                {<SubscriptionBlock subscription={subscription} yearly={subscription.yearly} selectable={false}/>}

                {!subscriptionInfo && <form onSubmit={codeForm.handleSubmit(handleSubscriptionCode)} className={"subscription_block_item"}>
                    <div className={"content"}>
                        <div className={"title"}>Vous avez un code ?</div>
                        <div className={"form_row_wrapper type1"}>
                            <input type={"text"} name={"code"} id={"code"} placeholder={"Votre code confidentiel"} {...codeForm.register('code', {required: "Veuillez renseigner un code"})} />
                            <div className="error">{codeForm.formState.errors.code && codeForm.formState.errors.code.message}</div>
                            {couponError !== "" && <div className={"error text-center"}>{couponError}</div>}
                        </div>
                        <div className={"cta_wrapper"}>
                            <button className={"cta full blue"}>Ajouter</button>
                        </div>
                    </div>
                </form>}
                {subscriptionInfo && <div className={"subscription_block_item subscription_code_block"}>
                    <div className={"content"}>
                        <div className={"title"}>{subscriptionInfo.info} ({code})</div>
                        <button className={"cta small blue"} onClick={() => setSubscriptionInfo(null)}>Supprimer</button>
                    </div>
                </div>}

                <div className={"profil_block"}>
                    <h2 className={"d-block d-mobile-none mt3 mb2"}>Facturation</h2>
                    <form onSubmit={handleSubmit(handleSubscriptionPayment)}>
                        <div className={"form_row_wrapper type3"}>
                            <label>Pays</label>
                            <select name={"country"} id={"country"} defaultValue={""} {...register("country", {required: "Veuillez renseigner cette information"})}>
                                <option value="" disabled={true}>Pays</option>
                                {countryList.map((c, index) => {
                                    return (<option value={c.id} key={index}>{c.name}</option>)
                                })}
                            </select>
                            <div className="error">{errors.country && errors.country.message}</div>
                        </div>
                        <div className={"form_row_wrapper type3"}>
                            <label>Adresse</label>
                            <input type={"text"} name={"address"} id={"address"} placeholder={"Adresse"} {...register('address', {required: "Veuillez renseigner cette information"})}/>
                            <div className="error">{errors.address && errors.address.message}</div>
                        </div>
                        <div className={"form_row_wrapper type3"}>
                            <label>Adresse 2</label>
                            <input type={"text"} name={"address2"} id={"address2"} placeholder={"Adress (suite)"} {...register('address2')}/>
                            <div className="error">{errors.address2 && errors.address2.message}</div>
                        </div>

                        <div className={"form_row_wrapper type3"}>
                            <label>Ville</label>
                            <input type={"text"} name={"city"} id={"city"} placeholder={"Ville"} {...register('city', {required: "Veuillez renseigner cette information"})}/>
                            <div className="error">{errors.city && errors.city.message}</div>
                        </div>

                        <div className={"form_row_wrapper type3"}>
                            <label>Code Postal</label>
                            <input type={"text"} name={"postal_code"} id={"postal_code"} placeholder={"Code postal"} {...register('postal_code', {required: "Veuillez renseigner cette information"})}/>
                            <div className="error">{errors.postal_code && errors.postal_code.message}</div>
                        </div>

                        <div className={"cta_wrapper"}>
                            <button className={"cta full blue"}>Payer {price}€</button>
                        </div>
                        {paymentError !== "" && <div className={"error text-center"}>{paymentError}</div>}
                    </form>
                </div>
            </div>
        </div>
    )
}

/**
 * Global component
 * @returns {Element}
 * @constructor
 */
const Subscriptions = () => {

    const [selectedSubscription, setSelectedSubscription] = useState(null)
    const [clientSecret, setClientSecret] = useState(null)
    const [amount, setAmount] = useState(null)
    const {user} = useOutletContext();
    // const [stripePromise, setStripePromise] = useState('')
    const stripePromise = loadStripe("pk_live_51P3ve9KnO1D0RDb5ZhWbKxv7KUmtCxQeka4GZdtKnDHASXRxNKLtz4by0yCCHPfV96X74XPGE6XHRxtnhfwYJ6Vg00dQpxEk6Z");
    // const stripePromise = loadStripe("pk_test_51P3ve9KnO1D0RDb5yB3X4i5cyF1GfMdEyFzMELVMd2hevdPgacUnYJnJ6Koa7cVU4WQnxBzPBpdD1e23cIqp2Szm00w8iUSSGl");

    // useEffect(() => {
    //     setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_KEY));
    //     // const stripePromise = loadStripe("pk_test_51P3ve9KnO1D0RDb5yB3X4i5cyF1GfMdEyFzMELVMd2hevdPgacUnYJnJ6Koa7cVU4WQnxBzPBpdD1e23cIqp2Szm00w8iUSSGl");
    // },[])

    return (
        <>
            {!selectedSubscription && !clientSecret && <SubscriptionsOptions user={user} setSubscriptionChoice={setSelectedSubscription}/>}
            {selectedSubscription && !clientSecret && <SubscriptionPayment subscription={selectedSubscription} setSecret={setClientSecret} setAmount={setAmount}  onBack={() => setSelectedSubscription(null)} />}
            {selectedSubscription && clientSecret &&  <Elements stripe={stripePromise} options={{ clientSecret: clientSecret }}>
                <SubscriptionCheckout amount={amount} clientSecret={clientSecret} onBack={() => setClientSecret(null)} />
            </Elements>}
        </>
    )
}

export default Subscriptions