import Api from "../helper/api";
import {
    PROFIL_TYPE_CANDIDAT,
    PROFIL_TYPE_RECRUTEUR,
    STEP_AGREEMENT, STEP_DONE,
    STEP_PENDING,
    STEP_RECIPIENT_ACCEPTED,
    STEP_RECIPIENT_REFUSED,
    STEP_SENDER_ACCEPTED, STEP_SENDER_REFUSED
} from "../other/Constant";

export const getUserProfileData = async (context) => {

    const api = new Api()
    let promises = {}

    //Global promise
    const myHelpPromise = api.getHelps(true, null, null, context.state.user.id)
    const helpPromise = api.getHelpLinkings(null, null, null)
    const helpMePromise = api.getHelpLinkings(null, null, true)

    const mySwitchPromise = api.getUserSwitchDuties(true, null, null, context.state.user.id)
    const switchPromise = api.getSwitchLinkings(null, null, null)
    const switchMePromise = api.getSwitchLinkings(null, null, true)


    promises = {
        helps : helpPromise,
        helpsMe : helpMePromise,
        myHelps : myHelpPromise,
        mySwitchs : mySwitchPromise,
        switchs : switchPromise,
        switchsMe : switchMePromise,
    }

    //data for candidate
    if(context.state.user.profileType === PROFIL_TYPE_CANDIDAT){

        const missionsActive = api.getUserMissions([STEP_SENDER_ACCEPTED], true,null)
        // const missionsActive = api.getUserMissions([STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED], true,false)
        const missionsAgreement = api.getUserMissions([STEP_AGREEMENT], true,null)

        // const missionsPromise = api.getUserMissions([STEP_AGREEMENT], true,false)
        const missionsPromise = {
            active : missionsActive,
            agreement : missionsAgreement
        }

        // const pendingMatchsPromise = api.getMissionLinkings(null,[STEP_PENDING,STEP_SENDER_ACCEPTED])
        // const allMatchsPromise = api.getMissionLinkings()
        // const agreementMatchPromise = api.getMissionLinkings(null,[STEP_AGREEMENT])
        // const discussMatchPromise = api.getMissionLinkings(null,[STEP_SENDER_ACCEPTED])

        const matchsMissionPromise = api.getMissionLinkings(null,[STEP_PENDING, STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED, STEP_SENDER_REFUSED, STEP_AGREEMENT, STEP_DONE])
        const matchsHelpPromise = api.getHelpLinkings(null, [STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED, STEP_SENDER_REFUSED, STEP_AGREEMENT, STEP_DONE], true)
        const matchsSwitchPromise = api.getSwitchLinkings(null, [STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED, STEP_SENDER_REFUSED, STEP_AGREEMENT, STEP_DONE], true)

        promises = {
            ...promises,
            missions : missionsPromise,

            matchsMission : matchsMissionPromise,
            matchsHelp : matchsHelpPromise,
            matchsSwitch : matchsSwitchPromise,

            // pendingMatchs : pendingMatchsPromise,
            // allMatchsPromise : allMatchsPromise,
            // agreementMatchPromise : agreementMatchPromise,
            // discussMatchPromise : discussMatchPromise
        }

    } else if(context.state.user.profileType === PROFIL_TYPE_RECRUTEUR) {

        //data for recruiter
        const missionsActive = api.getUserMissions(null, true,null)
        const missionsClosed = api.getUserMissions(null, false,true)

        const missionsPromise = {
            active : missionsActive,
            closed : missionsClosed
        }

        const matchsMissionPromise = api.getMissionLinkings(null, [STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED, STEP_SENDER_REFUSED, STEP_AGREEMENT, STEP_DONE])
        const matchsHelpPromise = api.getHelpLinkings(null, [STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED, STEP_SENDER_REFUSED, STEP_AGREEMENT, STEP_DONE], true)
        const matchsSwitchPromise = api.getSwitchLinkings(null, [STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED, STEP_SENDER_REFUSED, STEP_AGREEMENT, STEP_DONE], true)

        // const pendingMatchsPromise = api.getMissionLinkings(null,STEP_RECIPIENT_ACCEPTED)
        // const acceptedMatchPromise = api.getMissionLinkings(null,STEP_SENDER_ACCEPTED)
        // const agreementMatchPromise = api.getMissionLinkings(null,STEP_AGREEMENT)

        // const allMatchsPromise = api.getMissionLinkings()

        promises = {
            ...promises,
            missions : missionsPromise,

            matchsMission : matchsMissionPromise,
            matchsHelp : matchsHelpPromise,
            matchsSwitch : matchsSwitchPromise,

            // pendingMatchs : pendingMatchsPromise,
            // acceptedMatchPromise : acceptedMatchPromise,
            // agreementMatchPromise : agreementMatchPromise,
            // allMatchsPromise : allMatchsPromise
        }
    }

    return promises
}
