import { useNavigate } from 'react-router-dom';
import Api from "../helper/api";
import React, {useState, useEffect} from "react";
import {useGoogleLogin} from '@react-oauth/google';
import axios from "axios";

export const CtaReturn = ({link}) => {

    const navigate = useNavigate();

    // console.log(window.history.state.idx, 'STATE CHECK')

    if (window.history.state.idx > 0) {
        return(
            <button className={"cta return"} onClick={() => link ? navigate(link) : navigate(-1)} />
        )
    } else {
        return(
            <></>
        )
    }
}

export const CtaBookmark = ({item, userId, isActive=false}) => {

    const api = new Api()
    const [bookMarkId, setBookMarkId] = useState(item.bookmarks ? item.bookmarks.at(0).id : null)

    const handleLikeClick = async () => {

        if(bookMarkId === null) {
            api.setMatchBookmark(item.id, userId).then((data) => setBookMarkId(data.id))
        }
        else {
            api.deleteMatchBookmark(bookMarkId)
            setBookMarkId(null)
        }
    }

    return(
        <button className={`cta bookmark ${bookMarkId!==null?"active":""}`} onClick={handleLikeClick}/>
    )
}

export const CtaLike = ({item, userId, isActive=false}) => {

    const api = new Api()
    const [bookMarkId, setBookMarkId] = useState(item.likes ? item.likes.at(0).id : null)

    const handleLikeClick = async () => {

        if(bookMarkId === null) {
            api.setMatchBookmark(item.id, userId).then((data) => setBookMarkId(data.id))
        }
        else {
            api.deleteMatchBookmark(bookMarkId)
            setBookMarkId(null)
        }
    }

    return(
        <button className={`cta like ${bookMarkId!==null?"active":""}`} onClick={handleLikeClick}/>
    )
}

export const CtaMessage = ({type, match}) => {
    const navigate = useNavigate()

    let linkingType = ''
    if (type == 'help' || type == 'aide') {
        linkingType = 'help_linkings'
    } else if (type == 'switch' || type == 'echange') {
        linkingType = 'switch_duty_linkings'
    } else if (type == 'mission') {
        linkingType = 'linkings'
    }

    const handleMessageClick = () => {
        // console.log(match, "MATCH FOR MESSAGE")
        navigate(`/messagerie/${match.uid}/${linkingType}`)
        // navigate(`/messagerie/${match.id}/${match.uid}`)
    }

    return(
        <button className={"cta message"} onClick={handleMessageClick}/>
    )
}

export const CtaGoogleLogin = (onLogin) => {

    const [user, setUser] = useState([])
    const navigate = useNavigate()
    const api = new Api()

    const handleLoginGoogle = useGoogleLogin({
        onSuccess: tokenResponse => setUser(tokenResponse)
    });

    useEffect(
        () => {
            if (user.access_token) {
                axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {

                    const data = {
                        email: res.data?.email,
                        googleId: res.data?.id,
                        password: res.data?.id,
                        locale: 'fr',
                        lastname: res.data?.family_name,
                        firstname: res.data?.given_name
                    }
                    api.checkIfExist("", data.googleId,"")
                        .then(()=>{
                            onLogin({
                                mail: data.email,
                                password: data.password
                            })
                        })
                        .catch(() => {
                            navigate(`/inscription_sso`, {state : {...data}})
                        })

                })
                .catch((err) => console.log(err));
            }
        },
        [ user ]
    );

    return (
        <button className={"cta social_login google"} onClick={() => handleLoginGoogle()}><span>Continuer avec Google</span></button>
    )
}