import React, {useEffect, Suspense, useState} from "react";
import Api from "../../helper/api";
import {Await, useLoaderData, useNavigate, useOutletContext, useRevalidator} from "react-router-dom";
import {GenericEmptyItem} from "../../other/GenericBlock";
import ProfilDataBlock from "../../other/ProfilDataBlock";
import {CtaBookmark, CtaMessage, CtaReturn} from "../../other/Cta";
import SingleItemHeader, {OtherSingleItemHeader} from "../_singleItem/SingleItemHeader";
import SingleItemMatch from "../_singleItem/SingleItemMatch";
import SlidingPanel from "../_sliding_panel";
import SingleItemDetails from "../_singleItem/SingleItemDetails";
import {
    PROFIL_TYPE_CANDIDAT,
    PROFIL_TYPE_RECRUTEUR,
    STEP_AGREEMENT,
    STEP_DONE,
    STEP_PENDING,
    STEP_RECIPIENT_ACCEPTED, STEP_RECIPIENT_REFUSED,
    STEP_SENDER_ACCEPTED,
    STEP_SENDER_REFUSED
} from "../../other/Constant";
import ItemLabel from "../../other/ItemLabel";
import moment from "moment";
import SingleItemMessages from "../_singleItem/SingleItemMessages";
import SingleItemCollaborateur from "../_singleItem/SingleItemCollaborateur";
import CreateCompany from "../../slidingContent/createCompany";
import {useChat} from "../../other/Twilio";
import {getIcon} from "../../helper/other";
import SingleHelpItemDetails from "../_singleItem/SingleHelpItemDetails";
import SingleSwitchItemDetails from "../_singleItem/SingleSwitchItemDetails";


const ProfileDetail = ({match}) => {
    return (
        <div className="single_match_block" id={"match_details"}>
            <h2>Profil</h2>

            <div className="content">
                <div className={"picto user"}>{match.user.firstname} {match.user.lastname}</div>
                {match.user.email && <div className={"picto mail"}>{match.user.email}</div>}
                {match.user.phone && <div className={"picto phone"}>{match.user.phone}</div>}
                {match.user.location && <div className={"picto location"}>{match.user.location}</div>}
                {match.user.bar && <div className={"picto bar"}>{match.user.bar?.name}</div>}
            </div>
        </div>
    )
}
const SingleMatchCurrent = ({match, type, user, acceptMatch, declineMatch, agreementMatch, multiple}) => {

    return(
        // <div id={"single_match_wrapper"} className={"single_match_recruteur"}>
        <div className={"single_item_wrapper"}>
            <div className="cta_wrapper d-mobile-none">
                <CtaReturn />
            </div>

            <div className={"side-left d-mobile-none"}>
                <div className={"single_item_header single_mission_header"}>
                    <div className="cta_wrapper" id={"match_header"}>
                        {!multiple && <CtaReturn />}
                        <div>
                            {(match.step === STEP_SENDER_ACCEPTED || match.step === STEP_AGREEMENT) && <CtaMessage type={type} match={match} />}
                            {/*<CtaBookmark type={"match"} item={match} userId={user.id}/>*/}
                        </div>
                    </div>
                    <div>
                        {(match.userAcceptedOn || match.ownerAcceptedOn) && <div className="single_match_block" id={"match_details"}>
                            <h1>Profil</h1>
                            <div className="content">
                                {!match.ownerAcceptedOn && <>
                                    {match.user.createdAt && <h5>Membre actif depuis {moment(match.user.createdAt).format('DD/MM/YYYY')}</h5>}
                                    <div className={"picto bar"}>Barreau : {match.user.bar?.name}</div>
                                    {match.mission && <>
                                        <div className={"picto experience"}>Expérience : {match.user.experience?.name}</div>
                                        {match.user.languages && match.user.languages.length > 0 && <div className={"picto languages language"}>
                                            <div>Langues :&nbsp;{match.user.languages.map((item) => <div>{`${item.name} / `}</div>)}</div>
                                        </div>}
                                        {match.user.countries && match.user.countries.length > 0 && <div className={"picto countries language"}>
                                            <div>Expertise internationale :&nbsp;{match.user.countries.map((item) => <div>{`${item.name} / `}</div>)}</div>
                                        </div>}
                                    </>}
                                </>}
                                {match.ownerAcceptedOn && <>
                                    <div className={"picto user"}>{match.user.firstname} {match.user.lastname}</div>
                                    {match.user.email && <div className={"picto mail"}>{match.user.email}</div>}
                                    {match.user.phone && <div className={"picto phone"}>{match.user.phone}</div>}
                                    {match.user.location && <div className={"picto location"}>{match.user.location}</div>}
                                    {match.user.bar && <div className={"picto bar"}>{match.user.bar?.name}</div>}
                                    {match.user.experience && <div className={"picto experience"}>{match.user.experience?.name}</div>}
                                    {match.user.languages && match.user.languages.length > 0 && <div className={"picto languages language"}>
                                        Langues : {match.user.languages.map((item) => <div>{`${item.name} / `}</div>)}
                                    </div>}
                                    {match.user.countries && match.user.countries.length > 0 && <div className={"picto countries language"}>
                                        Expertise internationale : {match.user.countries.map((item) => <div>{`${item.name} / `}</div>)}
                                    </div>}
                                </>}
                                {match.message && <div className={""}>Note : {match.message}</div>}
                            </div>
                        </div>}

                        {match.mission && match.user?.id !== user.id && <>
                            {match.user.description && <div className="single_match_block" id={"match_presentation"}>
                                <h2>Présentation</h2>
                                <p>{match.user.description}</p>
                            </div>}

                            {match.user.skills?.length > 0 && <div className="single_match_block" id={"match_skill"}>
                                <h2>Compétences</h2>
                                {match.user.skills?.map((item, i) => <ProfilDataBlock title={item.area.name} experience={item.experience.name} international={item.countries} description={item.description} subareas={item.subareas}/>)}
                            </div>}

                            {match.user.specializations?.length > 0 && <div className="single_match_block" id={"match_specialization"}>
                                <h2>Spécialisations</h2>
                                {match.user.specializations?.map((item, i) => <ProfilDataBlock key={`specialization_data${i}`} title={item.specialization.name} experience={item.experience.name} description={item.description}/>)}
                            </div>}

                            {match.user.industries?.length > 0 && <div className="single_match_block" id={"match_industry"}>
                                <h2>Secteurs d'activité</h2>
                                {match.user.industries?.map((item, i) => <ProfilDataBlock key={`secteur_data${i}`} title={item.industry.name} experience={item.experience ? item.experience.name : null} international={item.countries}
                                                                                          description={item.description}/>)}
                            </div>}

                            {match.user.jobs?.length > 0 && <div className="single_match_block" id={"match_job"}>
                                <h2>Parcours professionnel</h2>
                                {match.user.jobs?.map((item, i) => <ProfilDataBlock key={`job_data${i}`} title={item.title} description={item.description}/>)}
                            </div>}

                            {match.user.educations?.length > 0 && <div className="single_match_block" id={"match_formation"}>
                                <h2>Formation et certifications</h2>
                                {match.user.educations?.map((item, i) => <ProfilDataBlock key={`education_data${i}`} title={item.title} description={item.description}/>)}
                            </div>}
                        </>}
                        {match.help && match.help.user?.id !== user.id && <SingleHelpItemDetails singleItem={match.help} detail={false}/>}
                        {match.switchDuty && match.switchDuty.user?.id !== user.id && <SingleSwitchItemDetails singleItem={match.switchDuty} detail={false}/>}
                    </div>
                </div>
            </div>
            <div className={`side-right side-right-last ${multiple ? 'border-side-left' : ''} d-mobile-none`}>
                <div id={"single_item_collab"} className={"single_item_collab linking_step_wrapper"}>
                    <div className={"linking_step_wrapper"}>
                        <h2>Étapes</h2>
                        {match.userAcceptedOn && <div className={"linking_step_item"}>
                            <div className={"linking_step_item_status"}>Intérêt notifié</div>
                            <div className={"linking_step_item_date"}>{moment(match.userAcceptedOn).format('DD/MM/YYYY HH:mm')}</div>
                        </div>}
                        {match.ownerAcceptedOn && <div className={"linking_step_item"}>
                            <div className={"linking_step_item_status"}>Mise en relation</div>
                            <div className={"linking_step_item_date"}>{moment(match.ownerAcceptedOn).format('DD/MM/YYYY HH:mm')}</div>
                        </div>}
                        {match.userDeclinedOn && <div className={"linking_step_item"}>
                            <div className={"linking_step_item_status"}>Sans suite</div>
                            <div className={"linking_step_item_date"}>{moment(match.userDeclinedOn).format('DD/MM/YYYY HH:mm')}</div>
                        </div>}
                        {match.ownerDeclinedOn && <div className={"linking_step_item"}>
                            <div className={"linking_step_item_status"}>Sans suite</div>
                            <div className={"linking_step_item_date"}>{moment(match.ownerDeclinedOn).format('DD/MM/YYYY HH:mm')}</div>
                        </div>}
                        {match.agreedOn && <div className={"linking_step_item"}>
                            <div className={"linking_step_item_status"}>Accord</div>
                            <div className={"linking_step_item_date"}>{moment(match.agreedOn).format('DD/MM/YYYY HH:mm')}</div>
                        </div>}
                        {match.closedOn && <div className={"linking_step_item"}>
                            <div className={"linking_step_item_status"}>Mission finalisée avec succès</div>
                            <div className={"linking_step_item_date"}>{moment(match.closedOn).format('DD/MM/YYYY HH:mm')}</div>
                        </div>}
                        {match.user.id !== user.id && match.step === STEP_RECIPIENT_ACCEPTED &&
                            <>
                                <button className={"cta blue full"} onClick={() => acceptMatch(match.id)}>{match.mission ? "J'accepte et souhaite discuter" : "Je suis intéressé et souhaite discuter"}</button>
                                <button className={"cta blue reverse full"} onClick={() => declineMatch(match.id)}>Non merci</button>
                            </>
                        }
                        {match.userAcceptedOn && !match.userDeclinedOn && match.ownerAcceptedOn && !match.ownerDeclinedOn && !match.agreedOn && <div>
                            <button className={"cta blue reverse full"} onClick={() => declineMatch(match.id)}>Sans suite</button>
                            {(match.mission?.user?.id === user.id || match.help?.user?.id === user.id || match.switchDuty?.user?.id === user.id) && <button className={"cta blue full"} onClick={() => agreementMatch(match.id)}>Avocat retenu</button>}
                            {(match.mission?.user?.id === user.id || match.help?.user?.id === user.id || match.switchDuty?.user?.id === user.id) && <p>Merci de cliquer ici pour nous informer que vous avez missioné cet avocat</p>}
                        </div>}
                    </div>
                </div>
                <SingleItemMessages type={type} linkings={[match]} />
            </div>
        </div>
    )
}

const SingleMatchCandidat = ({match, type, user, acceptMatch, declineMatch, agreementMatch}) => {

    //filter
    const [showSlidingPanelDetails, setShowSlidingPanelDetails] = useState(false);
    const navigate = useNavigate()

    console.log(match, 'MATCH FROM CANDIDAT')

    return(
        <div className={"single_item_wrapper"}>
            <div className={"side-left d-mobile-none"}>
                <div className={"single_item_header single_mission_header"}>
                    <div className={"cta_wrapper"} id={"match_header"}>
                        <CtaReturn/>
                        {(match.step === STEP_SENDER_ACCEPTED || match.step === STEP_AGREEMENT) && <CtaMessage type={type} match={match} />}
                    </div>
                    <div className={""}>
                        {(match.step === STEP_SENDER_ACCEPTED || match.step >= STEP_AGREEMENT) && <ProfileDetail match={match}/>}
                        <SingleItemDetails singleItem={match.mission} detail={false}/>
                    </div>
                </div>
            </div>
            <div className={"side-right d-mobile-none"}>
                <div id={"single_mission_collab"} className={"linking_step_wrapper"}>
                    <h2>Étapes</h2>
                    {match.userAcceptedOn && <div className={"linking_step_item"}>
                        <div className={"linking_step_item_status"}>Intérêt notifié</div>
                        <div className={"linking_step_item_date"}>{moment(match.userAcceptedOn).format('DD/MM/YYYY HH:mm')}</div>
                    </div>}
                    {!match.userAcceptedOn && match.userDeclinedOn && <div className={"linking_step_item"}>
                        <div className={"linking_step_item_status"}>Pas intéressé</div>
                        <div className={"linking_step_item_date"}>{moment(match.userDeclinedOn).format('DD/MM/YYYY HH:mm')}</div>
                    </div>}
                    {match.ownerAcceptedOn && <div className={"linking_step_item"}>
                        <div className={"linking_step_item_status"}>Mise en relation</div>
                        <div className={"linking_step_item_date"}>{moment(match.ownerAcceptedOn).format('DD/MM/YYYY HH:mm')}</div>
                    </div>}
                    {match.userAcceptedOn && match.userDeclinedOn && <div className={"linking_step_item"}>
                        <div className={"linking_step_item_status"}>Sans suite</div>
                        <div className={"linking_step_item_date"}>{moment(match.userDeclinedOn).format('DD/MM/YYYY HH:mm')}</div>
                    </div>}
                    {match.ownerDeclinedOn && <div className={"linking_step_item"}>
                        <div className={"linking_step_item_status"}>Recherche mission terminée</div>
                        <div className={"linking_step_item_date"}>{moment(match.ownerDeclinedOn).format('DD/MM/YYYY HH:mm')}</div>
                    </div>}
                    {/*{match.step === STEP_SENDER_ACCEPTED && <button className={"cta blue full"} onClick={() => agreementMatch(match.id)}>Passer à l'accord</button>}*/}
                    {/*{match.step == STEP_SENDER_ACCEPTED && match.user.id !== user.id && <div>*/}
                    {/*    <button className={"cta blue full"} onClick={() => agreementMatch(match.id)}>Je suis missioné</button>*/}
                    {/*    <p>Merci de cliquer ici pour nous informer que vous avez été missioné</p>*/}
                    {/*</div>}*/}
                    {match.agreedOn && <div className={"linking_step_item"}>
                        <div className={"linking_step_item_status"}>Accord</div>
                        <div className={"linking_step_item_date"}>{moment(match.agreedOn).format('DD/MM/YYYY HH:mm')}</div>
                    </div>}
                    {match.closedOn && <div className={"linking_step_item"}>
                        <div className={"linking_step_item_status"}>Mission finalisée avec succès</div>
                        <div className={"linking_step_item_date"}>{moment(match.closedOn).format('DD/MM/YYYY HH:mm')}</div>
                    </div>}
                    {match.step === STEP_PENDING && <>
                        <button className={"cta blue full"} onClick={() => {
                            if (!user?.subscription) {
                                navigate('/abonnements')
                            } else {
                                acceptMatch(match.id)
                            }
                        }}>Je suis intéressé(e) et souhaite être mis en relation</button>
                        <button className={"cta blue reverse full"} onClick={() => {
                            if (!user?.subscription) {
                                navigate('/abonnements')
                            } else {
                                declineMatch(match.id)
                            }
                        }}>Non merci</button>
                    </>}
                    {match.userAcceptedOn && !match.userDeclinedOn && match.ownerAcceptedOn && !match.ownerDeclinedOn && !match.agreedOn && <>
                        <button className={"cta blue reverse full"} onClick={() => declineMatch(match.id)}>Sans suite</button>
                    </>}
                </div>
                <SingleItemMessages type={type} linkings={[match]} />
            </div>

            <div className={"d-desktop-none"}>
                <div className="single_match_block" id={"match_header"}>
                    <CtaReturn />
                    {(match.step === STEP_SENDER_ACCEPTED || match.step === STEP_AGREEMENT) && <CtaMessage match={match}/>}
                    {/*<CtaLike type={"match"} item={match} userId={user.id}/>*/}
                </div>
                <div className="single_match_block">
                    <h2>{match.mission.title}</h2>
                </div>

                <div className={"other_single_item_header"}>
                    {(match.step === STEP_SENDER_ACCEPTED || match.step >= STEP_AGREEMENT) && <ProfileDetail match={match}/>}

                    <div className={"single_match_header"}>
                        <div className={`item_label item_label_${getIcon(match.mission.id)}`} />

                        <div className={"single_match_header_detail"}>
                            {match.mission.type &&
                                <div className={"other_single_item_data"}>
                                    <h3>{match.mission.type.name}</h3>
                                </div>
                            }
                            {match.mission.bar &&
                                <div className={"other_single_item_data"}>
                                    <div className={"picto bar"}>{match.mission.bar.name}</div>
                                </div>
                            }
                            {match.mission.experience &&
                                <div className={"other_single_item_data"}>
                                    <div className={"picto experience"}>{match.mission.experience.name}</div>
                                </div>
                            }
                            {match.mission.location &&
                                <div className={"other_single_item_data"}>
                                    <div className={"picto location"}>{match.mission.location}</div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={"content"}>
                        {match.mission.description &&
                            <div className="single_match_block">
                                <h2>Présentation</h2>
                                <p>{match.mission.description}</p>
                            </div>
                        }
                        {match.mission.estimatedBudget &&
                            <div className={"single_match_block"}>
                                <h2>Budget prévisionel</h2>
                                <p>{match.mission.estimatedBudget} €</p>
                            </div>
                        }
                        {match.mission.estimatedDuration &&
                            <div className="single_match_block">
                                <h2>Durée estimée</h2>
                                <p>{match.mission.estimatedDuration.name}</p>
                            </div>
                        }
                        {match.mission.type &&
                            <div className={"single_match_block"}>
                                <h2>Type</h2>
                                <p>{match.mission.type.name}</p>
                            </div>
                        }
                        {match.mission.collaborationType &&
                            <div className={"single_match_block"}>
                                <h2>Mode de collaboration</h2>
                                <p>{match.mission.collaborationType.name}</p>
                            </div>
                        }
                        {/*{match.mission.skills.length > 0 && <div className="single_match_block" id={"match_skill"}>*/}
                        {/*    <h2>Compétences</h2>*/}
                        {/*    {match.mission.skills.map((item, i) => <ProfilDataBlock title={item.area.name} experience={item.experience.name} international={item.countries} description={item.description} subareas={item.subareas}/>)}*/}
                        {/*</div>}*/}

                        {match.mission.specializations.length > 0 && <div className="single_match_block" id={"match_specialization"}>
                            <h2>Spécialisations</h2>
                            {match.mission.specializations.map((item, i) => <ProfilDataBlock key={`specialization_data${i}`} title={item.specialization.name} experience={item.experience.name} description={item.description}/>)}
                        </div>}

                        {match.mission.industries.length > 0 && <div className="single_match_block" id={"match_industry"}>
                            <h2>Secteurs d'activité</h2>
                            {match.mission.industries.map((item, i) => <ProfilDataBlock key={`secteur_data${i}`} title={item.industry.name} experience={item.experience ? item.experience.name : null} international={item.countries}
                                                                                     description={item.description}/>)}
                        </div>}
                    </div>
                </div>
                <div id={"single_help_cta"} className={"single_item_cta_wrapper"}>
                    {match.step === STEP_PENDING && <>
                        <button className={"cta blue full"} onClick={() => {
                            if (!user?.subscription) {
                                navigate('/abonnements')
                            } else {
                                acceptMatch(match.id)
                            }
                        }}>Je suis intéressé(e) et souhaite être mis en relation</button>
                        <button className={"cta blue reverse full"} onClick={() => {
                            if (!user?.subscription) {
                                navigate('/abonnements')
                            } else {
                                declineMatch(match.id)
                            }
                        }}>Non merci</button>
                    </>}
                    {match.step === STEP_RECIPIENT_ACCEPTED && <button className={"cta transparent full disabled"}>Intérêt manifesté</button>}
                    {/*{match.step === STEP_SENDER_ACCEPTED && <button className={"cta blue full"} onClick={() => agreementMatch(match.id)}>Passer à l'accord</button>}*/}
                </div>
            </div>
            <SlidingPanel show={showSlidingPanelDetails} handleClose={() => setShowSlidingPanelDetails(false)} forceSliding={true}>
                <SingleItemDetails singleItem={match}/>
            </SlidingPanel>
        </div>
    )
}

const SingleMatch = (props) => {

    //const [singleMatch, setSingleMatch] = useState([])
    const api = new Api()
    const revalidator = useRevalidator()
    const [matchConfirmation, setMatchConfirmation] = useState(null)
    const [currentMatch, setCurrentMatch] = useState(null)
    const [matchDeclineConfirmation, setMatchDeclineConfirmation] = useState(null)

    let {match} = useLoaderData()

    if (props.match) {
        match = props.match
    } else {
    }
    // console.log(match, "CHECK MATCH DATA")
    const {user} = useOutletContext()

    const updateMatchStep = (cMatch, step) => {
        console.log(cMatch, 'UPDATE MATCH STEP')
        if (cMatch.help || props.type === 'aide') {
            api.updateHelpMatch(cMatch.id, step).then(() => {
                revalidator.revalidate()
                setMatchConfirmation(step)
            })
        } else if (cMatch.switchDuty || props.type == 'echange') {
            api.updateSwitchMatch(cMatch.id, step).then(() => {
                revalidator.revalidate()
                setMatchConfirmation(step)
            })
        } else {
            api.updateMatch(cMatch.id, step).then(() => {
                revalidator.revalidate()
                setMatchConfirmation(step)
            })
        }
    }

    const setMatchDecline = (cMatch, step) => {
        setCurrentMatch(cMatch)
        setMatchDeclineConfirmation(step)
    }

    return(
        <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
            <Await resolve={match} errorElement={<p>Error loading Data !</p>}>
                {(match) => {
                    let linkingType = 'mission'
                    if (match.help || props.type === 'aide') {
                        linkingType = 'help'
                    } else if (match.switchDuty || props.type === 'echange') {
                        linkingType = 'switch'
                    }
                    if (((match.mission || props.type == 'mission') && user.profileType === PROFIL_TYPE_RECRUTEUR) || match.help || props.type === 'aide' || props.type == 'echange' || match.switchDuty) {
                        let declineStep = STEP_SENDER_REFUSED
                        if (((match.help || props.type === 'aide') && match.help?.user?.id !== user.id) || ((props.type == 'echange' || match.switchDuty) && match.switchDuty?.user?.id !== user.id)) {
                            declineStep = STEP_RECIPIENT_REFUSED
                        }
                        return(<SingleMatchCurrent match={match} user={user} type={linkingType} acceptMatch={(match_id) => updateMatchStep(match, STEP_SENDER_ACCEPTED)} declineMatch={(match_id) => setMatchDecline(match, declineStep)} agreementMatch={(match_id) => updateMatchStep(match, STEP_AGREEMENT)} multiple={props.multiple}/>)
                    } else {
                        return(<SingleMatchCandidat match={match} user={user} type={linkingType} acceptMatch={(match_id) => updateMatchStep(match, STEP_RECIPIENT_ACCEPTED)} declineMatch={(match_id) => setMatchDecline(match, STEP_RECIPIENT_REFUSED)} />)
                    }
                }}
            </Await>
            <SlidingPanel show={matchDeclineConfirmation} handleClose={() => setMatchDeclineConfirmation(null)}>
                <div>
                    <div className={"bloc_title_info"}>
                        {matchDeclineConfirmation === STEP_RECIPIENT_REFUSED && <>
                            <h2>Attention</h2>
                            <p>Êtes-vous sûr de vouloir refuser cette offre de mission ?</p>
                        </>}
                        {matchDeclineConfirmation === STEP_SENDER_REFUSED && <>
                            <h2>Attention</h2>
                            <p>Êtes-vous sûr de ne pas vouloir retenir cette candidature ?</p>
                        </>}
                    </div>
                    <div className={"text-center mb2 mt3"}>
                        <button className={"cta full blue"} onClick={() => {
                            updateMatchStep(currentMatch, matchDeclineConfirmation)
                            setMatchDeclineConfirmation(null)
                        }}>Oui je suis sûr</button>
                        <button className={"cta full blue reverse"} onClick={() => setMatchDeclineConfirmation(null)}>Fermer</button>
                    </div>
                </div>
            </SlidingPanel>

            <SlidingPanel show={matchConfirmation !== null} handleClose={() => {
                setMatchConfirmation(null)
                window.location.reload()
            }}>
                <div>
                    <div className={"bloc_title_info"}>
                        {matchConfirmation === 1 && <>
                            <h2>Super ! 👌</h2>
                            <p>Votre proposition vient d'être envoyée.</p>
                        </>}
                        {matchConfirmation === 2 && <>
                            <h2>Offre de mission refusée</h2>
                        </>}
                        {matchConfirmation === 3 && <>
                            <h2>Bravo !</h2>
                            <p>Vous venez d'accepter de discuter avec ce candidat.</p>
                        </>}
                        {matchConfirmation === 4 && <>
                            <h2>Candidature non retenue.</h2>
                        </>}
                        {matchConfirmation === 5 && <>
                            <h2>Bravo !</h2>
                            <p>Vous venez de passer à l'accord.</p>
                        </>}
                    </div>
                    <div className={"text-center mb2 mt3"}>
                        <button className={"cta full blue"} onClick={() => {
                            setMatchConfirmation(null)
                            window.location.reload()
                        }}>Fermer</button>
                    </div>
                </div>
            </SlidingPanel>
        </Suspense>
    )
}

export default SingleMatch