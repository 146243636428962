import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ItemLabel from "../../other/ItemLabel";
import {AuthContext} from "../../userAuth/AuthContext";
import {
    STEP_AGREEMENT,
    STEP_DONE,
    STEP_PENDING,
    STEP_RECIPIENT_ACCEPTED,
    STEP_SENDER_ACCEPTED,
    STEP_SENDER_REFUSED
} from "../../other/Constant";
import {GetFullDateTime, GetHelpDate, GetHelpTime, truncateString} from "../../helper/other";
import AddNoteToHelp from "../../slidingContent/help/addNoteToHelp";
import SlidingPanel from "../_sliding_panel";
import ShowMoreText from "react-show-more-text";
import moment from "moment";
import {useSetState} from "react-use";
const HelpBlock = (props) => {

    const navigate = useNavigate()
    const {state} = useContext(AuthContext)
    const userIsOwner = props.owner || (props.user && props.user.id == state.user.id)
    const [status, setStatus] = useSetState({
        status : "...",
        class: ''
    })


    useEffect(() => {
        let maxLinkingStep = -1
        if (props.linkings) {
            props.linkings.forEach((l) => {
                if (l.step > 0 && l.step > maxLinkingStep) {
                    maxLinkingStep = l.step
                }
            })
        }

        switch(maxLinkingStep) {
            case STEP_PENDING :
                setStatus({status : "Nouveau match", class: ''})
                break;
            case STEP_RECIPIENT_ACCEPTED :
                setStatus({status : "Matching en attente", class: 'step-pending'})
                break;
            case STEP_SENDER_ACCEPTED :
                setStatus({status : "Mise en relation", class: 'step-accepted'})
                break;
            case STEP_SENDER_REFUSED :
                setStatus({status : userIsOwner ? "Pas intéressé" : "Recherche aide terminée", class: 'step-accepted'})
                break;
            case STEP_AGREEMENT :
                setStatus({status : "Accord", class: 'step-agreement'})
                break;
            case STEP_DONE :
                setStatus({status : "Terminée", class: 'step-done'})
                break;
            default :
                setStatus({completion : "", status : ""})
        }
    }, [])

    return (
        <div className={`help_block_item small ${userIsOwner ? "own" : ""}`} onClick={() => {
            props.link ? navigate(props.link) : navigate(`/aides/${props.id}`)
        }}>
            {/*{props.step && <div className={`step ${status.class}`}>{status.status}</div>}*/}
            <div className={'help_block_item_inner'}>
                <div className={"icon_circle"} />
                <div className={"content"}>
                    <div className={"title"}>{truncateString(props.title, 100)}</div>
                    <div className={"description"}>
                        <span className={"type"}>{props.type?.name}</span>
                        {props.location && <span className={"state"}>{props.location}</span>}
                    </div>
                    <div className={"description"}>
                        <span className={"type"}>{props.court?.name}</span>
                        {status.status !== "" && <span className={"state"}>{status.status}</span>}
                    </div>
                    {props.date && <div className={"description"}>
                        <span className={"reference type capitalize"}>{GetFullDateTime(props.date)}</span>
                    </div>}
                </div>
            </div>
            <span className={"date"}>{moment(props.createdOn).format('DD/MM/YYYY')}</span>
        </div>
    )
}

HelpBlock.defaultProps = {
    helpId: 0,
    title: "-",
    description: "-",
    linkings: [],
    createdOn: "1970-01-01 00:00:00",
}

export const HelpFullBlock = (props) => {

    const {state} = useContext(AuthContext)
    const userIsOwner = props.user && props.user.id == state.user.id

    const navigate = useNavigate()

    return (
        <div className={`help_block_item full ${userIsOwner ? "own" : ""} ${props.urgent ? "urgent" : ""}`}>
            {props.urgent && <span className={"urgent_label"}>Urgent</span>}

            <div className={"help_header"}>
                <div className={"icon_circle"} />
                {/*<ItemLabel code={props.type.code} type={props.type.name}/>*/}

                <div className={"content"}>
                    <div className={"header_title"}>
                        <div className={"title"}>{props.title}</div>
                        <div className={"subtitle"}>{props.type?.name}</div>
                    </div>
                    {props.createdOn && <div className={"date"}>{moment(props.createdOn).format('DD/MM/YYYY')}</div>}
                </div>
            </div>

            <div className={"description"}>
                <ShowMoreText
                    lines={2}
                    more="voir plus"
                    less="voir moins"
                    className="content-css"
                    anchorClass="show-more-less-clickable"
                    expanded={false}
                    width={380}
                    truncatedEndingComponent={"... "}
                >
                    {props.description}
                </ShowMoreText>
            </div>

            <div className={"help_info"}>
                <div>
                    <h5>Juridiction</h5>
                    <span>{props.court?.name}</span>
                </div>
                <div>
                    <h5>Lieu</h5>
                    <span>{props.location}</span>
                </div>
                <div>
                    <h5>Date</h5>
                    <span>{props.date ? GetHelpDate(props.date) : ''}</span>
                </div>
                <div>
                    <h5>Heure</h5>
                    <span>{GetHelpTime(props.date) ? GetHelpTime(props.date) : '-'}</span>
                </div>
            </div>

            {props.showActions && <div className={"help_cta_wrapper"}>
                {!userIsOwner &&
                    <>
                        {!props.linking && props.handleConfirm && <button className={"cta blue full"} onClick={() => props.handleConfirm(props.id)}>Proposer mon aide</button>}
                        {!props.linking && props.handleAddNote && <button className={"cta blue full reverse"} onClick={() => props.handleAddNote(props.id)}>Envoyer une note</button>}
                        {!props.linking && !props.handleConfirm && !props.handleAddNote && <div className={"cta full"}>Aide envoyée</div>}
                        {props.linking && <button className={"cta blue full"} onClick={() => {
                            navigate(`/matchs/aide/${props.linking.id}`)
                        }}>Afficher le match</button>}
                    </>
                }
                {userIsOwner &&
                    <>
                        <button className={`cta blue full ${props.linkings?.length == 0 ? "reverse" : ""}`} onClick={() => {
                            if (props.onClick) {
                                props.onClick()
                            } else {
                                navigate(`/aides/${props.id}`)
                            }
                        }}>{props.linkings?.length > 0 ? "Consulter mes matchs " : "Afficher mon aide"}</button>
                    </>
                }
            </div>}
        </div>

    )

}

HelpFullBlock.defaultProps = {
    helpId: 0,
    title: "-",
    description: "-",
    linkings: [],
    linking: null,
    createdOn: null,
}

export default HelpBlock