//dummy missions
import Api from "../helper/api";
import {
    PROFIL_TYPE_RECRUTEUR,
    STEP_AGREEMENT,
    STEP_DONE,
    STEP_PENDING,
    STEP_RECIPIENT_ACCEPTED, STEP_RECIPIENT_REFUSED,
    STEP_SENDER_ACCEPTED, STEP_SENDER_REFUSED
} from "../other/Constant";
import {useOutletContext} from "react-router-dom";
import {useContext} from "react";
import {AuthContext} from "../userAuth/AuthContext";

/**
 * Return all user missions list
 * @returns {Promise<{missions: Promise<*>}>}
 */
export const getMissions = (context) => {

    const api = new Api()

    if (context.state.user.profileType === PROFIL_TYPE_RECRUTEUR) {
        const missionsPromise = api.getUserMissions();
        const linkingsPromise = api.getMissionLinkings(null,[STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED, STEP_SENDER_REFUSED, STEP_AGREEMENT, STEP_DONE]);

        return {
            missions : missionsPromise,
            linkings: linkingsPromise
        }
    } else {
        const linkingsPromise = api.getMissionLinkings(null,[STEP_RECIPIENT_ACCEPTED, STEP_RECIPIENT_REFUSED, STEP_SENDER_ACCEPTED, STEP_SENDER_REFUSED, STEP_AGREEMENT, STEP_DONE]);

        return {
            linkings: linkingsPromise
        }
    }
}

/**
 * Return data for current mission
 * @param params - contain missionId
 */
export const getMission = async ({params}) => {

    const api = new Api()
    const missionPromise = api.getMission(params.missionId)

    const pendingMatchPromise = api.getMissionLinkings(params.missionId, [STEP_RECIPIENT_ACCEPTED])
    // const acceptedMatchPromise = api.getMissionLinkings(params.missionId, [STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED, STEP_AGREEMENT])
    const acceptedMatchPromise = api.getMissionLinkings(params.missionId, [STEP_RECIPIENT_REFUSED, STEP_SENDER_ACCEPTED, STEP_SENDER_REFUSED, STEP_AGREEMENT])

    return {
        missionPromise : missionPromise,
        pendingMatchPromise : pendingMatchPromise,
        acceptedMatchPromise : acceptedMatchPromise
    }
}

/**
 * Return Matchs linking List for a specific mission
 * @param params - contain missionId
 */
export const getMissionMatchs = async ({params}) => {

    const api = new Api()

    const matchsPromise = api.getMissionLinkings(params.missionId, [STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED, STEP_AGREEMENT, STEP_DONE])

    return {
        type : "mission",
        matchs : matchsPromise
    }
}

