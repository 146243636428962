import React, {useContext, useEffect, useState} from "react";
import {useSetState} from "react-use";
import {useForm} from "react-hook-form";
import {Link} from "react-router-dom";

import Api from "../../helper/api";
import {AuthContext} from "../../userAuth/AuthContext";

import {emailPattern} from "../../helper/formUtils";
import PhoneInput from "react-phone-input-2";
import MissionBlock from "../mission/_MissionBlock";
import {GenericEmptyItem} from "../../other/GenericBlock";
import SponsorshipBlock from "./_SponsorshipBlock";

const Sponsorship = () => {

    const [serverError, setServerError] = useState("")
    const [sponsorships, setSponsorships] = useState([])

    //user context
    const {login} = useContext(AuthContext);

    //API
    const api = new Api();

    const fetchData = async () => {
        setSponsorships(await api.getSponsorships())
    }

    //Load form data
    useEffect(() => {
        fetchData()
    }, [])

    //handle form
    const { register, formState: { errors }, watch, handleSubmit,setValue } = useForm();

    const handleFormSubmit = (data) => {
        const sponsorship = {
            "email" : data.mail,
            "lastname" : data.lastname,
            "firstname": data.firstname,
            "phone": data.phone,
        }
        createSponsorship(sponsorship)
    }
    const createSponsorship = async (data) => {
        setServerError("");

        await api.createSponsorship(data).then((res) => {
            fetchData()
        }).catch(error => {
            setServerError(error.message);
        })
    }

    return(
        <div id={"sponsorship_wrapper"} className={"profile-wrapper"}>
            <h1>Retrouvez ici tous vos parrainages</h1>
            <p className={"subtitle"}>Plus vous partagez EsterLaw, avec vos confrères ou collègues, plus vous gagnez ! Cumulez des récompenses vraiment attrayantes sous forme de bons d'achat ou réductions jusqu'à 1200 euros et ... au delà ! <a className={""} href="https://www.esterlaw.io/parrainage/" target="_blank">Découvrez notre programme</a></p>
            <div className={"sponsorship_box"}>
                <div className={"side-left"}>
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                        <div className={"form_row_wrapper type3"}>
                            <label htmlFor={"firstname"}>Prénom</label>
                            <input type={"text"} name={"firstname"} id={"firstname"} placeholder={"Prénom"} {...register("firstname", {required: "Veuillez renseigner le prénom"})}/>
                            <div className={"error"}>{errors.firstname && errors.firstname.message}</div>
                        </div>

                        <div className={"form_row_wrapper type3"}>
                            <label htmlFor={"name"}>Nom</label>
                            <input type={"text"} name={"lastname"} id={"lastname"} placeholder={"Nom"} {...register("lastname", {required: "Veuillez renseigner le nom"})}/>
                            <div className={"error"}>{errors.lastname && errors.lastname.message}</div>
                        </div>

                        <div className={"form_row_wrapper type3"}>
                            <label htmlFor={"mail"}>Email</label>
                            <input type={"email"} name={"mail"} id={"mail"} placeholder={"Adresse e-mail"} {...register("mail", {
                                required: "Veuillez renseigner une adresse e-mail",
                                pattern: {
                                    value: emailPattern,
                                    message: "Veuillez saisir une adresse e-mail valide"
                                }
                            })}
                            />
                            <div className={"error"}>{errors.mail && errors.mail.message}</div>
                        </div>

                        <div className={"form_row_wrapper type3"}>
                            <input type={"hidden"} name={"phone"} id={"phone"} {...register("phone", {required: "Veuillez renseigner le numéro de téléphone"})}/>

                            <PhoneInput
                                country={'fr'}
                                onChange={phone => setValue("phone", "+" + phone)}
                                placeholder={"602030405"}
                            />
                            <label htmlFor={"phone"}>Téléphone</label>
                            <div className={"error"}>{errors.phone && errors.phone.message}</div>
                        </div>

                        <div className={"error"}>
                            {serverError}
                        </div>

                        <div className={"cta_wrapper"}>
                            <input type={"submit"} className={"cta blue full"} value={"Parrainer"}/>
                        </div>
                    </form>
                </div>
                <div className={"side-right"}>
                    {sponsorships?.length > 0 && <div className={"sponsorship_list"}>
                        <h2>Vos parrainages</h2>
                        {sponsorships.map( (sponsorship, key) => <SponsorshipBlock {...sponsorship} key={`sponsorship_${sponsorship.id}`} />)}
                    </div>}
                    {sponsorships?.length === 0 && <GenericEmptyItem title={"Aucun parrainage en cours"}/>}
                </div>
            </div>
        </div>
    )
}

export default Sponsorship