import React, {useEffect, useState, Suspense} from "react";
import {Await, useLoaderData, useNavigate, useRevalidator} from "react-router-dom";

import {GenericEmptyItem, SingleMatchBlock} from "../../other/GenericBlock";
import SlidingPanel from "../_sliding_panel";
import SingleItemHeader from "../_singleItem/SingleItemHeader";
import SingleItemMatch from "../_singleItem/SingleItemMatch";
import SingleItemDetails from "../_singleItem/SingleItemDetails";
import SingleItemCollaborateur from "../_singleItem/SingleItemCollaborateur";
import SingleItemMessages from "../_singleItem/SingleItemMessages";
import Api from "../../helper/api";
import {CtaReturn} from "../../other/Cta";

const SingleMission = () => {

    //state
    const [mission, setMission] = useState([])

    const [showSlidingPanelDetails, setShowSlidingPanelDetails] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showEnd, setShowEnd] = useState(false);
    const [showStop, setShowStop] = useState(false);

    //loaders
    const {missionPromise, pendingMatchPromise, acceptedMatchPromise, allMatchPromise} = useLoaderData()

    //api
    const revalidator = useRevalidator()
    const navigate = useNavigate()
    const api = new Api()

    useEffect(() => {
        missionPromise.then((mission) => setMission(mission))
    })

    //set mission as ended
    const handleEndMission = () => {
        api.updateMission(mission.id,{closed : true}).then(() => {
            revalidator.revalidate()
            setShowEnd(false)
        })
    }

    //delete mission
    const handleDeleteMission = () => {
        api.deleteMission(mission.id).then(() => {
            setShowDelete(false)
            navigate("/missions/")
        })
    }

    //stop matching
    const handleStopMatching = () => {
        api.updateMission(mission.id,{linkingOpened : false}).then(() => {
            revalidator.revalidate()
            setShowStop(false)
        })
    }

    const MissionActionBlock = () => {
        return (
            <div id={"single_mission_cta"}>
                {!mission.closed &&
                    <>
                        <button className={"cta blue full reverse"} onClick={() => setShowEnd(true)}>Merci de nous indiquer que la mission est finalisée avec succès</button>
                        <button className={"cta full"} onClick={() => setShowDelete(true)}>Supprimer</button>
                    </>
                }
                {mission.closed &&
                    <button className={"cta transparent full"}>Cette mission est terminée</button>
                }
            </div>
        )
    }

    return(
        <div className={"single_item_wrapper"} id={"single_mission_wrapper"}>
            <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                <Await resolve={missionPromise}>
                    <div className={"side-left d-mobile-none"}>
                        <div className={"single_item_header single_mission_header"}>
                            <div className={"cta_wrapper"}>
                                <CtaReturn/>
                            </div>
                            {/*<SingleItemHeader singleItem={mission} setShowSlidingPanelDetails={setShowSlidingPanelDetails}/>*/}
                            <SingleItemDetails singleItem={mission} detail={false} />
                        </div>
                        <Await resolve={acceptedMatchPromise}>
                            {(acceptedMatchs) => <SingleItemMessages type={"mission"} linkings={acceptedMatchs.filter(obj => obj.step >= 1 && obj.userAcceptedOn)}/>}
                        </Await>
                    </div>
                    <div className={"side-right d-mobile-none"}>
                        <Await resolve={pendingMatchPromise}>
                            {(pendingMatchs) => <SingleItemMatch pendingMatchs={pendingMatchs.length} itemId={mission.id} type={"mission"}/>}
                        </Await>
                        <Await resolve={acceptedMatchPromise}>
                            {(acceptedMatchs) => <SingleItemCollaborateur type={"mission"} linkings={acceptedMatchs.filter(obj => obj.step >= 1 && obj.userAcceptedOn)} showStopMatchingCta={mission.linkingOpened && !mission.closed} stopMatching={() => setShowStop(true)}/>}
                        </Await>
                        <MissionActionBlock />
                    </div>

                    <div className={"d-desktop-none"}>
                        <SingleItemHeader singleItem={mission} setShowSlidingPanelDetails={setShowSlidingPanelDetails}/>

                        <Await resolve={pendingMatchPromise}>
                            {(pendingMatchs) => <SingleItemMatch pendingMatchs={pendingMatchs.length} itemId={mission.id} type={"mission"}/>}
                        </Await>

                        <Await resolve={acceptedMatchPromise}>
                            {(acceptedMatchs) => {
                                return <>
                                    <SingleItemCollaborateur type={"mission"} linkings={acceptedMatchs.filter(obj => obj.step >= 1 && obj.userAcceptedOn)} showStopMatchingCta={mission.linkingOpened && !mission.closed} stopMatching={() => setShowStop(true)} />
                                    <SingleItemMessages type={"mission"} linkings={acceptedMatchs.filter(obj => obj.step >= 1 && obj.userAcceptedOn)}/>
                            </>}}
                        </Await>

                        <MissionActionBlock />

                        <SlidingPanel show={showSlidingPanelDetails} handleClose={() => setShowSlidingPanelDetails(false)} forceSliding={true}>
                            <SingleItemDetails singleItem={mission} detail={true}/>
                        </SlidingPanel>
                    </div>

                    <SlidingPanel show={showDelete} handleClose={() => setShowDelete(false)}>
                        <div>
                            <div className={"bloc_title_info"}>
                                <h2>Suppression</h2>
                                <p>Supprimer cette mission ?</p>
                            </div>
                            <div className={"text-center mb2 mt3"}>
                                <button className={"cta full blue"} onClick={() => handleDeleteMission()}>Supprimer</button>
                                <button className={"cta full blue reverse"} onClick={() => setShowDelete(false)}>Annuler</button>
                            </div>
                        </div>
                    </SlidingPanel>
                    <SlidingPanel show={showEnd} handleClose={() => setShowEnd(false)}>
                        <div>
                            <div className={"bloc_title_info"}>
                                <h2>Mission terminée</h2>
                                <p>Terminer cette mission ?</p>
                            </div>
                            <div className={"text-center mb2 mt3"}>
                                <button className={"cta full blue"} onClick={() => handleEndMission()}>Terminer</button>
                                <button className={"cta full blue reverse"} onClick={() => setShowEnd(false)}>Annuler</button>
                            </div>
                        </div>
                    </SlidingPanel>
                    <SlidingPanel show={showStop} handleClose={() => setShowStop(false)}>
                        <div>
                            <div className={"bloc_title_info"}>
                                <h2>J'ai trouvé mon talent idéal</h2>
                                <p>Ne plus recevoir de talent supplémentaire ?</p>
                            </div>
                            <div className={"text-center mb2 mt3"}>
                                <button className={"cta full blue"} onClick={() => handleStopMatching()}>Stop</button>
                                <button className={"cta full blue reverse"} onClick={() => setShowStop(false)}>Annuler</button>
                            </div>
                        </div>
                    </SlidingPanel>
                </Await>
            </Suspense>
        </div>
    )
}

export default SingleMission