import React, {useCallback, useEffect, useState} from "react";
import MultiSelect from "./multiSelect";
import {useForm} from "react-hook-form";
import Api from "../helper/api";
import {useSetState} from "react-use";
import {getItemFromArrayById} from "../helper/formUtils";
import {useDidUpdate} from "../helper/hook";

const FormSkill = ({user, parentFormData, onFormSubmit, onFormCancel, skill, description}) => {

    //react hook form
    const {register,formState: { errors }, trigger, handleSubmit, reset, getValues, setValue, watch, resetField} = useForm();

    //api
    const api = new Api();

    //handle multiselect
    const [countries, setCountries] = useState([])
    const [subareas, setSubAreas] = useState([])

    //Form State
    const [formData, setFormData] = useSetState({
        countryList : [],
        skillsList : [],
        subareas : [],
        experienceYear : [],
        updateUserField : false
    })

    //OnComponentDidMount -> Fetch form Data
    const fetchFormData = useCallback(async () => {
        const countryListData = await api.getCountryList();
        const skillsListData = await api.getAreasList();
        const experienceYear = await api.getExperienceYearList();

        setFormData({
            countryList: countryListData,
            skillsList : skillsListData,
            experienceYear : experienceYear,
            updateUserField : true
        })
    })

    useEffect( () => {
        fetchFormData()
    }, [])

    //Set user or item to edit values
    useEffect(() => {

        if (!formData.updateUserField) {
            return
        }

        //set user data
        if (skill === null) {
            if (user) {
                setCountries(user.countries)
                // if (parentFormData && parentFormData.)
                // console.log(getValues(""))
                // console.log(parentFormData, 'parentFormData')
                // setValue("experience", parentFormData?.id)
                setValue("experience", user.experience?.id)
            }
        } else { // set item data
            setValue("area", skill.area.id)
            setCountries(skill.countries)
            setSubAreas(skill.subareas)
            setValue("description", skill.description)
            setValue("experience", skill.experience.id)
        }

        setFormData({
            updateUserField : false
        })

    },[formData.updateUserField])

    // useEffect(() => {
    //     // if (skill === null) {
    //         // setCountries(user.countries)
    //         console.log(parentFormData, 'parentFormData')
    //         setValue("experience", parentFormData?.id)
    //         // setValue("experience", user.experience?.id)
    //     // }
    // },[parentFormData])

    //handle area change
    useEffect(() => {

        const areaId = watch("area") !== "" ? parseInt(watch("area")) : undefined
        if(areaId === undefined) {
            return
        }

        // const areaId = formData.skillsList[areaIndex].id

        //if in edit mode don't empty the list
        if(skill === null || skill.area.id !== areaId ){
            //empty prev choice
            setSubAreas([])
        }

        //get subarea from area list
        const subAreaList = formData.skillsList.filter((o) => o.id === areaId)

        setFormData({
            subareas : subAreaList.length ? subAreaList[0].subareas : []
        })

    },[watch("area")])

    //form cancel
    const handleCancel = () => {
        resetField("area")
        resetField("experience")

        setCountries([])
        setSubAreas([])

        onFormCancel()
    }

    //form submit
    const handleFormSubmit = async () => {

        const is_valid = await trigger()

        if(is_valid){
            const data = getValues()

            data.area = getItemFromArrayById(data.area, formData.skillsList)
            data.experience = getItemFromArrayById(data.experience, formData.experienceYear)
            data.countries = countries
            data.subareas = subareas

            onFormSubmit(data)
        }

    }

    //update component on specialization prop change
    useDidUpdate(() => {
        setFormData({ updateUserField : true })
    },[skill])

    return(
        <div className={"form"}>

            <div className={"form_row_wrapper type3"}>
                <label>Compétences</label>
                <select name={"area"} id={"area"} defaultValue={""} {...register("area",{
                    required : "Veuillez renseigner cette information"
                })}>
                    <option value={""} disabled={true}>Compétence</option>
                    {formData.skillsList.map((c,index) => {
                        return(<option value={c.id} key={index}>{c.name}</option>)
                    })}
                </select>
                <div className="error">{errors.area && errors.area.message}</div>
            </div>

            <div className={"form_row_wrapper type3"}>
                <label>Expérience</label>
                <select name={"experience"} id={"experience"} defaultValue={""} {...register("experience",{
                    required : "Veuillez renseigner cette information"
                })}>
                    <option value={""} disabled={true}>Expérience</option>
                    {formData.experienceYear.map((c,index) => {
                        return(<option value={c.id} key={index}>{c.name}</option>)
                    })}
                </select>
                <div className="error">{errors.experience && errors.experience.message}</div>
            </div>

            <div className={"form_row_wrapper type3 multiselect"}>
                <label>Expertise international</label>
                <MultiSelect name={"expertise"} id={"expertise"} placeholder="Sélectionner" options={ formData.countryList.map(item => ({ label: item.name, value: item.id }))} isMulti={true} closeMenuOnSelect={false} defaultValues={countries} getValues={setCountries}/>
                <div className="error">{errors.expertise && errors.expertise.message}</div>
            </div>


            {description && <div className={"form_row_wrapper type3"}>
                <label>Description</label>
                <textarea name={"description"}{...register("description")}></textarea>
                {/*<div className="error">{errors.description && errors.description.message}</div>*/}
            </div>}

            {formData.subareas.length !== 0 &&
                <div className={"form_row_wrapper type3 multiselect"}>
                    <label>Sous-domaine</label>
                    <MultiSelect name={"subarea"} id={"subarea"} placeholder="Sélectionner" options={ formData.subareas.map(item => ({ label: item.name, value: item.id }))} isMulti={true} closeMenuOnSelect={false} defaultValues={subareas} getValues={setSubAreas}/>
                </div>
            }


            <div className={"cta_wrapper text-right"}>
                <button className={"cta full blue"} onClick={handleSubmit(handleFormSubmit)}>
                    {skill === null && <>Ajouter une compétence</>}
                    {skill !== null && <>Valider les modifications</>}
                </button>
                <a className={"cta full blue reverse"} onClick={handleCancel}>Annuler</a>
            </div>
        </div>
    )
}

FormSkill.defaultProps = {
    user: null,
    onFormSubmit : (data) => console.log("onFormSubmit is not defined -> ",data),
    handleCancel : () => console.log("handleCancel is not defined"),
    skill : null,
}

export default FormSkill