import React, {Suspense, useEffect, useState} from "react";

import {Await, useLoaderData, useNavigate, useOutletContext} from "react-router-dom";

import {Link} from "react-router-dom";
import {GenericEmptyItem, SingleMatchBlock} from "../other/GenericBlock";
import {useSetState} from "react-use";
import {filterDuplicateHelpInLinkings, filterDuplicateSwitchDutyInLinkings} from "../helper/missionHelper";
import * as Constant from "../other/Constant";
import {PROFIL_TYPE_CANDIDAT, PROFIL_TYPE_RECRUTEUR} from "../other/Constant";
import ItemLabel from "../other/ItemLabel";
import MissionBlock from "./mission/_MissionBlock";
import HelpBlock from "./help/_HelpBlock";
import SwitchBlock from "./switch/_SwitchBlock";
import Conversations from "./message/Conversations";
import {isMobile} from "react-device-detect";

/**
 * Dashboard Data block with title and content
 * @param title
 * @param moreLink
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const DashboardBlock = ({title, moreLink, children}) => {
    return(
        <div className={"dashboard_block_wrapper"}>
            {moreLink && <Link className={"dashboard_block_more_link"} to={moreLink}>Tout voir</Link>}
            {title && <h2>{title}</h2>}
            {children}
        </div>
    )
}

/**
 * Dashboard Data statistics
 * @param title
 * @param promise
 * @param value
 * @returns {JSX.Element}
 * @constructor
 */
const DashboardStatBlock = ({title, promises, value = null, link}) => {
    const navigate = useNavigate()

    return(
        <div className={"dashboard_item_stat"} onClick={() => navigate(link)}>
            <div>
                <Suspense fallback={"..."}>
                    <Await resolve={Promise.all(promises)}>
                        {(promise) => {
                            let val = 0
                            if (value === 2) {
                                const nbMatchMission = promise[0].filter(obj => obj.step >= 5).length
                                const nbMatchHelp = promise[1].filter(obj => obj.step >= 5).length
                                const nbMatchSwitch = promise[2].filter(obj => obj.step >= 5).length
                                val = nbMatchMission + nbMatchHelp + nbMatchSwitch
                            } else if (value === 1) {
                                const nbMatchMission = promise[0].filter(obj => obj.step == 3).length
                                const nbMatchHelp = promise[1].filter(obj => obj.step == 3).length
                                const nbMatchSwitch = promise[2].filter(obj => obj.step == 3).length
                                val = nbMatchMission + nbMatchHelp + nbMatchSwitch
                            } else {
                                const nbMatchMission = promise[0].filter(obj => obj.step >= 0).length
                                const nbMatchHelp = promise[1].filter(obj => obj.step >= 0).length
                                const nbMatchSwitch = promise[2].filter(obj => obj.step >= 0).length
                                val = nbMatchMission + nbMatchHelp + nbMatchSwitch
                            }
                            return val
                        }}
                    </Await>
                </Suspense>
            </div>
            <span>{title}</span>
        </div>
    )
}

const Dashboard = () => {

    //get loader data
    //const { missions, helps, switchs, pendingMatchs, pendingHelpMatchs, pendingSwitchMatchs } = useLoaderData()
    const loaderPromises = useLoaderData()
    const {user} = useOutletContext();
    const navigate = useNavigate()

    const [missionsState, setMissionsState] = useSetState()
    const [matchState, setMatchState] = useState(0)

    // useEffect(() => {
    //
    //     if(user.profileType === PROFIL_TYPE_RECRUTEUR){
    //         //format data for profil type Recruteur
    //
    //         //missions datas
    //         Promise.all([loaderPromises.missions.active, loaderPromises.missions.closed]).then((missionsPromise) =>{
    //             const [active, closed] = missionsPromise
    //
    //             setMissionsState({
    //                 active : active,
    //                 closed : closed
    //             })
    //         })
    //     } else {
    //         //format data for profile type candidat
    //
    //         //missions datas
    //         Promise.all([loaderPromises.missions.active, loaderPromises.missions.agreement]).then((missionsPromise) =>{
    //             const [active, agreement] = missionsPromise
    //
    //             setMissionsState({
    //                 active : active,
    //                 agreement : agreement
    //             })
    //         })
    //     }
    // }, [loaderPromises]);

    return(
        <div className={"dashboard_page_wrapper"} data-profil={user?.profileType}>
            <div className={"dashboard-left"}>
                <div className={"dashboard-stats-wrapper"}>
                    {user.profileType === Constant.PROFIL_TYPE_RECRUTEUR &&
                        <div className={"dashboard_block_wrapper dashboard-stats-numbers"}>
                            <div className={"bloc_title_info"}>
                                <h2>Bonjour {user.firstname} !</h2>
                                <div className={"bloc_subtitle_info"}>
                                    <p className={"user_profile_type"}>Je publie des missions</p>
                                    <p className={"subtitle"}>Retrouvez ici une vue d’ensemble de votre activité</p>
                                </div>
                            </div>

                            <div id={"dashboard_stat_block"}>
                                {/*<DashboardStatBlock title={"Mes matchs"} promise={loaderPromises.allMatchsPromise} link={'/matchs/'} />*/}
                                {/*<DashboardStatBlock title={"Mises en relation"} promise={loaderPromises.acceptedMatchPromise} link={'/missions/'}/>*/}
                                {/*<DashboardStatBlock title={"Mes accords"} promise={loaderPromises.agreementMatchPromise} link={'/missions/'}/>*/}
                                <DashboardStatBlock title={"Mes matchs"} promises={[loaderPromises.matchsMission, loaderPromises.matchsHelp, loaderPromises.matchsSwitch]} value={0} link={'/matchs/'} />
                                <DashboardStatBlock title={"Mises en relation"} promises={[loaderPromises.matchsMission, loaderPromises.matchsHelp, loaderPromises.matchsSwitch]} value={1} link={'/missions/'}/>
                                <DashboardStatBlock title={"Mes accords"} promises={[loaderPromises.matchsMission, loaderPromises.matchsHelp, loaderPromises.matchsSwitch]} value={2} link={'/missions/'}/>
                            </div>
                        </div>
                    }

                    {user.profileType === Constant.PROFIL_TYPE_CANDIDAT &&
                        <div className={"dashboard_block_wrapper dashboard-stats-numbers"}>
                            <div className={"bloc_title_info"}>
                                <h2>Bonjour {user.firstname} !</h2>
                                <div className={"bloc_subtitle_info"}>
                                    <p className={"user_profile_type"}>Je propose mes compétences</p>
                                    <p className={"subtitle"}>Retrouvez ici une vue d’ensemble de votre activité</p>
                                </div>
                            </div>

                            <div id={"dashboard_stat_block"}>
                                {/*<DashboardStatBlock title={"Mes matchs"} promise={loaderPromises.allMatchsPromise} link={'/matchs/'} />*/}
                                {/*<DashboardStatBlock title={"Mises en relation"} promise={loaderPromises.discussMatchPromise} link={'/missions/'} />*/}
                                {/*<DashboardStatBlock title={"Mes accords"} promise={loaderPromises.agreementMatchPromise} link={'/missions/'} />*/}
                                <DashboardStatBlock title={"Mes matchs"} promises={[loaderPromises.matchsMission, loaderPromises.matchsHelp, loaderPromises.matchsSwitch]} value={0} link={'/matchs/'} />
                                <DashboardStatBlock title={"Mises en relation"} promises={[loaderPromises.matchsMission, loaderPromises.matchsHelp, loaderPromises.matchsSwitch]} value={1} link={'/missions/'} />
                                <DashboardStatBlock title={"Mes accords"} promises={[loaderPromises.matchsMission, loaderPromises.matchsHelp, loaderPromises.matchsSwitch]} value={2} link={'/missions/'} />
                            </div>
                        </div>
                    }

                    <DashboardBlock title={"Matchs"} moreLink={"/matchs"}>
                        <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                            <Await resolve={Promise.all([loaderPromises.matchsMission, loaderPromises.helps, loaderPromises.switchs, loaderPromises.helpsMe, loaderPromises.switchsMe])} errorElement={<p>Error loading Data !</p>}>
                                {(linkings) => {
                                    const missionLinkings = user.profileType === PROFIL_TYPE_RECRUTEUR ? linkings[0].filter(obj => obj.step === 1) : linkings[0].filter(obj => obj.step === 0)
                                    // const missionLinkings = user.profileType === PROFIL_TYPE_RECRUTEUR ? linkings[0].filter(obj => obj.step === 1) : linkings[0].filter(obj => obj.step === 0)
                                    const helpLinkings = linkings[1]
                                    const switchLinkings = linkings[2]
                                    const helpLinkingsMe = linkings[3]
                                    const switchLinkingsMe = linkings[4]
                                    // const allNewLinking = [...missionLinkings]
                                    const allNewLinking = [...missionLinkings, ...helpLinkingsMe, ...switchLinkingsMe]
                                    // const allNewLinking = [...missionLinkings, ...helpLinkingsMe?.filter(obj => obj.step === 1), ...switchLinkingsMe?.filter(obj => obj.step === 1)]
                                    // const allNewLinking = missionLinkings.length + (helpLinkings && helpLinkings.length > 0 ? helpLinkings?.filter(obj => obj.step === 1).length : 0) + (switchLinkings && switchLinkings.length > 0 ? switchLinkings?.filter(obj => obj.step === 1).length : 0)
                                    if (allNewLinking.length > 0) {
                                        return (allNewLinking.slice(0, 5).map((linking, key) => {
                                            if (linking.help) {
                                                return <HelpBlock {...linking.help} link={`/matchs/aide/${linking.id}`} key={key} step={linking.step} linkings={[linking]} owner={true} />
                                                // return <HelpBlock {...linking.help} link={linking.help?.user?.id === user.id ? `/aides/${linking.help.id}` : `/matchs/aide/${linking.id}`} key={key} step={linking.step} linkings={[linking]} owner={true} />
                                            } else if (linking.switchDuty) {
                                                return <SwitchBlock {...linking.switchDuty} link={`/matchs/echange/${linking.id}`} key={key} step={linking.step} linkings={[linking]} owner={true} />
                                                // return <SwitchBlock {...linking.switchDuty} link={linking.switchDuty?.user?.id === user.id ? `/echanges/${linking.switchDuty.id}` : `/matchs/echange/${linking.id}`} key={key} step={linking.step} linkings={[linking]} owner={true} />
                                            }
                                            return <MissionBlock {...linking.mission} linking={linking} key={key} link={user.profileType === PROFIL_TYPE_RECRUTEUR ? `/missions/${linking.mission.id}/matchs` : `/matchs/mission/${linking.id}`}/>
                                        }))
                                        // return <SingleMatchBlock count={allNewLinking} link={"/matchs"} user={user} />
                                    } else {
                                        return <GenericEmptyItem title={"Bientôt vos prochains matchs"}/>
                                    }
                                }}
                            </Await>
                        </Suspense>
                    </DashboardBlock>
                </div>

                <DashboardBlock title={"Missions"} moreLink={"/missions"}>
                    <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                        <Await resolve={Promise.all([loaderPromises.missions.active, loaderPromises.matchsMission])} errorElement={<p>Error loading Data !</p>} >
                            {(promise) => {
                                // let linkings = [...promise[1]]
                                let linkings = promise[1]
                                // if (promise[2]) {
                                //     linkings = [...promise[1], ...promise[2]]
                                // }

                                if (user.profileType === PROFIL_TYPE_RECRUTEUR && promise[0].length) {
                                    const missions = promise[0]
                                    return (missions.slice(0, 5).map((mission, key) => {
                                        let linkings = mission.linkings?.filter(obj => obj.step === 5)
                                        if (linkings && !linkings.length) {
                                            linkings = mission.linkings?.filter(obj => obj.step === 3)
                                            if (linkings && !linkings.length) {
                                                linkings = mission.linkings?.filter(obj => obj.step === 1)
                                            }
                                        }
                                        let linking = null
                                        if (linkings) {
                                            const sortedLinkings = linkings.sort((a, b) => {
                                                return b.rate - a.rate
                                            })
                                            if (sortedLinkings.length) {
                                                linking = sortedLinkings[0]
                                            }
                                        }
                                        return <MissionBlock {...mission} linking={linking} key={key} />
                                    }))
                                }else if (user.profileType === PROFIL_TYPE_CANDIDAT && linkings.length) {
                                    // console.log(linkings.filter(obj => obj.step >= 1), 'MY LINKINGSA 222')
                                    return (linkings.filter(obj => obj.step >= 1).slice(0, 5).map((linking, key) => <MissionBlock {...linking.mission} linking={linking} key={key} link={`/matchs/mission/${linking.id}`}/>))
                                } else {
                                    return (<GenericEmptyItem title={"Bientôt vos prochaines missions"}/>)
                                }
                            }}
                        </Await>
                    </Suspense>
                </DashboardBlock>

                <DashboardBlock title={"Aides"} moreLink={"/aides"}>
                    <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                        <Await resolve={Promise.all([loaderPromises.helps, loaderPromises.myHelps])} errorElement={<p>Error loading Data !</p>} >
                            {(helps) => {
                                //array 1 -> help linkings
                                //array 2 -> my helps
                                //order help linkings by step value DESC AND remove duplicate help in linkings
                                const sortedAllHelps = filterDuplicateHelpInLinkings(helps[1], helps[0])

                                if (sortedAllHelps.length > 0) {
                                    return sortedAllHelps.slice(0, 5).map((obj, key) => (<HelpBlock {...obj.help} key={key} link={obj.help?.user?.id === user.id ? `/aides/${obj.help.id}` : `/matchs/aide/${obj.linking.id}`} />))
                                } else {
                                    return <GenericEmptyItem title={"Bientôt vos prochaines aides"}/>
                                }
                            }}
                        </Await>
                    </Suspense>
                </DashboardBlock>

                <DashboardBlock title={"Échanges"} moreLink={"/echanges"}>
                    <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                        <Await resolve={Promise.all([loaderPromises.switchs, loaderPromises.mySwitchs])} errorElement={<p>Error loading Data !</p>} >
                            {(switchs) => {
                                const sortedAllSwitchs = filterDuplicateSwitchDutyInLinkings(switchs[1], switchs[0])

                                if (sortedAllSwitchs.length > 0) {
                                    return sortedAllSwitchs.slice(0, 5).map((obj, key) => <SwitchBlock {...obj.switchDuty} key={key} link={obj.switchDuty?.user?.id === user.id ? `/echanges/${obj.switchDuty.id}` : `/matchs/echange/${obj.linking.id}`}/>)
                                } else {
                                    return <GenericEmptyItem title={"Bientôt vos prochains échanges"}/>
                                }
                            }}
                        </Await>
                    </Suspense>
                </DashboardBlock>
            </div>
            {!isMobile && <div className={"dashboard-right"}>
                <DashboardBlock title={"Profil"}>
                    <div className={"user_picture"}>
                        <div className={"picture"}>
                            {/*{!user.imageName && <div>{user.firstname.charAt(0)}{user.lastname.charAt(0)}</div>}*/}
                            {user?.imageName && <img src={process.env.REACT_APP_API_URL+"/"+user.imageName}/> }
                        </div>
                        <div className={"name"}>{user?.firstname} {user?.lastname}</div>
                        <Link to={"/profile"} className={"cta full blue reverse"}>Voir profil</Link>
                    </div>
                    {user.profileType === PROFIL_TYPE_RECRUTEUR && <button className={"cta full blue"} onClick={() => {
                        if (!user?.subscription) {
                            navigate('/abonnements')
                        } else {
                            navigate('/missions?create')
                        }
                    }}>Publier une mission</button>}
                </DashboardBlock>
                <DashboardBlock>
                    <Conversations limit={5} />
                </DashboardBlock>
            </div>}
        </div>
    )
}

export default Dashboard
