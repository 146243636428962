import React, {useEffect, useState, Suspense} from "react";
import {Await, useLoaderData, useNavigate, useOutletContext, useRevalidator} from "react-router-dom";

import {GenericEmptyItem} from "../../other/GenericBlock";
import SlidingPanel from "../_sliding_panel";
import SingleItemHeader, {OtherSingleItemHeader} from "../_singleItem/SingleItemHeader";
import SingleItemMatch from "../_singleItem/SingleItemMatch";
import SingleItemDetails from "../_singleItem/SingleItemDetails";
import {CtaReturn} from "../../other/Cta";
import SingleItemMessages from "../_singleItem/SingleItemMessages";
import SingleHelpItemDetails from "../_singleItem/SingleHelpItemDetails";
import Api from "../../helper/api";
import SingleItemCollaborateur from "../_singleItem/SingleItemCollaborateur";

const SingleHelp = () => {

    //state
    const [help, setHelp] = useState([])
    const [userHelp, setUserHelp] = useState(false)
    const [pendingMatchs, setPendingMatchs] = useState(0)
    const [acceptedMatchs, setAcceptedMatchs] = useState(0)
    const [showDelete, setShowDelete] = useState(false);
    const [showEnd, setShowEnd] = useState(false);

    //filter
    const [showSlidingPanelDetails, setShowSlidingPanelDetails] = useState(false);

    //loaders
    const {helpPromise} = useLoaderData()

    const revalidator = useRevalidator()
    const navigate = useNavigate()
    const api = new Api()

    //context
    const {user} = useOutletContext()

    useEffect(() => {

        helpPromise.then((help) => {
            //help is current user help
            setUserHelp(user.id === help.user.id)
            setHelp(help)

            if (userHelp) {
                let pendingMatchCount = 0
                let acceptedMatchCount = 0

                help.linkings?.forEach((l) => {

                    if (l.step === 1) {
                        pendingMatchCount++
                    } else if(l.step === 3) {
                        acceptedMatchCount++
                    }
                })

                setPendingMatchs(pendingMatchCount)
                setAcceptedMatchs(acceptedMatchCount)
            }
        })
    })

    //set help as ended
    const handleEndHelp = () => {
        if (userHelp) {
            api.updateHelp(help.id,{closed : true}).then(() => {
                revalidator.revalidate()
                setShowEnd(false)
            })
        }
    }

    //delete help
    const handleDeleteHelp = () => {
        if (userHelp) {
            api.deleteHelp(help.id).then(() => {
                setShowDelete(false)
                navigate("/aides")
            })
        }
    }

    const HelpActionBlock = () => {
        return (
            <div id={"single_help_cta"} className={"single_item_cta_wrapper"}>
                {!help.closed && <>
                    <button className={"cta blue full"} onClick={() => setShowEnd(true)}>Clôturer cette aide</button>
                    <button className={"cta full"} onClick={() => setShowDelete(true)}>Supprimer</button>
                </>}
                {help.closed && <button className={"cta transparent full"}>Cette aide est terminée</button>}
            </div>
        )
    }

    return(
        <div className={"single_item_wrapper"} id={"single_help_wrapper"}>
            <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                <Await resolve={helpPromise}>
                    <div className={"side-left d-mobile-none"}>
                        <div className={"single_item_header single_mission_header"}>
                            <div className={"cta_wrapper"}>
                                <CtaReturn/>
                                {help.urgent && <span className={"urgent_label"}>Urgent</span>}
                            </div>
                            <SingleHelpItemDetails singleItem={help} detail={false}/>
                        </div>
                        {userHelp && <SingleItemMessages type={"help"} linkings={help.linkings} />}
                    </div>
                    {userHelp && <div className={"side-right d-mobile-none"}>
                        <SingleItemMatch pendingMatchs={pendingMatchs} acceptedMatchs={acceptedMatchs} itemId={help.id} type={"help"}/>

                        <SingleItemCollaborateur type={"help"} linkings={help.linkings?.filter(obj => obj.step >= 2)} />

                        <HelpActionBlock />
                    </div>}
                    <div className={"d-desktop-none"}>
                        <SingleItemHeader singleItem={help} setShowSlidingPanelDetails={setShowSlidingPanelDetails}/>
                        {userHelp && <SingleItemMatch pendingMatchs={pendingMatchs} acceptedMatchs={acceptedMatchs} itemId={help.id} type={"help"}/>}
                        {userHelp && <SingleItemCollaborateur type={"help"} linkings={help.linkings?.filter(obj => obj.step >= 2)} />}

                        {userHelp && <HelpActionBlock />}

                        <SlidingPanel show={showSlidingPanelDetails} handleClose={() => setShowSlidingPanelDetails(false)} forceSliding={true}>
                            <SingleItemDetails singleItem={help}/>
                        </SlidingPanel>
                    </div>

                    {userHelp && <SlidingPanel show={showDelete} handleClose={() => setShowDelete(false)}>
                        <div>
                            <div className={"bloc_title_info"}>
                                <h2>Suppression</h2>
                                <p>Supprimer cette aide ?</p>
                            </div>
                            <div className={"text-center mb2 mt3"}>
                                <button className={"cta full blue"} onClick={() => handleDeleteHelp()}>Supprimer</button>
                                <button className={"cta full blue reverse"} onClick={() => setShowDelete(false)}>Annuler</button>
                            </div>
                        </div>
                    </SlidingPanel>}
                    {userHelp && <SlidingPanel show={showEnd} handleClose={() => setShowEnd(false)}>
                        <div>
                            <div className={"bloc_title_info"}>
                                <h2>Aide terminée</h2>
                                <p>Terminer cette aide ?</p>
                            </div>
                            <div className={"text-center mb2 mt3"}>
                                <button className={"cta full blue"} onClick={() => handleEndHelp()}>Terminer</button>
                                <button className={"cta full blue reverse"} onClick={() => setShowEnd(false)}>Annuler</button>
                            </div>
                        </div>
                    </SlidingPanel>}
                </Await>
            </Suspense>
        </div>
    )
}

export default SingleHelp