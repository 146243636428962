// VideoCall.js
import React, { useEffect, useRef, useState } from 'react';
import Video from 'twilio-video';
import {Link, useNavigate, useParams} from "react-router-dom";
import Api from "../../helper/api";

const VideoChat = () => {

    const [room, setRoom] = useState(null);
    const localVideoRef = useRef();
    const remoteVideoRef = useRef();
    const navigate = useNavigate()
    const [mute, setMute] = useState(false)
    const [cameraOff, setCameraOff] = useState(false)
    const api = new Api();
    const {conversationId, type} = useParams()
    const [isConnected, setIsConnected] = useState(false);
    const [participantOnline, setParticipantOnline] = useState(false);
    const [showRemoteOff, setShowRemoteOff] = useState(false)

    const onEndCall = () => {
        if (room) {
            room.disconnect();
        }
        navigate(-1)
    }

    const onMute = () => {
        if (room) {
            const localParticipant = room.localParticipant;
            const audioTrack = localParticipant.audioTracks.values().next().value;

            if (audioTrack) {
                setMute(!mute)

                if (mute) {
                    audioTrack.track.enable();
                } else {
                    audioTrack.track.disable();
                }
            }
        }
    }

    const handleCamera = async () => {
        if (room) {
            const localParticipant = room.localParticipant;
            const videoTrack = localParticipant.videoTracks.values().next().value;

            if (videoTrack) {
                setCameraOff(!cameraOff)

                if (cameraOff) {
                    // const localVideoTrack = await Video.createLocalVideoTrack();
                    // if (localVideoTrack) {
                    //     room.localParticipant.publishTrack(localVideoTrack);
                    // }
                    videoTrack.track.enable()
                } else {
                    videoTrack.track.disable()
                    // const localVideoTrack = room.localParticipant.videoTracks.values().next().value;
                    // if (localVideoTrack) {
                    //     localVideoTrack.track.stop();
                    //     // localVideoTrack.unpublish();
                    //     room.localParticipant.unpublishTrack(localVideoTrack);
                    //     // localVideoTrack.track.stop();
                    //     // localVideoTrack.track.detach().forEach((element) => element.remove());
                    // }
                }
            }
        }
    };

    const initVideoRoom = async () => {
        const data = await api.getVisioToken(conversationId)

        if (data?.token) {
            Video.connect(data.token).then((room) => {
                setRoom(room);
                setIsConnected(true);

                room.localParticipant.videoTracks.forEach(trackPub => {
                    if (trackPub.track) {
                        localVideoRef.current?.appendChild(trackPub.track.attach());
                    }

                    // Écouter l'événement lorsque la piste vidéo est désabonnée
                    trackPub.track.on('unsubscribed', (track) => {
                        console.log("La piste vidéo locale a été désabonnée.");
                        // Si la caméra est désactivée, remplacez la vidéo par une image
                        stopRemoteVideo(room.localParticipant);
                    });
                });

                room.localParticipant.on('trackPublished', (trackPub) => {
                    const checkTrackPublished = () => {
                        if (trackPub.track) {
                            if (trackPub.track.isSubscribed) {
                                console.log('La piste est souscrite et envoyée 22');
                                localVideoRef.current?.appendChild(trackPub.track.attach());
                            } else {
                                trackPub.track.on('subscribed', track => {
                                    localVideoRef.current?.appendChild(track.attach());
                                });
                            }
                        } else {
                            console.log('La piste n\'est pas encore souscrite');
                            setTimeout(checkTrackPublished, 1000);
                        }
                    }
                    checkTrackPublished()
                })

                room.localParticipant.on('trackSubscribed', (track) => {
                    console.log("La piste vidéo locale a été souscrite :", track);
                    localVideoRef.current?.appendChild(track.attach());
                });

                room.localParticipant.audioTracks.forEach((trackPub) => {
                    const track = trackPub.track;
                    if (track) {
                        track.attach();
                    }
                });

                room.on('participantConnected', participant => {
                    participant.videoTracks.forEach(trackPub => {
                        const checkTrackAvailability = () => {
                            if (trackPub.track) {
                                if (trackPub.isSubscribed) {
                                    setParticipantOnline(true)
                                    remoteVideoRef.current?.appendChild(trackPub.track.attach());
                                } else {
                                    trackPub.track.on('subscribed', track => {
                                        setParticipantOnline(true)
                                        remoteVideoRef.current?.appendChild(track.attach());
                                    });

                                    trackPub.track.on('unsubscribed', (track) => {
                                        console.log(`${participant.identity} a désactivé sa caméra 44444`);
                                        stopRemoteVideo(participant); // Appel pour afficher l'image de remplacement
                                    });

                                    trackPub.track.on('failed', (error) => {
                                        console.error('Échec de l\'abonnement à la piste vidéo:', error);
                                    });
                                }
                            } else {
                                // console.log("TRY HIDE CAMERA 22222....")
                                console.log("Piste encore indisponible, réessayer...");
                                setTimeout(checkTrackAvailability, 1000);
                            }
                        }
                        checkTrackAvailability();
                    });
                    participant.audioTracks.forEach((trackPub) => {
                        const checkAudioTrackAvailability = () => {
                            const track = trackPub.track;
                            if (track) {
                                console.log("Piste audio souscrite pour le participant:", participant.identity);
                                track.attach();
                            } else {
                                console.log("Piste audio encore indisponible, réessayer...");
                                setTimeout(checkAudioTrackAvailability, 1000);
                            }
                        }
                        checkAudioTrackAvailability()
                    });

                    // Ecouter l'événement lorsqu'un participant arrête sa caméra
                    participant.on("trackUnsubscribed", (track) => {
                        console.log("TRY HIDE CAMERA.... 5555")
                        if (track.kind === "video") {
                            console.log(`${participant.identity} a arrêté sa caméra`);
                            // Logique pour masquer la vidéo du participant, si nécessaire
                            stopRemoteVideo(participant);
                        }
                    });

                    participant.on("trackDisabled", (track) => {
                        console.log("CHECK TRACK VIDEO DISABLED")
                        if (track.kind === "video") {
                            console.log(`${participant.identity} a arrêté sa caméra 888`);
                            // Logique pour masquer la vidéo du participant, si nécessaire
                            stopRemoteVideo(participant);
                        }
                    });
                    participant.on("trackEnabled", (track) => {
                        console.log("CHECK TRACK VIDEO ENABLED")
                        if (track.kind === "video") {
                            console.log(`${participant.identity} a activé sa caméra 888`);
                            // Logique pour masquer la vidéo du participant, si nécessaire
                            startRemoteVideo(participant);
                        }
                    });
                });

                room.on('participantDisconnected', participant => {
                    console.log('participantDisconnected')
                    participant.videoTracks.forEach(trackPub => {
                        if (trackPub.track) {
                            trackPub.track.detach().forEach(element => element.remove())
                        }
                        if (remoteVideoRef.current?.children?.length) {
                            remoteVideoRef.current?.removeChild(remoteVideoRef.current?.children[0])
                        }
                        setParticipantOnline(false)
                    });
                    participant.audioTracks.forEach((trackPub) => {
                        if (trackPub.track) {
                            trackPub.track.detach().forEach((element) => element.remove());
                        }
                    });
                });

                room.on('disconnected', () => {
                    console.log('disconnected')
                    room.localParticipant.videoTracks.forEach(trackPub => {
                        if (trackPub.track) {
                            trackPub.track.detach().forEach(element => element.remove());
                        }
                    });
                    room.localParticipant.audioTracks.forEach((trackPub) => {
                        if (trackPub.track) {
                            trackPub.track.detach().forEach((element) => element.remove());
                        }
                    });
                    setRoom(null);
                    setIsConnected(false);
                });

                // Gérer les participants déjà connectés
                room.participants.forEach((participant) => {
                    participant.videoTracks.forEach((trackPub) => {
                        const checkTrackAvailability = () => {
                            if (trackPub.track) {
                                if (trackPub.isSubscribed) {
                                    setParticipantOnline(true)
                                    remoteVideoRef.current?.appendChild(trackPub.track.attach());
                                } else {
                                    trackPub.track.on('subscribed', track => {
                                        setParticipantOnline(true)
                                        remoteVideoRef.current?.appendChild(track.attach());
                                        startRemoteVideo(participant);
                                    });
                                    trackPub.track.on('unsubscribed', track => {
                                        console.log("PARTICIPANT UNSUBSCRIBED CAMERA")
                                        stopRemoteVideo(participant);
                                    });
                                    trackPub.track.on('stopped', track => {
                                        console.log("PARTICIPANT STOPPED CAMERA")
                                        stopRemoteVideo(participant);
                                    });
                                    trackPub.track.on('failed', (error) => {
                                        console.error('Échec de l\'abonnement à la piste vidéo:', error);
                                        // setCameraOff(true)
                                    });
                                }
                            } else {
                                // console.log("Piste encore indisponible, réessayer...");
                                setTimeout(checkTrackAvailability, 1000);
                            }
                        }
                        checkTrackAvailability();
                    });

                    participant.audioTracks.forEach((trackPub) => {
                        const checkAudioTrackAvailability = () => {
                            const track = trackPub.track;
                            if (track) {
                                console.log("Piste audio souscrite pour le participant existant:", participant.identity);
                                track.attach();
                            } else {
                                setTimeout(checkAudioTrackAvailability, 1000);
                            }
                        }
                        checkAudioTrackAvailability()
                    });

                    participant.on("trackUnsubscribed", (track) => {
                        console.log("CHECK TRACK VIDEO STOP")
                        if (track.kind === "video") {
                            console.log(`${participant.identity} a arrêté sa caméra`);
                            // Logique pour masquer la vidéo du participant, si nécessaire
                            stopRemoteVideo(participant);
                        }
                    });

                    participant.on("trackDisabled", (track) => {
                        console.log("CHECK TRACK VIDEO DISABLED")
                        if (track.kind === "video") {
                            console.log(`${participant.identity} a arrêté sa caméra`);
                            // Logique pour masquer la vidéo du participant, si nécessaire
                            stopRemoteVideo(participant);
                        }
                    });
                    participant.on("trackEnabled", (track) => {
                        console.log("CHECK TRACK VIDEO ENABLED")
                        if (track.kind === "video") {
                            console.log(`${participant.identity} a activé sa caméra 999`);
                            // Logique pour masquer la vidéo du participant, si nécessaire
                            startRemoteVideo(participant);
                        }
                    });

                    participant.on("trackSubscribed", (track) => {
                        console.log("CHECK TRACK VIDEO START")
                        if (track.kind === "video") {
                            console.log(`${participant.identity} a activé sa caméra`);
                            // Logique pour masquer la vidéo du participant, si nécessaire
                            startRemoteVideo(participant);
                        }
                    });
                });
            }).catch(error => {
                console.error('Erreur de connexion à la room:', error);
            });
        }
    }

    const stopRemoteVideo = (participant) => {
        participant.videoTracks.forEach((trackPub) => {
            console.log(trackPub.track, "TRY STOP CAMERA")
            if (trackPub.track) {
                console.log("STOP REMOTE")
                // trackPub.track.stop();
                setShowRemoteOff(true)
                // trackPub.track.detach().forEach((element) => element.remove());
            }
        });
    };

    const startRemoteVideo = (participant) => {
        participant.videoTracks.forEach((trackPub) => {
            if (trackPub.track) {
                // trackPub.track.start();
                setShowRemoteOff(false)
                // trackPub.track.detach().forEach((element) => element.remove());
            }
        });
    };

    useEffect(() => {
        if (isConnected) {
            return
        }

        if (conversationId) {
            api.getLinkingByConversation(conversationId, type).then((data) => {
                if (data && data.length) {
                    initVideoRoom()
                }
            })
        }

        return () => {
            room && room.disconnect();
        };
    }, [conversationId, isConnected]);

    return (
        <div className={"video_wrapper"}>
            <div className={"video_inner"}>
                <div ref={remoteVideoRef} className={`remoteVideo ${participantOnline ? 'full' : ''} ${showRemoteOff ? 'remoteVideoOff' : ''}`} />
                <div ref={localVideoRef} className={`localVideo ${participantOnline ? 'corner' : 'full'} ${cameraOff ? 'localVideoOff' : ''}`} />
                {room && <div className={"video_actions"}>
                    <Link href={'#0'} className={`mute ${!mute ? 'unmute' : ''}`} onClick={() => { onMute(); }} />
                    <Link href={'#0'} className={`camera ${cameraOff ? 'cameraOff' : ''}`} onClick={() => { handleCamera(); }} />
                    <Link href={'#0'} className={"endCall"} onClick={() => { onEndCall(); }} />
                </div>}
            </div>
        </div>
    );
};

export default VideoChat;
