import React, {Suspense, useContext, useEffect, useState} from "react";
import {Await, useLoaderData} from "react-router-dom";

import {useForm} from "react-hook-form";

import {GenericEmptyItem} from "../../other/GenericBlock";
import FilterButtonBar from "../../other/FilterButtonBar";

import Api from "../../helper/api";
import {CtaReturn} from "../../other/Cta";

import {AuthContext} from "../../userAuth/AuthContext";

import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';

/**
 * Global component
 * @returns {Element}
 * @constructor
 */
const SubscriptionCheckout = ({amount, clientSecret, onBack}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [serverError, setServerError] = useState("")
    const {updateUserInGlobalContext} = useContext(AuthContext)
    const api = new Api()
    const taxAmount = amount + amount * 0.2

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        const options = {
            elements,
            confirmParams: {
                return_url: "https://pro.esterlaw.io/abonnement/confirmation",
                // return_url: "http://localhost:3000/abonnement/confirmation",
            },
        }
        let result;
        if (clientSecret.indexOf('seti_') !== -1) {
            result = await stripe.confirmSetup(options);
        } else {
            result = await stripe.confirmPayment(options);
        }


        if (result.error) {
            setServerError("Une erreur est survenue lors du paiement, Veuillez réessayer plus tard.")
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            const user = await Api.getUser();
            updateUserInGlobalContext(user)
        }
    };

    return (
        <>
            <div className={"cta_wrapper"}>
                <button className={"cta return"} onClick={onBack}/>
            </div>
            <div className={"subscription_wrapper"}>
                <h1 className={"d-block d-mobile-none text-center mb3"}>Information de paiement</h1>
                <form onSubmit={handleSubmit}>
                    <PaymentElement className={"subscription_checkout_form"}/>

                    <div className={"subscription_extra_wrapper"}>
                        <div className={"subscription_extra"}>
                            <div className={"subscription_extra_label"}>Taxes comprises</div>
                            <div className={"subscription_extra_value"}>20%</div>
                        </div>
                        <div className={"subscription_extra"}>
                            <div className={"subscription_extra_label"}>Sous-total HT</div>
                            <div className={"subscription_extra_value"}>{amount}€ HT</div>
                        </div>
                        <div className={"subscription_extra"}>
                            <div className={"subscription_extra_label"}>Total</div>
                            <div className={"subscription_extra_value"}>{taxAmount}€ TTC</div>
                        </div>
                    </div>
                    <div className={"cta_wrapper"}>
                        <button disabled={!stripe} className={"cta full blue"}>Payer : {taxAmount}€ TTC</button>
                    </div>
                    {serverError !== "" && <div className={"error text-center"}>{serverError}</div>}
                </form>
            </div>
        </>
    )
}

export default SubscriptionCheckout