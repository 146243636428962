//dummy missions
import Api from "../helper/api";
import {
    PROFIL_TYPE_CANDIDAT,
    PROFIL_TYPE_RECRUTEUR,
    STEP_AGREEMENT,
    STEP_PENDING,
    STEP_RECIPIENT_ACCEPTED, STEP_RECIPIENT_REFUSED,
    STEP_SENDER_ACCEPTED, STEP_SENDER_REFUSED
} from "../other/Constant";

/**
 * Return all user missions list
 * @returns {{switchs: Promise<*>, missions: Promise<*>, helps: Promise<[]|*>}}
 */
export const getMatchs = (context) => {

    const api = new Api()
    let matchsMissionPromise = null

    if (context.state.user.profileType === PROFIL_TYPE_CANDIDAT) {
        matchsMissionPromise = api.getMissionLinkings(null, [STEP_PENDING, STEP_RECIPIENT_ACCEPTED, STEP_RECIPIENT_REFUSED, STEP_SENDER_ACCEPTED, STEP_SENDER_REFUSED, STEP_AGREEMENT])
    } else if (context.state.user.profileType === PROFIL_TYPE_RECRUTEUR) {
        matchsMissionPromise = api.getMissionLinkings(null, [STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED, STEP_SENDER_REFUSED, STEP_AGREEMENT])
    }
    // const matchsHelpPromise = api.getHelpLinkings(null, [STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED, STEP_SENDER_REFUSED, STEP_AGREEMENT])
    const matchsMyHelpPromise = api.getHelpLinkings(null, [STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED, STEP_SENDER_REFUSED, STEP_AGREEMENT], true)
    // const matchsSwitchPromise = api.getSwitchLinkings(null, [STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED, STEP_SENDER_REFUSED, STEP_AGREEMENT])
    const matchsMySwitchPromise = api.getSwitchLinkings(null, [STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED, STEP_SENDER_REFUSED, STEP_AGREEMENT], true)

    return {
        missions : matchsMissionPromise,
        helps : matchsMyHelpPromise,
        switchs : matchsMySwitchPromise,
    }
}

/**
 * Return single Mission Match data
 * @param params - contain missionId
 */
export const getMissionMatch = async ({params}) => {

    const api = new Api()
    const matchPromise = api.getMatch(params.matchId)

    return {
        match : matchPromise
    }
}

/**
 * Return single Help Match data
 * @param params - contain missionId
 */
export const getHelpMatch = async ({params}) => {

    const api = new Api()
    const matchPromise = api.getHelpMatch(params.matchId)

    return {
        match : matchPromise
    }
}

/**
 * Return single Mission Match data
 * @param params - contain missionId
 */
export const getSwitchMatch = async ({params}) => {

    const api = new Api()
    const matchPromise = api.getSwitchMatch(params.matchId)

    return {
        match : matchPromise
    }
}