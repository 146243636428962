import React, {useEffect, useRef, useState} from "react";
import SwipeableTabs from "react-swipeable-tabs";

const FilterButtonBarOld = (props) => {

    const createFilterButton = () => {
        let htmlFilters = []

        props.filters.forEach((filter, key) => {
            htmlFilters.push(
                <div className={"button_item"} key={"buton_item_"+key}>
                    <input type={"radio"} id={filter.value} value={filter.value} name={"button_bar"} defaultChecked={key===0} onClick={e => props.setFilterValue(e.target.value)}/>
                    <label htmlFor={filter.value}>{filter.label} {filter.count? `(${filter.count})`:""}</label>
                </div>
            )
        })

        return htmlFilters
    }

    return(
        <div className={"button_bar"}>
            {createFilterButton()}
        </div>
    )
}

FilterButtonBarOld.defaultProps = {
    filters : [
        {value: "dummy", label: "dummy"},
        {value: "dummy2", label: "dummy2"},
    ],
    setFilterValue : () => console.log("please define setFilterValue")
}

const FilterButtonBar = ({setFilterValue, filters, activeIndex, fitItems, alignCenter}) => {

    const [activeItemIndex, setActiveItemIndex] = useState(activeIndex)
    const [items, setItems] = useState([])

    useEffect(() => {

        let formatted_items = []
        filters.forEach((i, key) => {
            formatted_items[key] = i.label
            if(i.count)
                formatted_items[key] = i.label + " (" + i.count + ")"
        })
        
        setItems(formatted_items)
        
    },[filters])

    const handleIndexChange = (item, index) => {
        setFilterValue(index) //save data for parent
        setActiveItemIndex(index) //save data for current component
    }

    return (
        <div className={"filterbar_wrapper"} index={activeItemIndex}>
            {items.length > 0 && <SwipeableTabs
                noFirstLeftPadding={false}
                noLastRightPadding={false}
                fitItems={fitItems}
                alignCenter={alignCenter}
                borderWidthRatio={1}
                activeItemIndex={activeIndex}
                onItemClick={handleIndexChange}
                items={items}
            />}
        </div>
    )
}

FilterButtonBar.defaultProps = {
    filters: [
        {value: "dummy", label: "dummy"},
        {value: "dummy2", label: "dummy2"},
    ],
    setFilterValue: () => console.log("please define setFilterValue"),
    activeIndex : 0,
    fitItems : false,
    alignCenter : false
}

export default FilterButtonBar