import React, {Suspense, useContext, useEffect, useState} from "react";
import Slider from "react-slick";

import SlidingPanel from "./_sliding_panel";
import FilterButtonBar from "../other/FilterButtonBar";
import {Await, useLoaderData, useNavigate, useOutletContext} from "react-router-dom";
import {GenericEmptyItem} from "../other/GenericBlock";
import Api from "../helper/api";
import {AuthContext} from "../userAuth/AuthContext";
import {SwitchFullBlock} from "./switch/_SwitchBlock";
import {NotificationTypes, PROFIL_TYPE_CANDIDAT, PROFIL_TYPE_RECRUTEUR} from "../other/Constant";

const NotificationItem = ({notification, onClickCallback, deleteCallBack, itemId}) => {
    const navigate = useNavigate()

    return(
        <div className={`notification_item ${notification.read === false ? 'not-read': ''}`} onClick={() => {
            onClickCallback()
        }}>
            {/*<div className={"picture"}/>*/}
            <div className={"content"}>
                <div className={"title"}>{notification.title}</div>
                <div className={"description"}>{notification.body}</div>
                <div className={"date"}>{new Date(notification.createdOn).toLocaleDateString("fr-FR")}</div>
            </div>
            <div className={"actions"}>
                <button className={"cta delete"} onClick={(e) => {
                    e.preventDefault()
                    deleteCallBack()
                }}></button>
            </div>
        </div>
    )
}

const Notifications = () => {

    const [filterValue, setFilterValue] = useState(0);
    const [showSlidingPanel, setShowSlidingPanel] = useState(false);

    const [missionNotifications, setMissionNotification] = useState([])
    const [communityNotifications, setCommunityNotification] = useState([])

    const { notifications } = useLoaderData()
    const {user} = useOutletContext();

    const api = new Api();

    const navigate = useNavigate()
    const {state: ContextState, updateUserInGlobalContext, setNewNotifications} = useContext(AuthContext)

    const deleteNotification = (item, type) => {
        api.deleteNotification(item.id).then(response => {
            if (response.ok) {
                if (type === 0) {
                    setMissionNotification(missionNotifications.filter(obj => obj.id !== item.id))
                } else if (type === 1) {
                    setCommunityNotification(communityNotifications.filter(obj => obj.id !== item.id))
                }
            }
        })
    }

    const checkProfileType = (type) => {
        if (user.profileType != type) {
            api.UpdateUser(user.id, {
                profileType : type
            }).then((user) => {
                updateUserInGlobalContext(user)
            })
        }
    }

    const updateNotification = (item) => {
        const data = {
            read: true
        }
        // console.log(item.group, "NOTIFICATION GROUP")
        // console.log(item.type, "NOTIFICATION TYPE")
        // console.log(item.id, "NOTIFICATION ID")
        console.log(item, "NOTIFICATION")
        api.patchNotification(item.id, data).then(response => {
            if (item.group === 0) {
                setMissionNotification(missionNotifications.map(obj => {
                    if (obj.id === item.id) {
                        obj.read = true
                    }
                    return obj;
                }))
            } else if (item.group === 1) {
                setCommunityNotification(communityNotifications.map(obj => {
                    if (obj.id === item.id) {
                        obj.read = true
                    }
                    return obj;
                }))
            }
            if (item.type === NotificationTypes.TYPE_PROFILE_INCOMPLETE) {
                navigate(`/profile`)
            } else if (item.type === NotificationTypes.TYPE_MISSION_INCOMPLETE) {
                checkProfileType(PROFIL_TYPE_RECRUTEUR)
                navigate(`/missions/${item.entity}`)
            } else if (item.type === NotificationTypes.TYPE_HELP_INCOMPLETE) {
                navigate(`/aides/${item.entity}`)
            } else if (item.type === NotificationTypes.TYPE_SWITCH_DUTY_INCOMPLETE) {
                navigate(`/echanges/${item.entity}`)
            } else if (item.type === NotificationTypes.TYPE_PROFILE_FILLRATE) {
                navigate(`/profile`)
            } else if (item.type === NotificationTypes.TYPE_MISSION_FILLRATE) {
                checkProfileType(PROFIL_TYPE_RECRUTEUR)
                navigate(`/missions/${item.entity}`)
            } else if (item.type === NotificationTypes.TYPE_HELP_FILLRATE) {
                navigate(`/aides/${item.entity}`)
            } else if (item.type === NotificationTypes.TYPE_SWITCH_DUTY_FILLRATE) {
                navigate(`/echanges/${item.entity}`)
            } else if (item.type === NotificationTypes.TYPE_GENERAL_HELP) {
                navigate(`/dashboard`)
            } else if (item.type === NotificationTypes.TYPE_MISSION_LINKING_NEW) {
                checkProfileType(PROFIL_TYPE_CANDIDAT)
                navigate(`/matchs/mission/${item.entity}`)
            } else if (item.type === NotificationTypes.TYPE_HELP_LINKING_NEW) {
                navigate(`/matchs/aide/${item.entity}`)
            } else if (item.type === NotificationTypes.TYPE_SWITCH_DUTY_LINKING_NEW) {
                navigate(`/matchs/echange/${item.entity}`)
            } else if (item.type === NotificationTypes.TYPE_MISSION_LINKING_REMINDER) {
                checkProfileType(PROFIL_TYPE_CANDIDAT)
                navigate(`/matchs/mission/${item.entity}`)
            } else if (item.type === NotificationTypes.TYPE_HELP_LINKING_REMINDER) {
                navigate(`/matchs/aide/${item.entity}`)
            } else if (item.type === NotificationTypes.TYPE_SWITCH_DUTY_LINKING_REMINDER) {
                navigate(`/matchs/echange/${item.entity}`)
            } else if (item.type === NotificationTypes.TYPE_MISSION_LINKING_RECIPIENT_ACCEPTED) {
                checkProfileType(PROFIL_TYPE_RECRUTEUR)
                // navigate(`/missions/${item.entity}/matchs`)
                navigate(`/matchs/mission/${item.entity}`)
            } else if (item.type === NotificationTypes.TYPE_HELP_LINKING_RECIPIENT_ACCEPTED) {
                // navigate(`/aides/${item.entity}/matchs`)
                navigate(`/matchs/aide/${item.entity}`)
            } else if (item.type === NotificationTypes.TYPE_SWITCH_DUTY_LINKING_RECIPIENT_ACCEPTED){
                // navigate(`/echanges/${item.entity}/matchs`)
                navigate(`/matchs/echange/${item.entity}`)
            } else if (item.type === NotificationTypes.TYPE_MISSION_LINKING_RECIPIENT_ACCEPTED_REMINDER) {
                checkProfileType(PROFIL_TYPE_RECRUTEUR)
                // navigate(`/missions/${item.entity}/matchs`)
                navigate(`/matchs/mission/${item.entity}`)
            } else if (item.type === NotificationTypes.TYPE_HELP_LINKING_RECIPIENT_ACCEPTED_REMINDER) {
                // navigate(`/aides/${item.entity}/matchs`)
                navigate(`/matchs/aide/${item.entity}`)
            } else if (item.type === NotificationTypes.TYPE_SWITCH_DUTY_LINKING_RECIPIENT_ACCEPTED_REMINDER) {
                // navigate(`/echanges/${item.entity}/matchs`)
                navigate(`/matchs/echange/${item.entity}`)
            } else if (item.type == NotificationTypes.TYPE_MISSION_LINKING_SENDER_ACCEPTED) {
                checkProfileType(PROFIL_TYPE_CANDIDAT)
                navigate(`/matchs/mission/${item.entity}`)
            } else if (item.type == NotificationTypes.TYPE_HELP_LINKING_SENDER_ACCEPTED) {
                navigate(`/matchs/aide/${item.entity}`)
            } else if (item.type == NotificationTypes.TYPE_SWITCH_DUTY_LINKING_SENDER_ACCEPTED) {
                navigate(`/matchs/echange/${item.entity}`)
            } else if (item.type == NotificationTypes.TYPE_MISSION_LINKING_SENDER_DECLINED) {
                checkProfileType(PROFIL_TYPE_CANDIDAT)
                navigate(`/matchs/mission/${item.entity}`)
            } else if (item.type == NotificationTypes.TYPE_HELP_LINKING_SENDER_DECLINED) {
                navigate(`/matchs/aide/${item.entity}`)
            } else if (item.type == NotificationTypes.TYPE_SWITCH_DUTY_LINKING_SENDER_DECLINED) {
                navigate(`/matchs/echange/${item.entity}`)
            } else if (item.type == NotificationTypes.TYPE_MESSAGE_NEW) {
                navigate(`/messagerie`)
            } else if (item.type == NotificationTypes.TYPE_VIDEO_NEW) {
                navigate(`/messagerie`)
            } else if (item.type == NotificationTypes.TYPE_HELP_LINKING_MESSAGE_NEW) {
                navigate(`/messagerie`)
            } else if (item.type == NotificationTypes.TYPE_HELP_LINKING_VIDEO_NEW) {
                navigate(`/messagerie`)
            } else if (item.type == NotificationTypes.TYPE_SWITCH_DUTY_LINKING_MESSAGE_NEW) {
                navigate(`/messagerie`)
            } else if (item.type == NotificationTypes.TYPE_SWITCH_DUTY_LINKING_VIDEO_NEW) {
                navigate(`/messagerie`)
            } else if (item.type == NotificationTypes.TYPE_MISSION_FINISHED) {
            } else if (item.type == NotificationTypes.TYPE_HELP_FINISHED) {
            } else if (item.type == NotificationTypes.TYPE_SWITCH_DUTY_FINISHED) {
            } else if (item.type == NotificationTypes.TYPE_MISSION_FINISHED_REMINDER) {
            } else if (item.type == NotificationTypes.TYPE_HELP_FINISHED_REMINDER) {
            } else if (item.type == NotificationTypes.TYPE_SWITCH_DUTY_FINISHED_REMINDER) {
            } else if (item.type == NotificationTypes.TYPE_MISSION_UPDATED) {
            } else if (item.type == NotificationTypes.TYPE_HELP_UPDATED) {
            } else if (item.type == NotificationTypes.TYPE_SWITCH_DUTY_UPDATED) {
            } else if (item.type == NotificationTypes.TYPE_QUESTION_CREATED) {
            } else if (item.type == NotificationTypes.TYPE_QUESTION_LIKED) {
            } else if (item.type == NotificationTypes.TYPE_ANSWER_CREATED) {
            } else if (item.type == NotificationTypes.TYPE_ANSWER_LIKED) {
            } else if (item.type == NotificationTypes.TYPE_ACT_MODEL_CREATED) {
            } else if (item.type == NotificationTypes.TYPE_COMPANY_EMPLOYEE_REQUEST_NEW) {
            } else if (item.type == NotificationTypes.TYPE_COMPANY_EMPLOYEE_REQUEST_ACCEPTED) {
            } else if (item.type == NotificationTypes.TYPE_COMPANY_EMPLOYEE_REQUEST_DECLINED) {
            } else if (item.type == NotificationTypes.TYPE_PROFESSIONAL_CERTIFICATE_VERIFIED) {
            } else if (item.type == NotificationTypes.TYPE_PROFESSIONAL_CERTIFICATE_DECLINED) {
            }
        })
    }

    useEffect(() => {
        notifications.then((notifications) => {
            setMissionNotification(notifications?.filter(obj => { return obj.group === 0}))
            setCommunityNotification(notifications?.filter(obj => { return obj.group === 1}))

    //         const mission_notifications = []
    //         const community_notifications = []
    //
    //         notifications.forEach((n) => {
    //             if (!ContextState.newNotifications) {
    //                 setNewNotifications(true)
    //             }
    //             const notificationItem = <NotificationItem notification={n} itemId={n.id} onClickCallback={() => { updateNotification(n)}} deleteCallBack={() => {
    //                 deleteNotification(n, n.group)
    //             }}/>
    //
    //             switch(n.group){
    //                 case 0 :
    //                     mission_notifications.push(notificationItem)
    //                     break;
    //                 case 1 :
    //                     community_notifications.push(notificationItem)
    //                     break;
    //             }
    //         })
    //         setMissionNotification(mission_notifications)
    //         setCommunityNotification(community_notifications)
        })
    }, [notifications])

    return(
        <div id={"notifications_wrapper"}>

            <div className={"bloc_title_info"}>
                <h2>Notifications</h2>
                <p>Retrouvez ici toutes vos notifications de missions, aides et autres</p>
            </div>

            <FilterButtonBar filters={[
                { value : "missions", label : `Missions (${missionNotifications.length})`},
                { value : "community", label : `Communauté (${communityNotifications.length})`},
            ]} setFilterValue={setFilterValue}/>

            {filterValue === 0 &&
                <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                    <Await resolve={notifications} errorElement={<p>Error loading Data !</p>} >
                        <div className={"notification_block_wrapper"}>
                            {missionNotifications.map((n, key) => {
                                return <NotificationItem notification={n} itemId={n.id} onClickCallback={() => { updateNotification(n)}} deleteCallBack={() => { deleteNotification(n, n.group) }}/>
                            })}
                        </div>
                    </Await>
                </Suspense>
            }

            {filterValue === 1 &&
                <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                    <Await resolve={notifications} errorElement={<p>Error loading Data !</p>} >
                        <div className={"notification_block_wrapper"}>
                            {communityNotifications.map((n, key) => {
                                return <NotificationItem notification={n} itemId={n.id} onClickCallback={() => { updateNotification(n)}} deleteCallBack={() => { deleteNotification(n, n.group) }}/>
                            })}
                        </div>
                    </Await>
                </Suspense>
            }

            <SlidingPanel show={showSlidingPanel} handleClose={() => setShowSlidingPanel(false)}>
            </SlidingPanel>

        </div>
    )
}

export default Notifications