import Api from "../helper/api";

/**
 * Return all user notifications list
 * @returns promise
 */
export const getNotifications = async () => {

    let notificationsPromise = Api.getUserNotifications();

    return {
        notifications : notificationsPromise
    };
}
