import React from "react";
import ProfilDataBlock from "../../other/ProfilDataBlock";

/**
 * Block to display a single Item details
 * @param mission
 * @returns {JSX.Element}
 * @constructor
 */
const SingleItemDetails = ({singleItem, detail}) => {
    return(
        <div id={"single_mission_details"} className={"single_item_details"}>
            <h2>{detail ? "Détails" : singleItem.title}</h2>
            {singleItem.description &&
                <div className={"single_item_detail_block single_item_detail_block_description d-mobile-none"}>
                    {singleItem.description}
                </div>
            }
            <div className={"single_mission_details_inner"}>
                {singleItem.type?.name &&
                    <div className={"single_item_detail_block"}>
                        <h3>Type</h3>
                        <div>{singleItem.type?.name}</div>
                    </div>
                }
                {singleItem.description &&
                    <div className={"single_item_detail_block d-desktop-none"}>
                        <h3>Description</h3>
                        <p>{singleItem.description}</p>
                    </div>
                }
                {singleItem.estimatedBudget &&
                    <div className={"single_item_detail_block"}>
                        <h3>Budget prévisionel</h3>
                        <div>{singleItem.estimatedBudget} €</div>
                    </div>
                }
                {singleItem.estimatedDuration?.name &&
                    <div className={"single_item_detail_block"}>
                        <h3>Durée estimée</h3>
                        <div>{singleItem.estimatedDuration?.name}</div>
                    </div>
                }
                {singleItem.collaborationType?.name &&
                    <div className={"single_item_detail_block"}>
                        <h3>Mode de collaboration</h3>
                        <div>{singleItem.collaborationType?.name}</div>
                    </div>
                }
                {singleItem.bar?.name &&
                    <div className={"single_item_detail_block"}>
                        <h3>Barreau actuel</h3>
                        <div>{singleItem.bar?.name}</div>
                    </div>
                }
                {singleItem.limitDate &&
                    <div className={"single_item_detail_block"}>
                        <h3>Date limite de réponse</h3>
                        <div>{new Date(singleItem.limitDate).toLocaleDateString("fr-FR")}</div>
                    </div>
                }
                {singleItem.experience?.name &&
                    <div className={"single_item_detail_block"}>
                        <h3>Expérience</h3>
                        <div>{singleItem.experience?.name}</div>
                    </div>
                }
                {singleItem.language?.name &&
                    <div className={"single_item_detail_block"}>
                        <h3>Langue</h3>
                        <div>{singleItem.language?.name}</div>
                    </div>
                }
                {singleItem.countries &&
                    <div className={"single_item_detail_block"}>
                        <h3>Expertise internationale</h3>
                        <div>{singleItem.countries.map((item) => { return `${item.name} / ` })}</div>
                    </div>
                }
                {singleItem.skills && singleItem.skills.length !== 0 &&
                    <div className={"single_item_detail_block"}>
                        <h3>Compétences</h3>
                        {singleItem.skills?.map((item, i) => <ProfilDataBlock title={item.area?.name} experience={item.experience?.name} international={item.countries} description={item.description} subareas={item.subareas}/>)}
                    </div>
                }
                {singleItem.specializations && singleItem.specializations.length !== 0 &&
                    <div className={"single_item_detail_block"}>
                        <h3>Spécialisation</h3>
                        {singleItem.specializations?.map((item, i) => <ProfilDataBlock key={`specialization_data${i}`} title={item.specialization.name} experience={item.experience.name}  description={item.description}/>)}
                    </div>
                }
                {singleItem.industries && singleItem.industries.length !== 0 &&
                    <div className={"single_item_detail_block"}>
                        <h3>Secteurs d'activité</h3>
                        {singleItem.industries?.map((item, i) => <ProfilDataBlock key={`secteur_data${i}`} title={item.industry.name} experience={item.experience ? item.experience.name : null} international={item.countries} description={item.description}/>)}
                    </div>
                }
            </div>
        </div>
    )
}

export default SingleItemDetails;